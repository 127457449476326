import React from "react";

function ImgSection() {
  return (
    <>
      <div className="pt-12">
        <div className="grid grid-rows-2 grid-cols-3 gap-1 pt-3">
          <div className="row-span-3">
            <img src="/images/img (8).jpg" alt="" />
          </div>
          <div className="col-span-1">
            <img src="/images/img (6).jpg" alt="" />
          </div>
          <div className="col-span-1">
            <img src="/images/img (7).jpg" alt="" />
          </div>
          <div className="col-span-1">
            <img src="/images/img (5).jpg" alt="" />
          </div>
          <div className="col-span-1">
            <img src="/images/img (9).jpg" alt="" />
          </div>
        </div>
        <div className="py-5 px-10">
          <p className="text-xs text-slate-500">
            Home > Houses >{" "}
            <span className="text-black">Kalpataru Society in Pune</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default ImgSection;
