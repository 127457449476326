import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getFeatureProject_web } from "../config/api";
import { baseURL } from "../http-common";
import { Carousel, initTE } from "tw-elements";
function Banner() {
  useEffect(() => {
    initTE({ Carousel });
  }, []);
  const [data, setData] = useState([]);
  useEffect(() => {
    BindData();
  }, []);

  async function BindData() {
    await getFeatureProject_web()
      .then((response) => {
        setData(response);
        console.log("Banner", response);
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
      <div className="relative  text-center">
        <p className="md:text-4xl text-2xl font-bold pb-10">
          Our Featured Project
        </p>

        {/* <div
          id="carouselExampleControls"
          className="carousel slide relative"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner md:px-10 px-5 relative w-full  overflow-hidden">
            {data?.map((item, index) =>
              index === 0 ? (
                <div className="carousel-item active relative float-left w-full">
                  <Link to={"/property-detail?id=" + item.PropertydetailID}>
                    <img
                      src={baseURL + "/" + item.Image}
                      className="block w-full md:h-96 h-36"
                      alt=""
                    />
                  </Link>
                </div>
              ) : (
                <div className="carousel-item relative float-left w-full">
                  <Link to={"/property-detail?id=" + item.PropertydetailID}>
                    <img
                      src={baseURL + "/" + item.Image}
                      className="block w-full md:h-96 h-36"
                      alt=""
                    />
                  </Link>
                </div>
              )
            )}
          </div>
          
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div> */}

        {data.length > 0 ? <div
          id="carouselExampleCaptions"
          className="relative container mx-auto"
          data-te-carousel-init
          data-te-carousel-slide
        >
          <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            {data.length > 0 && data?.map((item, index) => {
              return index === 0 ? (
                <div
                  className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                  data-te-carousel-active
                  data-te-carousel-item
                  key={index}
                >
                  <Link to={`/property-detail?id=${item.PropertydetailID}`}>
                    <img
                      src={baseURL + "/" + item.Image}
                      className="block w-full"
                      alt="..."
                    />
                    <div className="absolute left-0 bottom-3 w-1/4">
                      <div className="bg-gray-50 p-5 shadow-sm">
                        <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                          {item.PropertyName}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : (
                <div
                  className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                  data-te-carousel-item
                  key={index}
                >
                  <Link to={`/property-detail?id=${item.PropertydetailID}`}>
                    <img
                      src={baseURL + "/" + item.Image}
                      className="block w-full"
                      alt="..."
                    />{" "}
                    <div className="absolute left-0 bottom-3 w-1/4">
                      <div className="bg-gray-50 p-5 shadow-sm">
                        <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                          {item.PropertyName}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            }
            )}
            {/* <div
              class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
              data-te-carousel-active
            >
              <Link to="/property-detail-static2">
                <img
                  src="/images/Unimiont-Imperia.jpeg"
                  class="block w-full"
                  alt="..."
                />{" "}
                <div className="absolute left-0 bottom-3 w-1/4">
                  <div className="bg-gray-50 p-5 shadow-sm">
                    <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                    Unimont Imperia
                    </p>
                    <p className="text-sm text-gray-600">
                    Gut No.102, Opp. HP Petrol Pump, Karjat-Murbad Road, Dahivali Village, Karjat, Beyond Thane, Thane
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div
              class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
            >
              <Link to="/property-detail-static3">
                <img
                  src="/images/Signature-Property.jpg"
                  class="block w-full"
                  alt="..."
                />
                <div className="absolute left-0 bottom-3 w-1/4">
                  <div className="bg-gray-50 p-5 shadow-sm">
                    <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                     Sairama  
                    </p>
                    <p className="text-sm text-gray-600">
                    Signature, Plot no. 62B, Sector 30, Kharghar, Navi Mumbai.
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div
              class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-active
              data-te-carousel-item
            >
              <Link to="/property-detail-static1">
                <img
                  src="/images/GreenCrestTaloja.jpeg"
                  class="block w-full"
                  alt="..."
                />
                <div className="absolute left-0 bottom-3 w-1/4">
                  <div className="bg-gray-50 p-5 shadow-sm">
                    <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                      Green Crest Taloja
                    </p>
                    <p className="text-sm text-gray-600">
                      150 Homes 6 Wings of G+6 Storreys Building Phase 1
                      Thoughtfully Designed 1RK, 1BHK-C and 1 BHK-L
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div
              class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
            >
              <Link to="/property-detail-static2">
                <img
                  src="/images/Unimiont-Imperia.jpeg"
                  class="block w-full"
                  alt="..."
                />{" "}
                <div className="absolute left-0 bottom-3 w-1/4">
                  <div className="bg-gray-50 p-5 shadow-sm">
                    <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                    Unimont Imperia
                    </p>
                    <p className="text-sm text-gray-600">
                    Gut No.102, Opp. HP Petrol Pump, Karjat-Murbad Road, Dahivali Village, Karjat, Beyond Thane, Thane
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div
              class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
            >
              <Link to="/property-detail-static3">
                <img
                  src="/images/Signature-Property.jpg"
                  class="block w-full"
                  alt="..."
                />
                <div className="absolute left-0 bottom-3 w-1/4">
                  <div className="bg-gray-50 p-5 shadow-sm">
                    <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                     Sairama  
                    </p>
                    <p className="text-sm text-gray-600">
                    Signature, Plot no. 62B, Sector 30, Kharghar, Navi Mumbai.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div
              class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
            >
              <Link to="/property-detail-static3">
                <img
                  src="/images/Signature-Property.jpg"
                  class="block w-full"
                  alt="..."
                />
                <div className="absolute left-0 bottom-3 w-1/4">
                  <div className="bg-gray-50 p-5 shadow-sm">
                    <p className="uppercase text-abhita-300 mb-2 font-semibold text-xl">
                    Thalia - Vrindavan Flora
                    </p>
                    <p className="text-sm text-gray-600">
                    Dand-Apta Road Behind Gurudwara Chambharli Khalapur, Taluka, Rasayani, Maharashtra 410222
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>

          {data ? <><button
            className="absolute bottom-0 left-0 top-0 z-[1] flex w-[5%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="prev"
          >
            <span className="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </button>

            <button
              className="absolute bottom-0 right-0 top-0 z-[1] flex w-[5%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide="next"
            >
              <span className="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Next
              </span>
            </button></> : ""}
        </div> : ""}
      </div>
      
    </>
  );
}

export default Banner;
