import React from "react";
import Address from "../viewDetails/Address";
import AdvSearch from "../viewDetails/AdvSearch";
import ChangeCurrency from "../viewDetails/ChangeCurrency";
import ChangeMsrmnt from "../viewDetails/ChangeMsrmnt";
import Description from "../viewDetails/Description";
import Details from "../viewDetails/Details";
import FeaturedProject from "../viewDetails/FeaturedProperties";
import HeadingSection from "../viewDetails/HeadingSection";
import ImgSection from "../viewDetails/ImgSection";
import OurFeatures from "../viewDetails/OurFeatures";
import OurListings from "../viewDetails/OurListings";
import Overview from "../viewDetails/Overview";

function PropViewDetails() {
  return (
    <>
      <div className="bg-slate-100">
        <ImgSection />
        <div className="p-10">
          <HeadingSection />
          <div className="pt-10">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-2 space-y-6">
                <Overview />
                <Description />
                <Address />
                <Details />
                <OurFeatures />
              </div>
              <div className="space-y-6">
                <ChangeCurrency />
                <ChangeMsrmnt />
                <AdvSearch />
                <OurListings />
                <FeaturedProject />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropViewDetails;
