import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../http-common";
import {
  mdiAccountCircle,
  mdiAccountOutline,
  mdiChevronDown,
  mdiChevronRight,
  mdiClose,
  mdiEmail,
  mdiFormatListBulleted,
  mdiLogout,
  mdiMenu,
  mdiPhone,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Animated } from "react-animated-css";
import { Offcanvas, Dropdown, Ripple, initTE } from "tw-elements";

function Navbar() {
  let navigate = useNavigate();
  const sessiondata = {
    UserID: sessionStorage.getItem("UserID"),
    EmailID: sessionStorage.getItem("UserName"),
    FullName: sessionStorage.getItem("FullName"),
    Type: sessionStorage.getItem("Type"),
  };
  const uid = sessionStorage.getItem("UserID");
  const [state, setState] = useState({
    ID: "0",

    FirstName: "",
  });
  const btnLogout = () => {
    sessionStorage.clear();
    navigate(`/`);
  };
  //console.log(sessiondata);
  const menulist = [
    {
      menu: "Post Your Property",
      plansLink: "/post-your-property",
    },
    // {
    //   menu: "Refer & Earn",
    // },
    // {
    //   menu: "Rental Agreement",
    // },
    // {
    //   menu: "Rent Receipants",
    // },
    {
      menu: "Tenant Plans",
      plansLink: "tenant-plans",
    },
    // {
    //   menu: "Owner plans",
    //   plansLink: "/owner-plans",
    // },
    {
      menu: "Seller Plans",
      plansLink: "/seller-plans",
    },
    {
      menu: "Buyer Plans",
      plansLink: "/buyer-plans",
    },
    // {
    //   menu: "Commercial Plans",
    //   plansLink: "/commercial-plans",
    // },
  ];

  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";

    axiosInstance
      .post("getprofiledetail_web", { ID: uid })
      .then((response) => {
        if (response?.data.length > 0) {
          setState(response?.data[0]);
        }
        // else {
        //   alert("api error");
        // }
      })
      .catch((error) => {
        alert(error);
      });
  }
  initTE({ Offcanvas, Dropdown, Ripple });

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const funShowOffcanvas = () => {
    setShowOffcanvas(true);
  };
  const funHideOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const [showMenu, setShowMenu] = useState(false);
  const funShowMenu = () => {
    setShowMenu(true);
  };
  const funHideMenu = () => {
    setShowMenu(false);
  };

  const [showAccount, setShowAccount] = useState(false);
  const funShowAccount = () => {
    setShowAccount(true);
  };

  return (
    <>
      <div className="h-[85px]">
        <div className="absolute z-50 w-full">
          <div className="fixed h-24 top-0 w-full">
            <nav className="relative w-full flex flex-wrap items-center justify-between py-4 bg-white shadow-lg navbar navbar-expand-lg navbar-light">
              <div className="container-fluid w-full flex flex-wrap items-center justify-between lg:px-10 md:px-10 px-5">
                <a href="/" className="flex items-center">
                  <img
                    src="/images/Abhita Properties pvt ltd.png"
                 
                    alt=""
                    className="w-40"
                  />
                </a>

                {/* Full Screen Header */}
                {/* {window.screen.availWidth < 767 && ( */}
                <div
                  className="flex space-x-1 items-center pt-2 lg:hidden md:block tive:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  // data-bs-toggle="offcanvas"
                  // data-bs-target="#offcanvasLeft"
                  // aria-controls="offcanvasLeft"
                  // aria-expanded="false"

                  data-te-offcanvas-toggle
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={funShowOffcanvas}
                >
                  <Icon path={mdiMenu} className="w-8 h-8" />
                </div>
                {/* )} */}

                <div
                  className="items-center pl-10 lg:flex md:hidden hidden"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav flex pl-0 list-style-none mr-auto">
                    <li className="nav-item p-2">
                      <a
                        href="/about-us"
                        className="text-md md:pl-0 pl-5 text-black active focus:text-abhita-400 hover:text-abhita-400"
                      >
                        About Us
                      </a>
                    </li>
                    <li className="nav-item p-2">
                      <a
                        href="/services"
                        className="text-md text-black active focus:text-abhita-400 hover:text-abhita-400"
                      >
                        Services
                      </a>
                    </li>
                    <li className="nav-item p-2">
                      <a
                        href="/user-guide"
                        className="text-md text-black active focus:text-abhita-400 hover:text-abhita-400"
                      >
                        User Guide
                      </a>
                    </li>
                    <li className="nav-item p-2">
                      {sessiondata ? (
                        sessiondata.UserID === null ? (
                          <a
                            href="/post-your-property/personal-detail"
                            className="flex space-x-1 text-md text-black active focus:text-abhita-400 hover:text-abhita-400"
                          >
                            <p>Post Your Property</p>
                            <Animated
                              animationIn="swing"
                              animationOut="zoomOut"
                              isVisible={true}
                            >
                              <button className="bg-abhita-300 items-center text-white font-semibold text-xs px-1.5 py-1 animation-swing transition ease-in-out delay-150 hover:translate-1 hover:scale-100 duration-100">
                                FREE
                              </button>
                            </Animated>
                          </a>
                        ) : sessiondata.Type === "seller" ? (
                          <a
                            href="/post-your-property/property-detail"
                            className="flex space-x-1 text-md text-black active focus:text-abhita-400 hover:text-abhita-400"
                          >
                            <p>Post Your Property</p>
                            <Animated
                              animationIn="swing"
                              animationOut="zoomOut"
                              isVisible={true}
                            >
                              <button className="bg-abhita-300 items-center text-white font-semibold text-xs px-1.5 py-1 animation-swing transition ease-in-out delay-150 hover:translate-1 hover:scale-100 duration-100">
                                FREE
                              </button>
                            </Animated>
                          </a>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>

                  <div className="lg:flex md:hidden hidden items-center relative">
                    <div className="flex space-x-10">
                      <div className="">
                        {sessiondata ? (
                          sessiondata.UserID !== null ? (
                            <div className="items-center">
                              <button
                                className="flex items-center pt-1.5 space-x-2 focus:outline-none"
                                type="button"
                                id="dropdownMenuButton1"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                onClick={funShowAccount}
                              >
                                <Icon
                                  path={mdiAccountCircle}
                                  className="w-10 h-10 text-gray-400 border border-gray-400 rounded-full"
                                />
                                <p className="dropdown-toggle text-md truncate text-black">
                                  {sessiondata.FullName}
                                </p>
                                <Icon
                                  path={mdiChevronDown}
                                  className="w-7 h-7 text-gray-400 "
                                />
                              </button>
                              {showAccount ? (
                                <ul
                                  // className="dropdown-menu min-w-max absolute top-12 hidden bg-white text-base float-left py-2 list-none text-left  rounded-lg shadow-lg mt-1 m-0  border-none"
                                  class="absolute z-[1000] float-left m-0 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg [&[data-te-dropdown-show]]:block"
                                  aria-labelledby="dropdownMenuButton1"
                                  data-te-dropdown-menu-ref
                                >
                                  <li>
                                    <a
                                      className="dropdown-item flex space-x-2 text-md py-2 pl-4 pr-20 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                                      href="/profile"
                                    >
                                      <Icon
                                        path={mdiAccountOutline}
                                        className="w-5 h-5"
                                      />
                                      <p> Profile</p>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item flex space-x-2 text-md py-2 pl-4 pr-20 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                                      href="/my_listing"
                                    >
                                      <Icon
                                        path={mdiFormatListBulleted}
                                        className="w-5 h-5"
                                      />
                                      <p> My Listing</p>
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      className="dropdown-item flex space-x-2 text-md py-2 pl-4 pr-20 font-normal block w-full whitespace-nowrap bg-transparenttext-gray-700 hover:bg-gray-100"
                                      href="/"
                                      onClick={btnLogout}
                                    >
                                      <Icon
                                        path={mdiLogout}
                                        className="w-5 h-5"
                                      />
                                      <p> Logout</p>
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="flex items-center space-x-5 ">
                        {sessiondata ? (
                          sessiondata.UserID === "" ||
                          sessiondata.UserID === undefined ||
                          sessiondata.UserID === null ? (
                            <div className="flex space-x-3 items-center md:p-0 p-5">
                              <a
                                href="/register"
                                className="text-md text-black  hover:text-abhita-300 hover:underline"
                              >
                                Register
                              </a>

                              <Link to="/login">
                                <button
                                  type="button"
                                  className="items-center border border-abhita-300 rounded text-md px-2 py-1 hover:bg-abhita-300 hover:border-abhita-400 hover:text-white duration-700 transition text-center mr-3 md:mr-0"
                                >
                                  Login
                                </button>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <div
                          className="flex space-x-1 items-center cursor-pointer"
                          onClick={funShowMenu}
                        >
                          <Icon path={mdiMenu} className="w-5 h-5" />
                          <p className="text-md text-black ">Menu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Full Screen Header */}
              </div>
            </nav>
          </div>
        </div>
      </div>

      {showMenu ? (
        <div
          // className="fixed bottom-0 flex flex-col max-w-full bg-gray-50 translate-x shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96"
          // tabIndex="-1"
          // id="offcanvasRight"
          // aria-labelledby="offcanvasRightLabel"

          class="fixed bottom-0 right-0 top-0 z-[1045] flex w-96 max-w-full flex-col border-none bg-white 
          text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out
           [&[data-te-offcanvas-show]]:transform"
        >
          <div>
            <div className="flex justify-between space-x-5 bg-abhita-100 items-center px-7 py-5">
              <Link to="/">
                <img
                  // src="/images/Abhita Properties pvt ltd.png"
                  src="/images/Abhita-Properties-Logo-for-footer.JPG"
                  className="w-32"
                  alt=""
                />
              </Link>

              <Icon
                path={mdiClose}
                className=" w-5 h-5 text-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={funHideMenu}
              />
            </div>
            <div className="p-5 divide-y ">
              {menulist?.map((list, idx) => (
                <div
                  className="py-2 md:py-3 hover:pl-1 duration-700 transition-all hover:duration-700 hover:transition-all"
                  key={idx}
                >
                  <MenuList {...list} />
                </div>
              ))}
            </div>

            <div className="absolute bottom-10 px-7 pt-10">
              <div className="text-sm flex space-x-2 cursor-pointer">
                <div>
                  <Icon path={mdiEmail} className="w-4 h-4" />
                </div>
                <div>
                  <p className="text-left underline hover:opacity-100">
                  info@abhitaproperties.com
                  </p>
                </div>
              </div>
              <div className="text-sm flex space-x-2 cursor-pointer">
                <div>
                  <Icon path={mdiPhone} className="w-4 h-4" />
                </div>
                <div>
                  <p className="text-left  hover:opacity-100">
                    +91 771 007 3844
                  </p>
                </div>
              </div>
              <div className="flex">
                <a
                  href="https://www.facebook.com/abhitapropertiespvtltd/"
                  type="button"
                  className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase  hover:bg-blue-600 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                  target="_blank"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook-f"
                    className="w-2 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                    ></path>
                  </svg>
                </a>

                <a
                  href="https://www.instagram.com/abhitapropertiespvtltd/"
                  type="button"
                  className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase hover:bg-gradient-to-l  to-yellow-600 via-pink-500 from-purple-500  focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                  target="_blank"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    className="w-3 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    ></path>
                  </svg>
                </a>

                <a
                  href="#!"
                  type="button"
                  className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase  hover:bg-blue-400 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                  target="_blank"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="twitter"
                    className="w-3 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    ></path>
                  </svg>
                </a>

                <a
                  href="https://www.linkedin.com/company/88435348/admin/feed/posts/"
                  type="button"
                  className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase hover:bg-sky-600  focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                  target="_blank"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    className="w-3 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* OffCanvas Header */}
      {showOffcanvas ? (
        <div
          // className="overflow-auto fixed bottom-0 flex flex-col md:max-w-full bg-gray-50 bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 border-none w-72"
          class="fixed p-5 overflow-auto  bottom-0 left-0 top-0 z-[1045] flex w-72 max-w-full translate-x flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out [&[data-te-offcanvas-show]]:transform"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
          data-te-offcanvas-init
        >
          <div className="flex justify-between items-center">
            <a href="/" className="flex items-center">
              <img
                src="/images/Abhita Properties pvt ltd.png"
                alt=""
                className="w-40"
              />
            </a>

            <Icon
              path={mdiClose}
              className=" w-5 h-5 text-black opacity-75 "
              onClick={funHideOffcanvas}
            />
          </div>
          <div className="pt-5">
            <div>
              {sessiondata ? (
                sessiondata.UserID !== null ? (
                  <div className="">
                    <button
                      className="flex items-center space-x-1 focus:outline-none"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={funShowAccount}
                    >
                      <Icon
                        path={mdiAccountCircle}
                        className="w-10 h-10 text-gray-400 border border-gray-400 rounded-full"
                      />
                      <p className="dropdown-toggle text-md truncate text-black ">
                        {sessiondata.FullName}
                      </p>
                      <Icon
                        path={mdiChevronDown}
                        className="w-7 h-7 text-gray-400 "
                      />
                    </button>
                    {showAccount ? (
                      <ul
                        className="dropdown-menu min-w-max absolute top-10 hidden bg-white text-base float-left py-2 list-none text-left  rounded-lg shadow-lg mt-1 m-0  border-none"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item flex space-x-2 text-md py-2 pl-4 pr-20 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                            href="/profile"
                          >
                            <Icon
                              path={mdiAccountOutline}
                              className="w-5 h-5"
                            />
                            <p> Profile</p>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item flex space-x-2 text-md py-2 pl-4 pr-20 font-normal block w-full whitespace-nowrap bg-transparenttext-gray-700 hover:bg-gray-100"
                            href="/"
                            onClick={btnLogout}
                          >
                            <Icon path={mdiLogout} className="w-5 h-5" />
                            <p> Logout</p>
                          </a>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>

            {sessiondata ? (
              sessiondata.UserID === "" ||
              sessiondata.UserID === undefined ||
              sessiondata.UserID === null ? (
                <div className="flex justify-between items-center">
                  <div>
                    <a
                      href="/register"
                      className="text-md text-black pt-2 pl-2 hover:text-abhita-300 hover:underline"
                    >
                      Register
                    </a>
                  </div>
                  <div>
                    <Link to="/login">
                      <button
                        type="button"
                        className="items-center border border-abhita-300 rounded text-md px-2 py-1 hover:bg-abhita-300 hover:border-abhita-400 hover:text-white duration-700 transition text-center"
                      >
                        Login
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <ul className="list-style-none static pt-5 divide-y divide-gray-500">
            <li className=" p-2">
              <a
                href="/about-us"
                className="text-sm  md:pl-0 text-black active focus:text-abhita-400 hover:text-abhita-400"
              >
                About Us
              </a>
            </li>
            <li className=" p-2">
              <a
                href="/services"
                className="text-sm  text-black active focus:text-abhita-400 hover:text-abhita-400"
              >
                Services
              </a>
            </li>
            <li className=" p-2">
              <a
                href="/user-guide"
                className="text-sm  text-black active focus:text-abhita-400 hover:text-abhita-400"
              >
                User Guide
              </a>
            </li>
            <li className=" p-2">
              {sessiondata ? (
                sessiondata.UserID === null ? (
                  <a
                    href="/post-your-property/personal-detail"
                    className="flex space-x-1 text-sm  text-black active focus:text-abhita-400 hover:text-abhita-400"
                  >
                    <p>Post Your Property</p>
                    <Animated
                      animationIn="swing"
                      animationOut="zoomOut"
                      isVisible={true}
                    >
                      <button className="bg-abhita-300 items-center text-white  text-xs px-1 py-0.5 transition ease-in-out delay-150 hover:translate-1 hover:scale-100 duration-100">
                        FREE
                      </button>
                    </Animated>
                  </a>
                ) : sessiondata.Type === "seller" ? (
                  <a
                    href="/post-your-property/property-detail"
                    className="flex space-x-1 text-sm text-black active focus:text-abhita-400 hover:text-abhita-400"
                  >
                    <p>Post Your Property</p>
                    <Animated
                      animationIn="swing"
                      animationOut="zoomOut"
                      isVisible={true}
                    >
                      <button className="bg-abhita-300 items-center text-white font-semibold text-xs px-1 py-0.5 transition ease-in-out delay-150 hover:translate-1 hover:scale-100 duration-100">
                        FREE
                      </button>
                    </Animated>
                  </a>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </li>
            <div className="dropdown relative">
              <div
                className="dropdown-toggle overflow-auto flex justify-between items-center p-2"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <p className="text-sm text-black cursor-pointer">Menu</p>
                <Icon
                  path={mdiChevronDown}
                  className="w-5 h-5 hover:rotate-180 focus:rotate-180"
                />
              </div>
              <ul
                className="dropdown-menu overflow-auto px-2 list-none h-[150px]"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="text-md font-medium text-black">
                  {menulist?.map((list, idx) => (
                    <div className="flex space-x-1 py-1" key={idx}>
                      <MenuList {...list} />
                    </div>
                  ))}
                </li>
              </ul>
            </div>
          </ul>

          <div className="absolute bottom-5 px-2 pb-2 ">
            <div className="text-sm flex space-x-2 cursor-pointer">
              <div>
                <Icon path={mdiEmail} className="w-4 h-4" />
              </div>
              <div>
                <p className="text-left underline hover:opacity-100">
                info@abhitaproperties.com
                </p>
              </div>
            </div>
            <div className="text-sm flex space-x-2 cursor-pointer">
              <div>
                <Icon path={mdiPhone} className="w-4 h-4" />
              </div>
              <div>
                <p className="text-left  hover:opacity-100">+91 771 007 3844</p>
              </div>
            </div>
            <div className="flex">
              <a
                href="https://www.facebook.com/abhitapropertiespvtltd/"
                type="button"
                className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase  hover:bg-blue-600 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                target="_blank"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="facebook-f"
                  className="w-2 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                  ></path>
                </svg>
              </a>

              <a
                href="https://www.instagram.com/abhitapropertiespvtltd/"
                type="button"
                className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase hover:bg-gradient-to-l  to-yellow-600 via-pink-500 from-purple-500  focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                target="_blank"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="instagram"
                  className="w-3 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </a>

              <a
                href="#!"
                type="button"
                className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase  hover:bg-blue-400 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                target="_blank"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="twitter"
                  className="w-3 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                  ></path>
                </svg>
              </a>

              <a
                href="https://www.linkedin.com/company/88435348/admin/feed/posts/"
                type="button"
                className="rounded-full border-2 border-black text-black hover:border-white hover:text-white leading-normal uppercase hover:bg-sky-600  focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-7 h-7 m-1"
                target="_blank"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="linkedin-in"
                  className="w-3 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* OffCanvas Header */}
    </>
  );
}

function MenuList({ menu, plansLink }) {
  return (
    <>
      <a href={plansLink} className="flex space-x-1 ">
        <Icon path={mdiChevronRight} className="w-5 h-5" />
        <p className="md:text-md text-sm font-medium items-center hover:underline hover:text-abhita-400">
          {menu}
        </p>
      </a>
    </>
  );
}

export default Navbar;
