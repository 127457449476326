import React from "react";
import { Animated } from "react-animated-css";

function Services() {
  const blog = [
    {
      title: "Sell/Rent your Property",
      desc: "A Speedy way to find genuine Buyers and explore the best rental properties",
      icon: "/images/sale-rent.png",
      href: "/post-your-property",
      img: "/images/OurBestServices/RentYourProperty.jpg",
    },
    {
      title: "Buy/Rent a Property",
      desc: "Guided by trusted Property Experts and explore the best rental properties",
      icon: "/images/buy-rent.png",
      href: "/",
      img: "/images/OurBestServices/RentProperty.jpg",
    },
    {
      title: "Consultation",
      desc: "Get Legal Opinion of our experts for your Property related concerns",
      icon: "/images/consultation.png",
      href: "https://abhitalandsolutions.com/consultation/",
      img: "/images/OurBestServices/Consultation.jpg",
    },
    {
      title: "Search / Title Report",
      desc: "Get a verified Scrutiny Report for a clear title over your property",
      icon: "/images/report.png",
      href: "https://abhitalandsolutions.com/search-report/",
      img: "/images/OurBestServices/TitleReport.jpg",
    },
    {
      title: "Valuation",
      desc: "Know the current valuation by our Government approved valuers ",
      icon: "/images/valuation.png",
      href: "https://abhitalandsolutions.com/property-and-machinery-valuation/",
      img: "/images/OurBestServices/Valuation.jpg",
    },
    {
      title: "Rent Agreement",
      desc: "Register your Rental Agreements with us",
      icon: "/images/rent.png",
      href: "https://abhitalandsolutions.com/rent-agreement/",
      img: "/images/OurBestServices/RentAgreement.jpg",
    },
    {
      title: "Home Loan",
      desc: "Apply for Quick Home Loans",
      icon: "/images/homeLoan.png",
      href: "https://abhitalandsolutions.com/property-loan/",
      img: "/images/OurBestServices/HomeLoan.jpg",
    },
    {
      title: "Sale Deed",
      desc: "Register Sale Deed of your Property",
      icon: "/images/deed.png",
      href: "https://abhitalandsolutions.com/registration-all-deed/",
      img: "/images/OurBestServices/SaleDeed.jpg",
    },
  ];
  return (
    <>
      <div className="py-20 lg:px-20 md:px-10 px-5 w-full bg-gray-50">
        <p className="text-center font-semibold text-3xl">Our Best Services</p>

        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-5 pt-10">
          {blog?.map((s, idx) => (
            <div
              className="try relative bg-white shadow-md hover:shadow-2xl hover:shadow-slate-600 shadow-slate-100 border border-slate-100 transition duration-700"
              key={idx}
            >
              <ServiceBlog {...s} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function ServiceBlog({ title, desc, icon, href, img }) {
  return (
    <>
      <div className="">
        <div className="try-1 p-3 grid place-items-center">
          <Animated
            animationIn="slideInDown"
            animationOut="zoomOut"
            isVisible={true}
          >
            <img src={img} alt="" className="w-full h-40" />
          </Animated>
        </div>
        <div className="try-2">
          <div className="p-5 space-y-3">
            <div className="flex space-x-2 justify-center">
              <Animated
                animationIn="tada"
                animationOut="zoomOut"
                isVisible={true}
              >
                
                <img src={icon} alt="" className="w-10 h-10" />
              </Animated>
              <p className="text-lg text-center font-semibold pt-2">{title}</p>
            </div>
            <p className="text-gray-500 text-center md:text-base text-sm pb-5">
              {desc}
            </p>
          </div>
        </div>
        <div className="">
          <div className="pl-5 pb-10 flex justify-center">
            <Animated
              animationIn="zoomIn"
              animationOut="zoomOut"
              isVisible={true}
            >
              <a
                href={href}
                target="_blank"
                className="uppercase text-sm shadow-lg hover:shadow-slate-100 bg-abhita-300 text-white font-semibold rounded border-2 border-abhita-300 p-2 "
              >
                Read More
              </a>
            </Animated>
          </div>
          {/* <div className="absolute bottom-0 right-0 flex justify-end">
          <div className="bg-violet-100 rounded-tl-[5rem] p-7">
            <img src={icon} alt="" className="w-16 h-16" />
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Services;
