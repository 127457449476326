import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance, { useQuery } from "../http-common";
import {
  checkonlyletterandcharacter,
  checkemail,
  checkindiamobile,
} from "../config/validate";
import { getProfileDetailByID } from "../config/api";

function Profile() {
  let query = useQuery();
  const uid = sessionStorage.getItem("UserID");
  const id = query.get("id");
  const [state, setState] = useState({
    ID: "0",
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    Type: "",
    Password: "",
    CreatedBy: 0, // 0 means admin
    errors: [],
  });
  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(uid);
    }
    getData();
  }, []);
  async function getData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";

    axiosInstance
      .post("getprofiledetail_web", { ID: uid })
      .then((response) => {
        if (response?.data.length > 0) {
          setState(response?.data[0]);
        } else {
          alert("api error");
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function UpdateData() {
    //await getProfileDetailByID()
    axiosInstance
      .post("iuusermaster", state)
      .then((response) => {
        //  if (response !== "" && response?.length > 0) {
        //   setState(response?.data[0]);
        //   }

        //  alert("updated...");
        if (response?.data.length > 0) {
          if (response?.data[0].ID) {
            alert("Successfully update your Profile....");
          } else {
            alert("error");
          }
        } else {
          alert("api error");
        }
      })

      .catch((error) => {
        alert(error);
      });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Salutation) {
      IsValid = false;
      errors["Salutation"] = "Salutation is Required";
    }
    if (!state?.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name is Required";
    } else {
      if (!checkonlyletterandcharacter(state.FirstName)) {
        IsValid = false;
        errors["FirstName"] = "Only letter and character allowed";
      }
    }
    // if (!state?.MiddleName) {
    //   IsValid = false;
    //   errors["MiddleName"] = "Middle Name is Required";
    // }
    // else {
    //   if (!checkonlyletterandcharacter(state.MiddleName)) {
    //     IsValid = false;
    //     errors["MiddleName"] = "Only letter and character allowed";
    //   }
    // }
    if (!state?.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name is Required";
    } else {
      if (!checkonlyletterandcharacter(state.LastName)) {
        IsValid = false;
        errors["LastName"] = "Only letter and character allowed";
      }
    }
    if (!state?.EmailID) {
      IsValid = false;
      errors["EmailID"] = "EmailID is Required";
    } else {
      if (!checkemail(state.EmailID)) {
        IsValid = false;
        errors["EmailID"] = "Only valid EmailID Required";
      }
    }
    if (!state?.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile is Required";
    } else {
      if (!checkindiamobile(state.Mobile)) {
        IsValid = false;
        errors["Mobile"] = "Only valid Mobile Number is Required";
      }
    }
    //  if (!state?.Password) {
    //   IsValid = false;
    //   errors["Password"] = "Password is Required";
    // }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  console.log(state);
  return (
    <>
      <div className="relative">
        <div className="bg-contactimg md:h-[750px] h-[900px] bg-center bg-cover bg-no-repeat relative after:bg-black after:absolute after:inset-0 after:opacity-50"></div>

        <div className="px-5">
          <div className="grid place-items-center">
            <div className="md:w-2/3 w-11/12 container absolute md:top-20 top-28  bg-white rounded-xl md:mx-5 mx-5 shadow-lg overflow-hidden text-center md:py-5 ">
              <p className="md:pt-2 pt-5 text-center md:text-4xl text-2xl text-abhita-100 font-bold uppercase">
                Profile
              </p>
              <p className="text-slate-400 md:text-semibold md:text-base text-xs py-1 px-2 ">
                The information will let us know more about you
              </p>
              <p className="p-2 bg-abhita-200  text-center  text-lg text-white font-bold tracking-widest ">
                User Profile
              </p>
              <div className="px-5 pt-5 grid md:grid-cols-3 grid-cols-1 gap-2">
                <div className="md:mb-5 mb-2">
                  <label
                    for="full_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Salutation
                  </label>
                  <select
                    id="Salutation"
                    className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2"
                    name="Salutation"
                    value={state.Salutation}
                    onChange={handlechange}
                  >
                    <option value="0">Select Salutation</option>
                    <option value="Mr">Mr.</option>
                    <option value="Miss">Miss</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs ">
                      {state.errors.Salutation}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="px-5 grid md:grid-cols-3 grid-cols-1 gap-2">
                <div className="md:mb-5 mb-2">
                  <label
                    for="first_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    placeholder="Enter First Name"
                    name="FirstName"
                    value={state.FirstName}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs ">
                      {state.errors.FirstName}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-6">
                  <label
                    for="middle_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Middle name
                  </label>
                  <input
                    type="text"
                    id="middle_name"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    placeholder="Enter Middle Name"
                    name="MiddleName"
                    value={state.MiddleName}
                    onChange={handlechange}
                    required
                  />
                  {/* {state.errors ? (
            <div className="text-red-600 text-left text-xs ">{state.errors.MiddleName}</div>
          ) : (
            ""
          )} */}
                </div>

                <div className="mb-6">
                  <label
                    for="last_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    placeholder="Enter Last Name"
                    name="LastName"
                    value={state.LastName}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">
                      {state.errors.LastName}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="full_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    id="mb_no"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    placeholder="Enter Mobile No."
                    name="Mobile"
                    value={state.Mobile}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">
                      {state.errors.Mobile}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Email address
                  </label>

                  <input
                    type="email"
                    id="email"
                    className="border border-gray-300 text-black text-sm rounded block w-full p-2"
                    placeholder="Enter Email ID"
                    name="EmailID"
                    value={state.EmailID}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">
                      {state.errors.EmailID}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="mb-6">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Password
                  </label>

                  <input
                    type="password"
                    id="password"
                    className="border bg-white md:pr-60 pr-20 border-gray-300 text-black text-sm rounded-lg block w-full p-2"
                    placeholder="Enter Password"
                    name="Password"
                    value={state.Password}
                    onChange={handlechange}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Re-enter Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="border bg-white md:pr-60 pr-20 border-gray-300 text-black text-sm rounded-lg block w-full p-2"
                    placeholder="Enter Re-enter Password"
                    required
                  />
                </div> */}
              </div>
              <div className="flex space-x-2 justify-center pt-5">
                <div>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5  bg-abhita-300 text-white font-semibold rounded text-xs leading-tight uppercase focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-700 delay-150 ease-in-out hover:bg-blue-700"
                    onClick={UpdateData}
                  >
                    Update Profile
                  </button>
                </div>
              </div>
              <div className="md:pt-5 md:py-0 py-5 px-2 grid place-content-center">
                <p className="text-slate-600 text-xs text-center">
                Abhita Properties collects and uses personal data in accordance
                  with our{" "}
                  <span className="underline hover:text-blue-800">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </span>
                  . By creating an account, you agree to our{" "}
                  <span className="underline hover:text-blue-800">
                    Terms & Conditions
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
