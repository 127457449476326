//import React from 'react'
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../http-common";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "../../config/api";
import {
  checkonlyletterandcharacter,
  checkemail,
  checkindiamobile,
} from "../../config/validate";


function PersonalDetailsPage() {
  let navigate = useNavigate();
  let query = useQuery();
  const [state, setState] = useState({
    ID: "0",
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    Type: "seller",
    Password: "",
    CreatedBy: 0, // 0 means admin
    errors: [],
  });
  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  async function SaveData() {debugger;
    let errors = {};
    if (validate()) {
      axiosInstance
        .post("iuusermaster", state)
        .then((response) => {
          
          if (response?.data.length > 0) {
            if (response?.data[0].ID === "exists") {
              alert("EmailID already exists");
            } else {
              sessionStorage.setItem("UserID", response?.data[0].ID);
              sessionStorage.setItem("Type", response?.data[0].Type);
              alert("Successfuly");
              navigate(
                `/post-your-property/property-detail?uid=${response?.data[0].ID}`
              );
            }
          } else {
            alert("api error");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Salutation) {
      IsValid = false;
      errors["Salutation"] = "Salutation is Required";
    }
    if (!state?.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name is Required";
    }
    else {
      if (!checkonlyletterandcharacter(state.FirstName)) {
        IsValid = false;
        errors["FirstName"] = "Only letter and character allowed";
      }
    }
    // if (!state?.MiddleName) {
    //   IsValid = false;
    //   errors["MiddleName"] = "Middle Name is Required";
    // }
    // else {
    //   if (!checkonlyletterandcharacter(state.MiddleName)) {
    //     IsValid = false;
    //     errors["MiddleName"] = "Only letter and character allowed";
    //   }
    // }
    if (!state?.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name is Required";
    }
    else {
      if (!checkonlyletterandcharacter(state.LastName)) {
        IsValid = false;
        errors["LastName"] = "Only letter and character allowed";
      }
    }
    if (!state?.EmailID) {
      IsValid = false;
      errors["EmailID"] = "EmailID is Required";
    }
    else {
      if (!checkemail(state.EmailID)) {
        IsValid = false;
        errors["EmailID"] = "Only valid EmailID Required";
      }
    }
    if (!state?.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile is Required";
    }
    else {
      if (!checkindiamobile(state.Mobile)) {
        IsValid = false;
        errors["Mobile"] = "Only valid Mobile Number is Required";
      }
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }
  return (
    <>
    
        <div className="text-xl font-semibold  underline underline-offset-[6px] decoration-abhita-200 decoration-[2px]">
          <p>Personal Details</p>
        </div>
        <div className="px-5 pt-5 grid md:grid-cols-3 grid-cols-1 gap-2">
          <div className="md:mb-5 mb-2">
            <label
              for="full_name"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
              Salutation
            </label>
            <select
              id="Salutation"
              className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              name="Salutation"
              value={state.Salutation}
              onChange={handlechange}
            >
              <option value="0">Select Salutation</option>
              <option value="Mr">Mr.</option>
              <option value="Miss">Miss</option>
              <option value="Mrs">Mrs</option>
            </select>
            {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.Salutation}</div>
          ) : (
            ""
          )}
          </div>
        </div>
        <div className="px-5 grid md:grid-cols-3 grid-cols-1 gap-2">
          <div className="md:mb-5 mb-2">
            <label
              for="first_name"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
              First name
            </label>
            <input
              type="text"
              id="first_name"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder="Enter First Name"
              name="FirstName"
              value={state.FirstName}
              onChange={handlechange}
              required
            />
             {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.FirstName}</div>
          ) : (
            ""
          )}
          </div>
          <div className="md:mb-5 mb-2">
            <label
              for="middle_name"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
              Middle name
            </label>
            <input
              type="text"
              id="middle_name"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder="Enter Middle Name"
              name="MiddleName"
              value={state.MiddleName}
              onChange={handlechange}
              required
            />
               {/* {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.MiddleName}</div>
          ) : (
            ""
          )} */}
          </div>
          <div className="md:mb-5 mb-2">
            <label
              for="last_name"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
              Last name
            </label>
            <input
              type="text"
              id="last_name"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder="Enter Last Name"
              name="LastName"
              value={state.LastName}
              onChange={handlechange}
              required
            />
               {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.LastName}</div>
          ) : (
            ""
          )}
          </div>
        </div>

        <div className="px-5 grid md:grid-cols-2 grid-cols-1 gap-2 ">
          <div className="mb-2">
            <label
              htmlFor="full_name"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
              Mobile Number
            </label>
            <input
              type="number"
              id="mb_no"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder="Enter Mobile No."
              name="Mobile"
              value={state.Mobile}
              onChange={handlechange}
              required
            />
             {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.Mobile}</div>
          ) : (
            ""
          )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="email"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
              Email address
            </label>

            <input
              type="email"
              id="email"
              className="border border-gray-300 text-black text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder="Enter Email ID"
              name="EmailID"
              value={state.EmailID}
              onChange={handlechange}
              required
            />
              {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.EmailID}</div>
          ) : (
            ""
          )}
          </div>
        </div>
        <div className="flex space-x-2 justify-between py-5 px-10">
          {/* <div>
            <button
              type="button"
              className="inline-block px-6 py-2.5  bg-gray-500 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-abhita-300 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              
            >
              Previous
            </button>
          </div> */}

          <div>
            <button
              type="button"
              className="inline-block px-6 py-2.5  bg-abhita-300 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              onClick={SaveData}
            >
              Next
            </button>
          </div>
        </div>
      
    </>
  );
}

export default PersonalDetailsPage;
