import React from "react";

function ChangeCurrency() {
  return (
    <>
      <div className="bg-white p-10">
        <p className="font-semibold hover:text-blue-700">Change Currency</p>
        <div className="pt-5">
          <select className="py-2 px-2 grid w-full border border-slate-300 rounded ">
            <option>Rupees</option>
            <option>USD</option>
          </select>
        </div>
      </div>
    </>
  );
}

export default ChangeCurrency;
