import React from "react";
import PostYourPropertyPage from "./NewPostProperty/PostYourPropertyPage";


function NewPostProperty() {
  return (
    <>
      <div className="relative">
        <div className="md:bg-contactimg md:h-[1200px] h-[1500px] bg-center bg-cover bg-no-repeat relative md:after:bg-black md:after:absolute md:after:inset-0 md:after:opacity-50"></div>

        <div className="px-5">
          <div className="grid place-items-center">
            <div className="md:w-2/3 w-11/12 container absolute md:top-40 top-28  md:bg-white rounded-xl md:mx-5 mx-5 md:shadow-lg md:shadow-abhita-100 overflow-hidden text-center md:py-5 ">
              <p className="md:pt-2 pt-5 text-center md:text-2xl text-2xl text-abhita-100 font-bold uppercase py-2">
                Add Your Property Details
              </p>
              <PostYourPropertyPage />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPostProperty;
