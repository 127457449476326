import axios from "axios";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
export const baseURL = "https://api.abhitaproperties.com/";
//export const baseURL = "http://localhost:5000";
//use this only
export const baseURL_path = `${baseURL}/admin/uploads/`;
export const baseURL_path_image = `${baseURL}/admin/uploads/gallery`;
const axiosInstance = axios.create({
  baseURL: baseURL,
});
export default axiosInstance;


export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};