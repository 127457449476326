import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../http-common";
import { useNavigate } from "react-router-dom";
import { checkemail } from "../config/validate";

function Login() {
  let navigate = useNavigate();
  const [state, setState] = useState({ EmailID: "", Password: "" });
  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  async function btnLogin() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    //  if (validate()) {
    axiosInstance
      .post("getlogindetail", {
        UserName: state.EmailID,
        Password: state.Password,
      })
      .then((response) => {
        if (response?.data && response?.data.length > 0) {
          sessionStorage.setItem("UserName", response?.data[0].UserName);
          sessionStorage.setItem("FullName", response?.data[0].FullName);
          sessionStorage.setItem("UserID", response?.data[0].ID);
          sessionStorage.setItem("Type", response?.data[0].Type);
          if (response?.data[0].Type === "seller") {
            navigate(`/`);
          } else if (response?.data[0].Type === "buyer") {
            navigate(`/`);
          } else {
            alert("EmailID or Password incorrect");
          }
        } else {
          alert("error");
        }
        // if (response?.data.length > 0) {
        //   setState(response?.data[0]);
        // } else {
        //   alert("api error");
        // }
      })
      .catch((error) => {
        alert(error);
      });
    // }
  }

  function validate() {
    let errors = {};
    let IsValid = true;
    if (!state?.EmailID) {
      IsValid = false;
      errors["EmailID"] = "EmailID is Required";
    } else {
      if (!checkemail(state.EmailID)) {
        IsValid = false;
        errors["EmailID"] = "Only valid EmailID Required";
      }
    }

    if (!state?.Password) {
      IsValid = false;
      errors["Password"] = "Password is Required";
    }
  }

  return (
    <>
      <div className="relative place-content-center">
        <div className="bg-logoinBgImg h-300 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-60"></div>

        <div className="absolute lg:w-11/12 md:w-11/12 bg-cover mx-auto top-40 left-0 right-0 text-center z-10 flex md:text-5xl text-3xl font-medium">
          <div className="flex md:flex-row flex-col w-full lg:px-20 md:px-0">
            <div className="w-1/2 bg-logoin lg:block md:block hidden bg-cover bg-no-repeat opacity-90"></div>
            <div className="md:w-1/2 bg-white p-5 md:mx-0 mx-5">
              <p className="md:pt-2 mb-4 pt-5 text-center  text-abhita-100 font-bold text-2xl uppercase">
                Login Your Account
              </p>
              <p className="mb-4  text-center  text-slate-400 font-bold text-sm tracking-widest">
                Welcome to Abhita Properties great to have you here.
              </p>
              <div className="pt-10">
                <div className=" grid grid-cols-1 gap-2 md:px-10">
                  <div className="">
                    <label
                      htmlFor="email"
                      className="block mb-1 text-lg tracking-wider font-medium text-black text-left focus:outline-none"
                    >
                      Email address
                    </label>

                    <input
                      type="email"
                      id="email"
                      className="border border-gray-300 text-black text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                      placeholder="Enter Email ID"
                      name="EmailID"
                      value={state.EmailID}
                      onChange={handlechange}
                      required
                    />
                    {state.errors ? (
                      <div className="text-red-600 text-left text-xs">
                        {state.errors.EmailID}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-sm text-right  text-blue-600 leading-none">
                    <a href="">Remind Me</a>
                  </div>
                  <div className="">
                    <label
                      htmlFor="password"
                      className="block mb-1 text-lg tracking-wider font-medium text-black text-left focus:outline-none"
                    >
                      Password
                    </label>

                    <input
                      type="password"
                      id="password"
                      className="border border-gray-300 text-black text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                      placeholder="Enter Password"
                      name="Password"
                      value={state.Password}
                      onChange={handlechange}
                      required
                    />
                    {state.errors ? (
                      <div className="text-red-600 text-left text-xs">
                        {state.errors.Password}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="text-right text-sm leading-none	">
                    <a href="" className="text-blue-600 hover:underline">
                      Forgot Password
                    </a>
                  </div>

                  <div className="flex  justify-center pb-5">
                    <div>
                      <button
                        type="button"
                        className="inline-block px-6 py-2.5  bg-abhita-300 text-white font-semibold rounded text-xs leading-tight uppercase focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-700 delay-150 ease-in-out hover:bg-blue-700"
                        onClick={btnLogin}
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                  <div className="flex place-content-center space-x-1 text-sm">
                    <p className="text-slate-800">sign Up to register?</p>
                    <p className="text-abhita-300 underline">
                      <Link to="/register">Create an account</Link>
                    </p>
                  </div>
                  <div className="md:py-0 px-2 grid place-content-center">
                    <p className="text-slate-600 text-xs text-center">
                    Abhita Properties collects and uses personal data in accordance
                      with our{" "}
                      <span className="hover:underline font-semibold">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </span>
                      . By creating an account, you agree to our{" "}
                      <span className="hover:underline font-semibold">
                        Terms & Conditions
                      </span>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
