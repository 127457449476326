//import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import { getmenuname } from "../config/constant";
import React, { useEffect, useState } from "react";
import { getCitylistForDDL } from "../config/api";
import {
  propertyprice,
  propertytype_buy,
  propertytype_commercial,
} from "../common";
import { Tab, initTE } from "tw-elements";

function SearchResult() {
  useEffect(() => {
    initTE({ Tab });
  }, []);
  const [state, setState] = useState({
    // LocalityID: "0",
    // PropertydetailID: id,
    // Address: "",
    // SocietyName: "",
    // Area: "",
    // Pincode: "",
    // City: "",
    // State: "",
    // Country: "",
    CityID: "0",
    PropertyPrice: "0",
    // StateID: "0",
    // CountryID: "0",
    // DistrictID: "0",

    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });

  const [cityddl, setCityDDL] = useState([]);

  const [locationValue, setLocationValue] = useState("");
  const [PTypeValue, setPTypeValue] = useState("");
  const [PropertyTypeValue, setPropertyTypeValue] = useState("");
  const [propertyPrice, setPropertyPrice] = useState("");

  useEffect(() => {
    BindCityDDL();
    // if (
    //   id !== "" &&
    //   id !== null &&
    //   id !== "0" &&
    //   id !== 0 &&
    //   typeof id !== "undefined"
    // )
  }, []);

  const handleLocationChange = (e) => {
    const query = e.target.value;
    setLocationValue(query);

  };
  console.log(locationValue);
  const handlePTypeChange = (e) => {
    const query = e.target.value;
    setPTypeValue(query);
 
  };
  const handlePropertyTypeChange = (e) => {
    const query = e.target.value;
    setPropertyTypeValue(query);
  };
  const handleBudgetChange = (e) => {
    const query = e.target.value;
    setPropertyPrice(query);
  };

  async function BindCityDDL() {
    await getCitylistForDDL(sessionStorage.getItem("UserID"))
      .then((response) => {
        setCityDDL(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }

  // console.log(setLocationValue);
  return (
    <>
      <Animated animationIn="slideInDown" animationOut="" isVisible={true}>
        <div className="sticky w-full ">
          <div className="absolute w-full lg:-bottom-32 md:-bottom-48 md:p-20 px-5 -bottom-60">
            <div className="w-full grid place-items-center ">
              <ul
                class="bg-white space-x-1 rounded-t flex flex-row flex-wrap list-none border-b-0 px-2"
                id="pills-tab"
                role="tablist"
                data-te-nav-ref
              >
                <li role="presentation">
                  <a
                    href="#pills-buy"
                    class="my-2 block rounded lg:px-7 lg:pb-3.5 lg:pt-4 md:px-7 md:pb-3.5 md:pt-4 py-2 px-3 text-sm font-medium uppercase leading-tight text-abhita-300 focus:text-white data-[te-nav-active]:!bg-abhita-300 data-[te-nav-active]:text-white dark:data-[te-nav-active]:text-white md:mr-4"
                    id="pills-buy-tab"
                    data-te-toggle="pill"
                    data-te-target="#pills-buy"
                    data-te-nav-active
                    role="tab"
                    aria-controls="pills-buy"
                    aria-selected="true"
                    onChange={handlePTypeChange}
                  >
                    Buy
                  </a>
                </li>

                <li role="presentation">
                  <a
                    href="#pills-rent"
                    class="my-2 block rounded lg:px-7 lg:pb-3.5 lg:pt-4 md:px-7 md:pb-3.5 md:pt-4 py-2 px-3 text-xs font-medium uppercase leading-tight text-abhita-300 data-[te-nav-active]:!bg-abhita-300 data-[te-nav-active]:text-white md:mr-4"
                    id="pills-rent-tab"
                    data-te-toggle="pill"
                    data-te-target="#pills-rent"
                    role="tab"
                    aria-controls="pills-rent"
                    aria-selected="false"
                    onChange={handlePTypeChange}
                  >
                    Rent
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#pills-commercial"
                    class="my-2 block rounded lg:px-7 lg:pb-3.5 lg:pt-4 md:px-7 md:pb-3.5 md:pt-4 py-2 px-3 text-xs font-medium uppercase leading-tight text-abhita-300 data-[te-nav-active]:!bg-abhita-300 data-[te-nav-active]:text-white md:mr-4"
                    id="pills-commercial-tab"
                    data-te-toggle="pill"
                    data-te-target="#pills-commercial"
                    role="tab"
                    aria-controls="pills-commercial"
                    aria-selected="false"
                  >
                    Commercial
                  </a>
                </li>
              </ul>

              <div className="bg-white rounded shadow-lg shadow-slate-200">
                <div class="mb-6">
                  <div
                    class="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                    id="pills-buy"
                    role="tabpanel"
                    aria-labelledby="pills-buy-tab"
                    data-te-tab-active
                  >
                    <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-3 p-7">
                      <div>
                        <select
                          className="border w-full bg-white  text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                          onChange={handleLocationChange}
                          value={locationValue}
                          data-name="CityName"
                          name="CityName"
                        >
                          <option className="text-lg">Select Location</option>
                          {cityddl?.map((item, idx) => (
                            <option key={idx} value={item.CityName}>
                              {" "}
                              {item.CityName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <select
                          onChange={handleBudgetChange}
                          className="border w-full bg-white text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                          value={propertyPrice}
                          data-name="PropertyPrice"
                          name="PropertyPrice"
                        >
                          <option value="0" selected>
                            Select Below Budget
                          </option>
                          {propertyprice?.map((item, index) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <select
                          onChange={handlePropertyTypeChange}
                          className="border w-full bg-white  text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                        >
                          <option>Property Type</option>
                          {propertytype_buy?.map((item, index) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <input
                          text="text"
                          placeholder="Search here"
                          className="border w-full bg-white  text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                        />
                      </div>
                      <div className="lg:col-span-1 md:col-span-2">
                        <Link
                          to={
                              "property-list?t=buy&l=" +
                              getmenuname(locationValue) +
                              "&b=" +
                              propertyPrice +
                              // "&pt="+
                              getmenuname(PTypeValue) +
                              "&pt=" +
                              getmenuname(PropertyTypeValue)
                            }
                          //"/propertiesliststatic"
                          
                          className="search-button btn rounded-2xl"
                        >
                          <button className="border w-full font-medium text-sm text-center bg-abhita-300 text-white shadow shadow-slate-100 py-3 rounded">
                            Search
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                    id="pills-rent"
                    role="tabpanel"
                    aria-labelledby="pills-rent-tab"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-3 p-7">
                      <div>
                        <select
                          className="border w-full bg-white  text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                          onChange={handleLocationChange}
                          value={locationValue}
                          data-name="CityID"
                          name="CityID"
                        >
                          <option className="text-lg">Select Location</option>

                          {cityddl?.map((item, idx) => (
                            <option key={idx} value={item.CityName}>
                              {item.CityName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <select
                          onChange={handleBudgetChange}
                          className="border w-full bg-white text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                          value={propertyPrice}
                          data-name="PropertyPrice"
                          name="PropertyPrice"
                        >
                          <option value="0" selected>
                            Select Below Budget
                          </option>
                          {propertyprice?.map((item, index) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <input
                          text="text"
                          placeholder="Search here"
                          className="border w-full bg-white text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                        />
                      </div>
                      <div>
                        <Link
                          to={
                            "property-list?t=rent&l=" +
                            getmenuname(locationValue) +
                            "&b=" +
                            propertyPrice +
                            // "&pt="+
                            // getmenuname(PTypeValue)+
                            "&pt=" +
                            getmenuname(PropertyTypeValue)
                          }
                          // "/propertiesliststatic"
                          className="search-button btn rounded-2xl"
                        >
                          <button className="border w-full font-medium text-sm text-center bg-abhita-300 text-white shadow shadow-slate-100 py-3 rounded">
                            Search
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                    id="pills-commercial"
                    role="tabpanel"
                    aria-labelledby="pills-commercial-tab"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-3 p-7">
                      <div>
                        <select
                          className="border w-full bg-white  text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                          onChange={handleLocationChange}
                          value={locationValue}
                          data-name="CityID"
                          name="CityID"
                        >
                          <option className="text-lg">Select Location</option>
                          {cityddl?.map((item, idx) => (
                            <option key={idx} value={item.CityName}>
                              {" "}
                              {item.CityName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <select
                          onChange={handleBudgetChange}
                          className="border w-full bg-white text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                          value={propertyPrice}
                          data-name="PropertyPrice"
                          name="PropertyPrice"
                        >
                          <option value="0" selected>
                            Select Below Budget
                          </option>
                          <option value="500000">₹ 5 lac</option>
                          <option value="2500000">₹ 25 lac</option>
                          <option value="5000000">₹ 50 lac</option>
                          <option value="10000000">₹ 1 Cr</option>
                          <option value="50000000">₹ 5 Cr</option>
                          <option value="500000000">₹ 50 Cr</option>
                          <option value="750000000">₹ 75 Cr</option>
                        </select>
                      </div>
                      <div>
                        <select
                          onChange={handlePropertyTypeChange}
                          className="border w-full bg-white text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                        >
                          <option>Property Type</option>
                          {propertytype_commercial?.map((item, index) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <input
                          text="text"
                          placeholder="Search here"
                          className="border w-full bg-white text-xs font-medium border-slate-300 text-left shadow shadow-slate-100 pl-3 pr-16 py-3 rounded"
                        />
                      </div>
                      <div>
                        <Link
                          to= {
                              "property-list?t=commercial&l=" +
                              getmenuname(locationValue) +
                              "&b=" +
                              propertyPrice +
                              // "&pt="+
  
                              // getmenuname(PTypeValue)+
                              "&pt=" +
                              getmenuname(PropertyTypeValue)
                            }
                          //"/propertiesliststatic"
                          
                          className="search-button btn rounded-2xl"
                        >
                          <button className="border w-full font-medium text-sm text-center bg-abhita-300 text-white shadow shadow-slate-100 py-3 rounded">
                            Search
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Animated>
    </>
  );
}

export default SearchResult;
