import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Breadcrums from "../featuredProjectsDetails2/Breadcrums";
import ImpAddress from "../featuredProjectsDetails2/ImpAddress";
import ImpAmenities from "../featuredProjectsDetails2/ImpAmenities";
import ImpContactSeller from "../featuredProjectsDetails2/ImpContactSeller";
import ImperiaGallary from "../featuredProjectsDetails2/ImperiaGallary";
import ImpHeadingSection from "../featuredProjectsDetails2/ImpHeadingSection";
import axiosInstance, { useQuery } from "../http-common";
import AdvSearch from "../viewDetails/AdvSearch";
import ChangeMsrmnt from "../viewDetails/ChangeMsrmnt";
import Details from "../viewDetails/Details";
import FeaturedProject from "../viewDetails/FeaturedProperties";
import OurListings from "../viewDetails/OurListings";
import Overview from "../viewDetails/Overview";

function BannerDetails2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [show, setShow] = useState(true);
  const [data_overview, setData_overview] = useState({
    updateon: "",
    bedroom: "0",
    bathroom: "0",
    yearbuilt: "0",
    bhktype: "0",
    availability: "0",
  });
  const [data_locality, setData_locality] = useState({
    address: "",
    societyname: "0",
    area: "0",
    pincode: "0",
    city: "0",
    state: "0",
    country: "0",
  });
  const [data_detail, setData_detail] = useState({
    propertyprice: "",
    propertypriceinfo: "0",
    propertysize: "0",
    bedroom: "0",
    bathroom: "0",
    floor: "0",
    flats: "0",
    furnishing: "0",
    parking: "0",
    bHK_1_MinBudget:"",
    bHK_1_MaxBudget:"",
    bHK_1_PropertySize:"",
    bHK_2_MinBudget:"",
    bHK_2_MaxBudget:"",
    bHK_2_PropertySize:"",
    bHK_3_MinBudget:"",
    bHK_3_MaxBudget:"",
    bHK_3_PropertySize:"",
    bHK_4_MinBudget:"",
    bHK_4_MaxBudget:"",
    bHK_4_PropertySize:"",
  });
  const [data_amenities, setData_amenities] = useState({ feature: "" });
  const [image, setImage] = useState([]);
  const [heading, setheading] = useState({
    propertyname: "",
    type: "",
    name: "",
  });
  useEffect(() => {
    getData();
  }, []);
  const query = useQuery();
  const id = query.get("id");
  function getData() {
    axiosInstance
      .post("getPropertydetailbyid_web", { PropertydetailID: id })
      .then((response) => {
        debugger
        if (response?.data.length > 0) {
          console.log("BannerDetail",response.data)
          setImage(response.data[1]);
          let d = response?.data[0][0];
          setheading({
            propertyname: d.PropertyName,
            type: d.PlanType,
            name: d.name,
          });
          setData_overview({
            updateon: d.updateon,
            bedroom: d.Bedroom,
            bathroom: d.Bathroom,
            yearbuilt: d.YearBuilt,
            parking: d.Parking,

          });
          setData_locality({
            address: d.Address,
            societyname: d.SocietyName,
            area: d.Area,
            pincode: d.Pincode,
            city: d.CityName,
            state: d.StateName,
            country: d.CountryName,
          });
          setData_detail({
            maxPrice: d.maxPrice,
            minPrice: d.minPrice,
            propertysize: d.PropertySize,
            availability: d.Availability,
            balcony: d.Balcony,
            flats: d.Flats,
            floor: d.Floor,
            furnishing: d.Furnishing,
            specification: d.Specification,
            bHK_1_MinBudget: d.BHK_1_MinBudget,
            bHK_1_MaxBudget: d.BHK_1_MaxBudget,
            bHK_1_PropertySize: d.BHK_1_PropertySize,
            bHK_2_MinBudget: d.BHK_2_MinBudget,
            bHK_2_MaxBudget: d.BHK_2_MaxBudget,
            bHK_2_PropertySize: d.BHK_2_PropertySize,
            bHK_3_MinBudget: d.BHK_3_MinBudget,
            bHK_3_MaxBudget: d.BHK_3_MaxBudget,
            bHK_3_PropertySize: d.BHK_3_PropertySize,
            bHK_4_MinBudget: d.BHK_4_MinBudget,
            bHK_4_MaxBudget: d.BHK_4_MaxBudget,
            bHK_4_PropertySize: d.BHK_4_PropertySize,
          });
          setData_amenities({ feature: d.OtherFeature });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
console.log(show)
  return (
    <>
      <div className="bg-slate-100">
        <ImperiaGallary data={image ? image : ""} />
        <Breadcrums data={heading ? heading : ""} />
        <div className="md:p-10 p-5">
          <ImpHeadingSection data={heading ? heading : ""} />
          <div className="pt-10">
            <div className="grid lg:grid-cols-3 md:gri-cols-3 grid-cols-1 lg:gap-5 md:gap-0 gap-0 lg:space-y-0 md:space-y-5 space-y-5 items-start">
              <div className="col-span-2 space-y-6">
                <Overview data={data_overview ? data_overview : ""} />
                {/* <Description /> */}
                <ImpAddress data={data_locality ? data_locality : ""} />
                <Details data={data_detail ? data_detail : ""} />
                <ImpAmenities data={data_amenities ? data_amenities : ""} />
              </div>
              <div className="space-y-6 w-full">
                <ImpContactSeller />
                <ChangeMsrmnt />
                <AdvSearch />
                <OurListings />
                <FeaturedProject />
              </div>
            </div>
          </div>
        </div>
      </div>
      {show?<div
        className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="exampleModalCenteredScrollable"
        tabIndex="-1"
        aria-labelledby="exampleModalCenteredScrollable"
        aria-modal="true"
        aria-hidden={show}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-bold leading-normal text-gray-800"
                id="exampleModalCenteredScrollableLabel"
              >
                Disclaimer
              </h5>
              {/* <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <div className="modal-body relative p-4">
              <p className="text-justify pt-2 font-semibold">
                The data (based on the search query performed), on the webpage
                of Abhita Properties.in has been made available for informational
                purposes only and no representation or warranty is expressly or
                impliedly given as to its accuracy. Any investment decisions
                that you take should not be based solely on the information that
                is available on the website Abhita Properties.in or downloaded from
                it. Nothing contained herein shall be deemed to constitute legal
                advice, solicitation, invitation to acquire by the
                developer/builder or any other entity. You are advised to visit
                the relevant RERA website and contact builder/advertisers
                directly to know more about the project before taking any
                decision based on the contents displayed on the website
                Abhita Properties.in. If you have any question or want to share
                feedback, feel free to write to us at support@AbhitaProperties.in.
                Trademarks belong to the respective owners.
              </p>
              <button style={{float:"right"}} onClick={()=>{setShow(false)}}>Close</button>
            </div>
          </div>
        </div>
      </div>:""}
    </>
  );
}

export default BannerDetails2;
