//import React from "react";
//import FormControl from "../../components/FormControl";
//import { Row, Col, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  iuamenities,
  getAmenitiesDetailByID,
  useQuery,
  getAmenityDetail,
  iuamenitymaster,
} from "../../config/api";
import { useNavigate, useLocation } from "react-router-dom";

function PropertyFeatures({ setSmShow, editID }) {
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

  const id = query.get("pid");
  const [state, setState] = useState({
    AmenitiesID: "0",
    PropertydetailID: id,
    Bedroom: "",
    Bathroom: "",
    Balcony: "",
    Floor: "",
    Flats: "",
    Parking: "",
    OtherFeature: "",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  const [feature, setFeature] = useState([]);
  const [amenity, setAmenity] = useState([]);
  const [amenityName, setAmenityName] = useState("");
  // const id = query.get("id");
  // const id = editID;
  useEffect(() => {
    // BindEducationDDL();
    getAmenity();
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(id);
    }
  }, []);

  const bedroomoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const bathroomoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const balconiesoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const flooroptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const flatoptions = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
  ];

  const parkingoptions = [
    { value: "2- Wheeler", text: "2- Wheeler" },
    { value: "4- Wheeler", text: "4- Wheeler" },
  ];

  // function handlechange(e) {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // }

  function handlechange(e) {
    if (e.target.name === "AvailableAmenities") {
      let temp = [];
      for (
        let i = 0;
        i < e.target.parentElement.parentElement.children.length;
        i++
      ) {
        if (
          e.target.parentElement.parentElement.children[i].children[0].checked
        ) {
          temp.push(
            e.target.parentElement.parentElement.children[i].children[0].value
          );
        }
      }
      setState({ ...state, [e.target.name]: temp.join(",") });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  }
  function handlefeature(e) {
    let arr = [...feature];
    if (e.target.checked) {
      arr.push(e.target.value);
    } else {
      arr.pop(e.target.value);
    }
    setFeature(arr);
    setState({ ...state, OtherFeature: arr.toString() });
  }

  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iuamenities(state)
        .then((response) => {
          if (response[0].ID !== "exists") {
            alert("Amenities Details Saved Successfully");
            ResetState();
            // window.location.reload(true);
            navigate(`/post-your-property/gallery?pid=${id}`);
          }
          //  else {
          //   errors["PropertyType"] = "This Property Type Already exists...";
          // }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  async function SaveAmenity() {
    debugger
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (amenityName !== "") {
      await iuamenitymaster({ ID: "0", Name: amenityName })
        .then((response) => {
          if (response[0].ID !== "exists") {
            alert("Name Saved Successfully");
            setAmenityName("");
            getAmenity();
          }
          else {
            alert("amenity name already exists...");
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Please enter amenity name");
    }
  }

  async function UpdateData(PropertydetailID) {
    await getAmenitiesDetailByID(PropertydetailID)
      .then((response) => {
        if (response !== "" && response?.length > 0) {
          setState(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function getAmenity() {
    await getAmenityDetail()
      .then((response) => {
        if (response !== "" && response?.length > 0) {
          let a = [];
          for (let i = 0; i < response.length; i++) {
            a.push(response[i].Name);
          }
          setAmenity(a);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function ResetState() {
    setState({
      AmenitiesID: "0",
      PropertydetailID: id,
      Bedroom: "",
      Bathroom: "",
      Balcony: "",
      Floor: "",
      Flats: "",
      Parking: "",
      OtherFeature: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Bedroom) {
      IsValid = false;
      errors["Bedroom"] = "Bedroom  is Required";
    }
    if (!state?.Bathroom) {
      IsValid = false;
      errors["Bathroom"] = "Bathroom is Required";
    }
    if (!state?.Balcony) {
      IsValid = false;
      errors["Balcony"] = "Balcony is Required";
    }
    if (!state?.Floor) {
      IsValid = false;
      errors["Floor"] = "Floor is Required";
    }
    if (!state?.Flats) {
      IsValid = false;
      errors["Flats"] = "Flats is Required";
    }
    if (!state?.Parking) {
      IsValid = false;
      errors["Parking"] = "Parking is Required";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }


  console.log(state);

  return (
    <>
      <div className="text-xl font-semibold  underline underline-offset-[6px] decoration-abhita-200 decoration-[2px]">
        <p>Property Features</p>
      </div>

      <div className="px-5 pt-5 grid md:grid-cols-3 grid-cols-1 gap-2">
        <div className="mb-2">
          <label
            for="Bedroom"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            Bedrooms
          </label>
          <select
            id="Bedroom"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            name="Bedroom"
            value={state.Bedroom}
            onChange={handlechange}
          >
            <option className="text-sm">Select Bedrooms</option>
            {bedroomoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {" "}
                {item.text}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.Bedroom}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="mb-2">
          <label
            for="Bathroom"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            Bathrooms
          </label>
          <select
            id="Bathroom"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            data-name="Bathroom"
            name="Bathroom"
            value={state.Bathroom}
            onChange={handlechange}
          >
            <option className="text-sm">Select Bathrooms</option>
            {bathroomoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {" "}
                {item.text}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.Bathroom}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="mb-2">
          <label
            for="Balcony"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            Balconies
          </label>

          <select
            id="Balcony"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            name="Balcony"
            value={state.Balcony}
            onChange={handlechange}
          >
            <option className="text-sm">Select Balcony</option>
            {balconiesoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {" "}
                {item.text}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.Balcony}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="mb-2">
          <label
            for="Floor"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            Floor Number
          </label>

          <select
            id="Floor"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            name="Floor"
            value={state.Floor}
            onChange={handlechange}
          >
            <option className="text-sm">Select Floor No.</option>
            {flooroptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {" "}
                {item.text}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.Floor}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="mb-2">
          <label
            for="Flats"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            Total Flats
          </label>

          <select
            id="Flats"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            name="Flats"
            value={state.Flats}
            onChange={handlechange}
          >
            <option className="text-sm">Select Total Flats</option>
            {flatoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {" "}
                {item.text}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.Flats}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="mb-2">
          <label
            for="Parking"
            className="block mb-1 text-sm font-medium text-black text-left"
          >
            Parking
          </label>

          <select
            id="Parking"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            name="Parking"
            value={state.Parking}
            onChange={handlechange}
          >
            <option className="text-sm">Select Parking</option>
            {parkingoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {" "}
                {item.text}
              </option>
            ))}
          </select>
          {state.errors ? (
            <div className="text-red-600 text-left text-xs">
              {state.errors.Parking}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="md:mb-5 mb-2 px-5 pt-5">
        <p className="text-slate-600 text-lg font-semibold mb-5">Other Features</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-2 text-sm font-medium">
          {amenity && amenity.map((item, index) => (
            <div className="flex space-x-2" key={index}>
              <input
                type="checkbox"
                label={item}
                value={item}
                onChange={handlefeature}
                checked={state.OtherFeature.includes(item) ? true : false}
              />
              <p> {item}</p>
            </div>
          ))}

        </div>
      </div>
      {/* <div className="relative z-0 p-10">
        <button
          className="w-full h-9 p-2 text-white font-medium bg-amber-500 rounded"
          onClick={SaveData}
        >
          SUBMIT & NEXT
        </button>
      </div> */}
      <div className="flex space-x-2 justify-between py-5 px-10">
        <div>
          <button
            type="button"
            className="inline-block px-6 py-2.5  bg-gray-500 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-abhita-300 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={() => {
              navigate(`/post-your-property/locality-detail?pid=${id}`);
            }}
          >
            Previous
          </button>
        </div>

        <div>
          <button
            type="button"
            className="inline-block px-6 py-2.5  bg-abhita-300 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={SaveData}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default PropertyFeatures;
