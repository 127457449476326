import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { propertytype_buy, propertytype_commercial, replaceQuery } from "../common";
import { getmenuname } from "../config/constant";
import { useQuery } from "../http-common";

function AdvSearch() {
  const navigate = useNavigate();
  const query = useQuery();
  const [filter, setFilter] = useState({
    PType: query.get("t"),
  });
  function handlechange(val, name, arg) {
    //
    
    let key = "",
      value = "", classname = [], arr = [];
    //console.log(year,"year")
    if (name === "property_type") {
      classname = document.getElementsByClassName('propertytype');
      for (let i = 0; i < classname.length; i++) {
        if (classname[i].checked) {
          arr.push(getmenuname(classname[i].value));
        }
      };
      value = arr.join(',');
      key = "pt";
    }
    if (name === "availability") {
      value = getmenuname(val);
      key = "a";
    }
    if (name === "preferred_tenants") {
      classname = document.getElementsByClassName('preferredtenants');
      for (let i = 0; i < classname.length; i++) {
        if (classname[i].checked) {
          arr.push(getmenuname(classname[i].value));
        }
      };
      value = arr.join(',');
      key = "pta";
    }
    if (name === "furnished") {
      classname = document.getElementsByClassName('furnished');
      for (let i = 0; i < classname.length; i++) {
        if (classname[i].checked) {
          arr.push(getmenuname(classname[i].value));
        }
      };
      value = arr.join(',');
      key = "f";
    }
    if (name === "parking") {
      classname = document.getElementsByClassName('parking');
      for (let i = 0; i < classname.length; i++) {
        if (classname[i].checked) {
          arr.push(getmenuname(classname[i].value));
        }
      };
      value = arr.join(',');
      key = "pw";
    }
    if (name === "bhk_type") {
      classname = document.getElementsByClassName('bhktype');
      for (let i = 0; i < classname.length; i++) {
        if (classname[i].checked) {
          arr.push(getmenuname(classname[i].value));
        }
      };
      value = arr.join(',');
      key = "bt";
    }
    const _query = replaceQuery(key, value, query);
    navigate("/property-list?" + _query);
  }
  return (
    <>
      <div className="">
        <div className="pb-2">
          <p className="bg-white shadow py-2 rounded text-xl text-center text-slate-800 font-semibold">
            Advance Search
          </p>
        </div>
        <div className="space-y-2">
          <div className="bg-white shadow p-5 rounded">
            <p className="text-sm font-medium pb-3">Property Type</p>
            <ul
              className="overflow-y-auto text-sm space-y-2"
              aria-labelledby="dropdownSearchButton"
            >
              {filter.PType !== undefined &&
                filter.PType !== "undefined" &&
                filter.PType !== null &&
                filter.PType !== "" ? (filter.PType === "buy" ?
                  propertytype_buy?.map((item, index) => (
                    <li key={index}>
                      <div className="flex items-center rounded">
                        <input
                          id="checkbox-item-11"
                          type="checkbox"
                          value={item.value}
                          className="w-4 h-4 rounded border-gray-300 propertytype"
                          onChange={(e) =>
                            handlechange(e.target.value, "property_type")}
                        />
                        <label
                          htmlFor="checkbox-item-11"
                          className="ml-2 w-full text-sm rounded"
                        >
                          {item.label}
                        </label>
                      </div>
                    </li>
                  )) : filter.PType === "commercial" ?
                    propertytype_commercial?.map((item, index) => (
                      <li key={index}>
                        <div className="flex items-center rounded">
                          <input
                            id="checkbox-item-11"
                            type="checkbox"
                            value={item.value}
                            className="w-4 h-4 rounded border-gray-300"
                            onChange={(e) =>
                              handlechange(e.target.value, "property_type")}
                          />
                          <label
                            htmlFor="checkbox-item-11"
                            className="ml-2 w-full text-sm rounded"
                          >
                            {item.label}
                          </label>
                        </div>
                      </li>
                    )) : "") : ""
              }
              {/* 
              (propertytype_buy?map((item,index)=>(<li key={index}>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value={item.value}
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    {item.label}
                  </label>
                </div>
              </li>))):""):""
              filter.PType !== "commercial"?
              propertytype_commercial?map((item,index)=>(<li key={index}>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value={item.value}
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    {item.label}
                  </label>
                </div>
              </li>))):""
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Independent House
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                   Villa
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Studio
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Agriculture Land
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Studio
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Plot
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Office Space
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Shop
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Warehouse / Godown
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Commercial Plot
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 rounded border-gray-300"
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Other
                  </label>
                </div>
              </li> */}
            </ul>
          </div>
          <div className="bg-white shadow p-5 rounded">
            <p className="text-sm font-medium pb-3">Availability</p>
            <div className="">
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="Immediate"
                    onChange={(e) =>
                      handlechange(e.target.value, "availability")}
                  />
                  <label
                    className="form-check-label inline-block text-sm"
                    htmlFor="flexRadioDefault1"
                  >
                    Immediate
                  </label>
                </div>
              </div>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="Within 15 Days"
                    onChange={(e) =>
                      handlechange(e.target.value, "availability")}
                  />
                  <label
                    className="form-check-label inline-block text-sm"
                    htmlFor="flexRadioDefault1"
                  >
                    Within 15 Days
                  </label>
                </div>
              </div>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="Within 30 Days"
                    onChange={(e) =>
                      handlechange(e.target.value, "availability")}
                  />
                  <label
                    className="form-check-label inline-block text-sm"
                    htmlFor="flexRadioDefault1"
                  >
                    Within 30 Days
                  </label>
                </div>
              </div>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="After 15 Days"
                    onChange={(e) =>
                      handlechange(e.target.value, "availability")}
                  />
                  <label
                    className="form-check-label inline-block text-sm"
                    htmlFor="flexRadioDefault1"
                  >
                    After 15 Days
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow p-5 rounded">
            <p className="text-sm font-medium pb-3">furnished</p>
            <ul
              className="overflow-y-auto text-sm grid grid-cols-2 gap-y-2"
              aria-labelledby="dropdownSearchButton"
            >
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value="Fully Furnished"
                    className="furnished w-4 h-4 rounded border-gray-300"
                    onChange={(e) =>
                      handlechange(e.target.value, "furnished")}
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Fully Furnished
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value="Semi-Furnished"
                    className="furnished w-4 h-4 rounded border-gray-300"
                    onChange={(e) =>
                      handlechange(e.target.value, "furnished")}
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Semi-Furnished
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value="Unfurnished"
                    className="furnished w-4 h-4 rounded border-gray-300"
                    onChange={(e) =>
                      handlechange(e.target.value, "furnished")}
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    Unfurnished
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div className="bg-white shadow p-5 rounded">
            <p className="text-sm font-medium pb-3">Parking</p>
            <ul
              className="overflow-y-auto text-sm space-y-2"
              aria-labelledby="dropdownSearchButton"
            >
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value="2- Wheeler"
                    className="parking w-4 h-4 rounded border-gray-300"
                    onChange={(e) =>
                      handlechange(e.target.value, "parking")}
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    2- Wheeler
                  </label>
                </div>
              </li>
              <li>
                <div className="flex items-center rounded">
                  <input
                    id="checkbox-item-11"
                    type="checkbox"
                    value="4- Wheeler"
                    className="parking w-4 h-4 rounded border-gray-300"
                    onChange={(e) =>
                      handlechange(e.target.value, "parking")}
                  />
                  <label
                    htmlFor="checkbox-item-11"
                    className="ml-2 w-full text-sm rounded"
                  >
                    4- Wheeler
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvSearch;
