import React from 'react';
import { mdiAlphaPCircleOutline, mdiCheckAll, mdiClose } from '@mdi/js';
import Icon from '@mdi/react'

function TenantPlans() {
  return (
    <>
      <div className="pt-40 pb-20 md:px-20 px-5 w-full bg-gray-50">
        <p className="text-center font-semibold text-3xl">
            Tenant Plans
        </p>
        <div className="grid md:grid-cols-4 grid-cols-1 md:gap-5 gap-y-20 place-items-center py-20">

          <div className='bg-white border border-amber-500 rounded-lg p-10 w-80'>
            <div className='flex space-x-3'>
              <Icon path={mdiAlphaPCircleOutline} className="w-8 h-8 text-amber-500"/>
              <p className='font-medium text-left text-2xl uppercase pb-5'>Basic Plan</p>
            </div>
            <ul className='space-y-2'>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-amber-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-amber-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-amber-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-amber-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-amber-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
            </ul>
            <div className='text-center'>
                 <p className='text-amber-500 font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-slate-500 opacity-80 font-medium text-center'>Per Month</p>
            </div>
            <div className='pt-5'>
            <button className='bg-gradient-to-t from-amber-400 to-amber-600 text-white font-semibold px-10 py-3 w-full rounded-lg'>Purchase</button>
            </div>
          </div>

          <div className='bg-white border border-violet-900 rounded-lg p-10 w-80'>
            <div className='flex space-x-3'>
              <Icon path={mdiAlphaPCircleOutline} className="w-8 h-8 text-violet-900"/>
              <p className='font-medium text-left text-2xl uppercase pb-5'>Standard Plan</p>
            </div>
            <ul className='space-y-2'>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-violet-900 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-violet-900 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-violet-900 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-violet-900 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-violet-900 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
            </ul>
            <div className='text-center'>
                 <p className='text-violet-900 font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-slate-500 opacity-80 font-medium text-center'>Per Month</p>
            </div>
            <div className='pt-5'>
            <button className='bg-gradient-to-t from-violet-700 to-violet-900 text-white font-semibold px-10 py-3 w-full rounded-lg'>Purchase</button>
            </div>
          </div>

          <div className='bg-white border border-cyan-500 rounded-lg p-10 w-80'>
            <div className='flex space-x-3'>
              <Icon path={mdiAlphaPCircleOutline} className="w-8 h-8 text-cyan-500"/>
              <p className='font-medium text-left text-2xl uppercase pb-5'>Premium Plan</p>
            </div>
            <ul className='space-y-2'>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
            </ul>
            <div className='text-center'>
                 <p className='text-cyan-500 font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-slate-500 opacity-80 font-medium text-center'>Per Month</p>
            </div>
            <div className='pt-5'>
            <button className='bg-gradient-to-t from-cyan-400 to-cyan-600 text-white font-semibold px-10 py-3 w-full rounded-lg'>Purchase</button>
            </div>
          </div>

          <div className='bg-white border border-pink-500 rounded-lg p-10 w-80'>
            <div className='flex space-x-3'>
              <Icon path={mdiAlphaPCircleOutline} className="w-8 h-8 text-pink-500"/>
              <p className='font-medium text-left text-2xl uppercase pb-5'>Premium Plan</p>
            </div>
            <ul className='space-y-2'>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-pink-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-pink-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-pink-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-pink-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-pink-500 w-5 h-5" /> <p>Lorem ipsum Lorem</p></li>
            </ul>
            <div className='text-center'>
                 <p className='text-pink-500 font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-slate-500 opacity-80 font-medium text-center'>Per Month</p>
            </div>
            <div className='pt-5'>
            <button className='bg-gradient-to-t from-pink-400 to-pink-600 text-white font-semibold px-10 py-3 w-full rounded-lg'>Purchase</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TenantPlans;
