import React from "react";

function NewContactUs() {
  const form = [
    {
      title: "First Name",
      type: "text",
      placeholder: "Enter First Name",
    },
    {
      title: "Middle Name",
      type: "text",
      placeholder: "Enter Middle Name",
    },
    {
      title: "Last Name",
      type: "text",
      placeholder: "Enter Last Name",
    },
    {
      title: "Email ID",
      type: "email",
      placeholder: "Enter Email ID",
    },
    {
      title: "Contact Number",
      type: "number",
      placeholder: "Enter Middle Name",
    },
  ];
  return (
    <>
      <div className="">
        <div className="bg-newcontact min-h-screen bg-center bg-cover relative after:absolute after:inset-0"></div>
        <div className="absolute md:top-7 top-5 md:left-40 left-0 md:right-auto right-0 m-5 md:bottom-auto bottom-10 py-16">
          <div className="bg-transparent p-5 rounded shadow-lg shadow-gray-300 border">
            <p className="text-center font-bold text-2xl text-white pb-5">
              Contact us
            </p>
            {form?.map((f, idx) => (
              <div className="" key={idx}>
                <FormBlog {...f} />
              </div>
            ))}
            <div className="">
              <button className="bg-abhita-400 text-white text-md rounded w-full py-2 font-semibold uppercase">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FormBlog({ title, placeholder, type }) {
  return (
    <>
      <div className="">
        <p className="text-white font-semibold text-sm upperase pb-1">
          {title}
        </p>
        <input
          type={type}
          placeholder={placeholder}
          className="md:w-80 w-full md:py-1 placeholder:text-sm px-2 md:mb-5 mb-2 border-2 border-white rounded hover:border-abhita-300 focus:border-abhita-300 focus:outline-none"
        />
      </div>
    </>
  );
}

export default NewContactUs;
