import React, { useEffect } from "react";
import { Carousel, initTE } from "tw-elements";

function HeadSection() {
  useEffect(() => {
    initTE({ Carousel });
  }, []);

  return (
    <>
      {/* <div className="relative place-content-center">

        <div
          id="carouselDarkVariant"
          className="carousel slide carousel-fade carousel-dark relative z-10"
          data-bs-ride="carousel"
        >
          <div className="absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
            <button
              data-bs-target="#carouselDarkVariant"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              data-bs-target="#carouselDarkVariant"
              data-bs-slide-to="1"
              aria-label="Slide 1"
            ></button>
            <button
              data-bs-target="#carouselDarkVariant"
              data-bs-slide-to="2"
              aria-label="Slide 1"
            ></button>
          </div>

          <div className="carousel-inner relative w-full overflow-hidden">
            <div className="carousel-item active relative float-left w-full">
              <div className="bg-home h-200 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-40">
                <div className="carousel-caption md:block absolute top-48 z-40 ">
                  <p className="text-center font-semibold md:text-4xl text-2xl text-white">
                    Find Your Dream Property
                  </p>
                </div>
              </div>
            </div>

            <div className="carousel-item relative float-left w-full">
              <div className="bg-contactimg h-200 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-40"></div>
              <div className="carousel-caption md:block absolute top-48 z-40 ">
                <p className="text-center font-semibold md:text-4xl text-2xl text-white">
                  Simplify The Dream
                </p>
              </div>
            </div>

            <div className="carousel-item relative float-left w-full">
              <div className="bg-building h-200 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-40"></div>
              <div className="carousel-caption md:block absolute top-48 z-40 ">
                <p className="text-center font-semibold md:text-4xl text-2xl text-white">
                  Simplify The Mortagage
                </p>
              </div>
            </div>
          </div>

          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselDarkVariant"
            data-bs-slide="prev"
          >
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselDarkVariant"
            data-bs-slide="next"
          >
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div> */}

      <div
        id="carouselExampleCrossfade"
        class="relative"
        data-te-carousel-init
        data-te-carousel-slide
      >
        <div
          class="absolute inset-x-0 bottom-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
          data-te-carousel-indicators
        >
          <button
            type="button"
            data-te-target="#carouselExampleCrossfade"
            data-te-slide-to="0"
            data-te-carousel-active
            class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-te-target="#carouselExampleCrossfade"
            data-te-slide-to="1"
            class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-te-target="#carouselExampleCrossfade"
            data-te-slide-to="2"
            class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
            aria-label="Slide 3"
          ></button>
        </div>

        <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
          <div
            class="relative float-left -mr-[100%] w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-fade
            data-te-carousel-item
            data-te-carousel-active
          >
            <div className="bg-home h-[450px] bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-40">
              <div className="carousel-caption md:block absolute top-40 z-40 left-0 right-0 mx-auto container">
                <p className="text-center font-semibold md:text-4xl text-2xl text-white">
                  Find Your Dream Property
                </p>
              </div>
            </div>
          </div>

          <div
            class="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-fade
            data-te-carousel-item
          >
            <div className="bg-contactimg h-[450px] bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-40">
              <div className="carousel-caption md:block absolute top-40 z-40 left-0 right-0 mx-auto container">
                <p className="text-center font-semibold md:text-4xl text-2xl text-white">
                  Simplify The Dream
                </p>
              </div>
            </div>
          </div>

          <div
            class="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-te-carousel-fade
            data-te-carousel-item
          >
            <div className="bg-building h-[450px] bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-40">
              <div className="carousel-caption md:block absolute top-40 z-40 left-0 right-0 mx-auto container">
                <p className="text-center font-semibold md:text-4xl text-2xl text-white">
                  Simplify The Mortagage
                </p>
              </div>
            </div>
          </div>
        </div>

        <button
          class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
          type="button"
          data-te-target="#carouselExampleCrossfade"
          data-te-slide="prev"
        >
          <span class="inline-block h-8 w-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Previous
          </span>
        </button>

        <button
          class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
          type="button"
          data-te-target="#carouselExampleCrossfade"
          data-te-slide="next"
        >
          <span class="inline-block h-8 w-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Next
          </span>
        </button>
      </div>
    </>
  );
}

export default HeadSection;
