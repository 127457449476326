import React from "react";
import Icon from "@mdi/react";
import {
  mdiMapMarkerOutline,
  mdiShareVariant,
  mdiHeartOutline,
  mdiPrinterOutline,
} from "@mdi/js";

function HeadingSection() {
  return (
    <>
      <div className="flex justify-between">
        <div className="flex ">
          <div className="py-2">
            <button className="py-2 px-5 text-center text-white text-xs font-medium bg-blue-700 rounded">
              Sales
            </button>
          </div>
          <div className="p-2">
            <button className="py-2 px-5 text-center text-white text-xs font-medium bg-blue-700 rounded">
              Houses
            </button>
          </div>
        </div>
        <div className="pt-5">
          <p className="text-blue-700 md:text-lg">₹ 1,098 /sq ft</p>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="pt-2">
          <p className="md:text-3xl font-semibold">Green Crest Taloja</p>
        </div>
        <div>
          <p className="text-blue-700 md:text-3xl font-medium">₹ 86,00,000</p>
        </div>
      </div>
      <div className="flex pt-2 space-x-1">
        <span className="text-sm">By</span>
        <p className="uppercase text-sm font-semibold truncate text-blue-700">sai shardha developers & rajdhyan ventures</p>
      </div>

      <div className="md:flex md:justify-between">
        <div className="flex pt-4 text-sm text-slate-600">
          <Icon path={mdiMapMarkerOutline} className="h-5 w-5" />
          <p className="truncate">
            150 Homes 6 Wings of G+6 Storreys Building in Phase 1 Thoughtfully
            Designed 1RK, 1BHK - C and 1 BHK - L
          </p>
        </div>
        <div className="flex">
          <div className="p-2">
            <button className="flex space-x-1 py-2 px-5 text-center text-xs font-medium bg-white rounded">
              <Icon path={mdiShareVariant} className="h-4 w-4" />
              <p>Share</p>
            </button>
          </div>
          <div className="p-2">
            <button className="flex space-x-1 py-2 px-5 text-center text-xs font-medium bg-white rounded">
              <Icon path={mdiHeartOutline} className="h-4 w-4" />
              <p>Favourite</p>
            </button>
          </div>
          <div className="p-2">
            <button className="flex space-x-1 py-2 px-5 text-center text-xs font-medium bg-white rounded">
              <Icon path={mdiPrinterOutline} className="h-4 w-4" />
              <p>Print</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeadingSection;
