import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

function PostYourPropertyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sessiondata = {
    UserID: sessionStorage.getItem("UserID"),
    EmailID: sessionStorage.getItem("UserName"),
    FullName: sessionStorage.getItem("FullName"),
    Type: sessionStorage.getItem("Type"),
  };

  return (
    <>
      <div className="container">
        <div className="bg-abhita-400">
          <ul
            className="nav nav-tabs nav-justified  flex flex-col md:flex-row  flex-wrap  list-none  border-b-0  pl-0  mb-4 text-xl hei"
            id="tabs-tabJustify"
            role="tablist"
          >
            {sessiondata ? (
              sessiondata.UserID === null ? (<li className="nav-item flex-grow text-center " role="presentation">
                <a
                  href="#tabs-personalDetailsJustify"
                  className="w-full block  text-xs tracking-wider font-semibold uppercase   py-3
        
                hover:bg-abhita-300 text-white
      focus:border-transparent
      active"
                  id="tabs-personal-tabJustify"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-personalDetailsJustify"
                  role="tab"
                  aria-controls="tabs-personalDetailsJustify"
                  aria-selected="true"
                >
                  Personal Details
                </a>
              </li>) : (
                ""
              )
            ) : (
              ""
            )}
            <li className="nav-item flex-grow text-center" role="presentation">
              <a
                href="#tabs-PropertyDetailsJustify"
                className="w-full block font-semibold text-xs tracking-wider uppercase border-x-0 border-t-0  border-transparent  py-3
      hover:border-transparent focus:border-transparent   hover:bg-abhita-300 text-white"
                id="tabs-profile-tabJustify"
                data-bs-toggle="pill"
                data-bs-target="#tabs-PropertyDetailsJustify"
                role="tab"
                aria-controls="tabs-PropertyDetailsJustify"
                aria-selected="false"
              >
                Property Details
              </a>
            </li>
            <li className="nav-item flex-grow text-center" role="presentation">
              <a
                href="#tabs-LocalityDetailsJustify"
                className="w-full  block

      font-semibold
      text-xs tracking-wider
      uppercase
      border-x-0 border-t-0  border-transparent
      py-3
      hover:bg-abhita-300 text-white      hover:border-transparent
      focus:border-transparent
      
    "
                id="tabs-messages-tabJustify"
                data-bs-toggle="pill"
                data-bs-target="#tabs-LocalityDetailsJustify"
                role="tab"
                aria-controls="tabs-LocalityDetailsJustify"
                aria-selected="false"
              >
                Locality Details
              </a>
            </li>

            <li className="nav-item flex-grow text-center" role="presentation">
              <a
                href="#tabs-PropertyFeatureJustify"
                className="w-full block  font-semibold text-xs tracking-wider uppercase border-x-0 border-t-0  border-transparent py-3 hover:border-transparent focus:border-transparent
                hover:bg-abhita-300 text-white"
                id="tabs-messages-tabJustify"
                data-bs-toggle="pill"
                data-bs-target="#tabs-PropertyFeatureJustify"
                role="tab"
                aria-controls="tabs-PropertyFeatureJustify"
                aria-selected="false"
              >
                Property Features
              </a>
            </li>
            <li className="nav-item flex-grow text-center" role="presentation">
              <a
                href="#tabs-UpoaldGalleryJustify"
                className=" w-full
      block
      font-semibold
      text-xs tracking-wider
      uppercase
      border-x-0 border-t-0  border-transparent
      py-3
      hover:bg-abhita-300 text-white 
      hover:border-transparent
      focus:border-transparent
    "
                id="tabs-messages-tabJustify"
                data-bs-toggle="pill"
                data-bs-target="#tabs-UpoaldGalleryJustify"
                role="tab"
                aria-controls="tabs-UpoaldGalleryJustify"
                aria-selected="false"
              >
                Upload Gallary
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="tabs-tabContentJustify">
          <div
            className="tab-pane fade show active"
            id="tabs-personalDetailsJustify"
            role="tabpanel"
            aria-labelledby="tabs-personal-tabJustify"
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default PostYourPropertyPage;
