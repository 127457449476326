import React from "react";

function Address() {
  return (
    <>
      <div className="bg-white p-10">
        <p className="font-semibold hover:text-blue-700">Address</p>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-5 pt-5 w-full">
          <div className="flex ">
            <p className="text-xs font-semibold">Address</p>
            <p className="text-xs font-medium text-slate-500">: Kalpataru Society</p>
          </div>
          <div className="flex ">
            <p className="text-xs font-semibold">City</p>
            <p className="text-xs font-medium text-slate-500">: Pune</p>
          </div>
          <div className="flex ">
            <p className="text-xs font-semibold">Area</p>
            <p className="text-xs font-medium text-slate-500">: Tulsi Bag</p>
          </div>
          <div className="flex ">
            <p className="text-xs font-semibold">State</p>
            <p className="text-xs font-medium text-slate-500">: Maharashtra</p>
          </div>
          <div className="flex ">
            <p className="text-xs font-semibold">Zip</p>
            <p className="text-xs font-medium text-slate-500">: 11690</p>
          </div>
          <div className="flex ">
            <p className="text-xs font-semibold">Country</p>
            <p className="text-xs font-medium text-slate-500">: India</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Address;
