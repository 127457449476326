import React from "react";

function Overview() {
  const over = [
    {
      title: "Sell / Rent your Property",
      desc: "A Speedy way to find genuine Buyers",
      icon: "/images/sale-rent.png",
      href: "/post-your-property",
    },
    {
      title: "Buy / Rent a Property",
      desc: "Guided by trusted Property Experts",
      icon: "/images/buy-rent.png",
      href: "/",
    },
    {
      title: "Consultancy",
      desc: "Get Legal Opinion of our experts for your Property related concerns",
      icon: "/images/consultation.png",
      href: "https://abhitalandsolutions.com/consultation/",
    },
    {
      title: "Search / Title Report",
      desc: "Get a verified Scrutiny Report for a clear title over your property",
      icon: "/images/report.png",
      href: "https://abhitalandsolutions.com/search-report/",
    },
    {
      title: "Home Loan",
      desc: "Apply for Quick Home Loans",
      icon: "/images/homeLoan.png",
      href: "https://abhitalandsolutions.com/property-loan/",
    },
    {
      title: "Valuation",
      desc: "Know the current valuation by our Government approved valuers ",
      icon: "/images/valuation.png",
      href: "https://abhitalandsolutions.com/property-and-machinery-valuation/",
    },
  ];

  return (
    <>
      <div className="pt-10 md:pt-10 text-center bg-gray-50">
        <p className="md:text-4xl text-2xl font-bold pb-5">
          Our Services for You
        </p>
        <p className="md:text-md text-sm font-normal md:pb-5 pb-10 px-5 md:px-60 ">
          Highlight the best of your properties by using the List Category
          shortcode. You can list categories, types, cities, areas and states of
          your choice.
        </p>

        <div className="container mx-auto p-5 lg:py-10 lg:px-20 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 gap-y-20">
          {over?.map((o, idx) => (
            <div
              className="relative p-5 border border-slate-300 bg-white shadow-md hover:shadow-lg hover:shadow-slate-300 transition ease-in-out hover:translate-1 hover:scale-105"
              key={idx}
            >
              <OverviewBlog {...o} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function OverviewBlog({ title, desc, icon, href }) {
  return (
    <>
      <div className="grid place-items-center">
        <div className="absolute -top-12 left-5 bg-gray-100 shadow-xl shadow-gray-200 flex border border-gray-300 rounded-full p-2">
          <img src={icon} className=" w-20 h-20 p-3" alt="" />
        </div>

        <div className="pt-10">
          <p className="font-semibold text-lg">{title}</p>
          <p className="text-center text-sm font-normal text-gray-700 pb-16">
            {desc}
          </p>
          {/* <div className="absolute bottom-7  right-0 left-0">
            <a
              target="_blank"
              href={href}
              className="text-white text-sm bg-abhita-300 rounded px-4 py-3 font-semibold mx-auto"
            >
              Read More
            </a>
          </div> */}
          <div className=" absolute bottom-7  right-0 left-0">
            <a
              target="_blank"
              href={href}
              className=" read-more text-white text-sm bg-abhita-300 rounded px-4 py-3 font-semibold mx-auto"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
