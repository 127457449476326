import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import { baseURL } from "../http-common";
export const axiosInstance = axios.create({
  baseURL: baseURL,
});

//https://api.abhitalandsolutions.com
// http://localhost:5001
//const API_URL="https://api.legavac.com/api/legavac";
//const API_URL="http://localhost:5000/api/legavac";
//const API_URL="http://localhost:5001/api/warritour";
// const API_URL="http://localhost:5001/api/nahibroker";
export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

//UserMaster
export async function getLoginDetail(state) {
  return await axiosInstance
    .post(`/getlogindetail`, state)
    .then((res) => res.data);
}
export async function forgotPassword(state) {
  return await axiosInstance
    .post(`/forgotPassword`, state)
    .then((res) => res.data);
}
export async function getAdminLoginDetail(state) {
  return await axios
    .post(`/getAdminLoginDetail`, state)
    .then((res) => res.data);
}
export async function dashboardcount() {
  return await axiosInstance
    .get(`/dashboardcount`)
    .then((res) => res.data);
}

export async function iuusermaster(state) {
  return await axiosInstance
    .post(`/iuusermaster`, state)
    .then((res) => res.data);
}

//For Continent
export async function getContinentForDDL() {
  return await axiosInstance
    .get(`/getContinentForDDL`)
    .then((res) => res.data);
}

export async function getFeatureProject_web() {
  return await axiosInstance
    .get(`/getFeatureProject_web`)
    .then((res) => res.data);
}

// //ForCountry
// export async function iucountry(state) {
//   return await axiosInstance
//     .post(`/iucountry`, state)
//     .then((res) => res.data);
// }
// export async function getCountryForDDL() {
//   return await axiosInstance
//     .get(`/getCountryForDDL`)
//     .then((res) => res.data);
// }
// export async function getCountryDetail() {
//   return await 
//     axiosInstance
//       .get(`/getCountryDetail`)
//       .then((res) => res.data);
// }
// export async function getCountryDetailByID(id) {
//   return await axiosInstance
//     .get(`/getCountryDetailByID/${id}`)
//     .then((res) => res.data);
// }
// export async function getCountryDelete(id) {
//   return await axiosInstance
//     .get(`/getCountryDelete/${id}`)
//     .then((res) => res.data);
// }
//ForCountry
export async function getCountryForDDL(id) {
  return await axiosInstance
    .get(`/getCountryForDDL/${id}`)
    .then((res) => res.data);
}


//ForState
// export async function iustate(state) {
//   return await axiosInstance
//     .post(`/iustate`, state)
//     .then((res) => res.data);
// }
// export async function getStateForDDL(countryid) {
//   return await axiosInstance
//     .get(`/getStateForDDL/${countryid}`)
//     .then((res) => res.data);
// }
// export async function getStateDetail() {
//   return await 
//   //resolve(
//     axiosInstance
//       .get(`/getStateDetail`)
//       .then((res) => res.data);
// }
// export async function getStateDetailByID(id) {
//   return await axiosInstance
//     .get(`/getStateDetailByID/${id}`)
//     .then((res) => res.data);
// }
// export async function getStateDelete(id) {
//   return await axiosInstance
//     .get(`/getStateDelete/${id}`)
//     .then((res) => res.data);
// }
export async function getStateForDDL(id) {
  return await axiosInstance
    .get(`/getStateForDDL/${id}`)
    .then((res) => res.data);
}
export async function getStateForDDLByCountryID(CountryID) {
  return await axiosInstance
    .get(
      `/getStateForDDLByCountryID/${CountryID}`
    )
    .then((res) => res.data);
}


//ForCity
// export async function iucity(state) {
//   return await axiosInstance
//     .post(`/iucity`, state)
//     .then((res) => res.data);
// }
// export async function getCityForDDL(stateid) {
//   return await axiosInstance
//     .get(`/getCityForDDL/${stateid}`)
//     .then((res) => res.data);
// }
// export async function getCityDetail() {
//   return await 
//   //resolve(
//     axiosInstance
//       .get(`/getCityDetail`)
//       .then((res) => res.data);
// }
// export async function getCityDetailByID(id) {
//   return await axiosInstance
//     .get(`/getCityDetailByID/${id}`)
//     .then((res) => res.data);
// }
// export async function getCityDelete(id) {
//   return await axiosInstance
//     .get(`/getCityDelete/${id}`)
//     .then((res) => res.data);
// }
export async function getCityForDDL(id) {
  return await axiosInstance
    .get(`/getCityForDDL/${id}`)
    .then((res) => res.data);
}
export async function getCityForDDLByStateID(StateID) {
  return await axiosInstance
    .get(`/getCityForDDLByStateID/${StateID}`
    )
    .then((res) => res.data);
}
export async function getCitylistForDDL(id) {
  return await axiosInstance
    .get(`/getCitylistForDDL/${id}`)
    .then((res) => res.data);
}

//district
export async function getDistrictForDDL(id) {
  return await axiosInstance
    .get(`/getDistrictForDDL/${id}`)
    .then((res) => res.data);
}



//propertydetail
export async function iupropertydetail(state) {
  return await axiosInstance
    .post(`/iupropertydetail`, state)
    .then((res) => res.data);
}
export async function getPropertydetailForDDL() {
  return await axiosInstance
    .get(`/getPropertydetailForDDL`)
    .then((res) => res.data);
}

export async function getPropertydetailDetail() {
  return await axiosInstance
    .get(`/getPropertydetailDetail`)
    .then((res) => res.data);
}

export async function getPropertydetailDetailByID(PropertydetailID) {
  return await axiosInstance
    .get(`/getPropertydetailDetailByID/${PropertydetailID}`)
    .then((res) => res.data);
}
export async function getPropertydetailDelete(PropertydetailID) {
  return await axiosInstance
    .get(`/getPropertydetailDelete/${PropertydetailID}`)
    .then((res) => res.data);
}
export async function getPropertydetail_web(state) {
  return await axiosInstance
    .post(`/getPropertydetail_web`, state)
    .then((res) => res.data);
}



//localitydetail
export async function iulocalitydetail(state) {
  return await axiosInstance
    .post(`/iulocalitydetail`, state)
    .then((res) => res.data);
}
export async function getLocalitydetailForDDL() {
  return await axiosInstance
    .get(`/getLocalitydetailForDDL`)
    .then((res) => res.data);
}

export async function getLocalitydetailDetail() {
  return await axiosInstance
    .get(`/getLocalitydetailDetail`)
    .then((res) => res.data);
}

export async function getLocalitydetailDetailByID(id) {
  return await axiosInstance
    .get(`/getLocalitydetailDetailByID/${id}`)
    .then((res) => res.data);
}
export async function getLocalitydetailDelete(id) {
  return await axiosInstance
    .get(`/getLocalitydetailDelete/${id}`)
    .then((res) => res.data);
}




//Pgdetail
export async function iupgdetail(state) {
  return await axiosInstance
    .post(`/iupgdetail`, state)
    .then((res) => res.data);
}
export async function getPgdetailForDDL() {
  return await axiosInstance
    .get(`/getPgdetailForDDL`)
    .then((res) => res.data);
}

export async function getPgdetailDetail() {
  return await axiosInstance
    .get(`/getPgdetailDetail`)
    .then((res) => res.data);
}

export async function getPgdetailDetailByID(PropertydetailID) {
  return await axiosInstance
    .get(`/getPgdetailDetailByID/${PropertydetailID}`)
    .then((res) => res.data);
}
export async function getPgdetailDelete(PropertydetailID) {
  return await axiosInstance
    .get(`/getPgdetailDelete/${PropertydetailID}`)
    .then((res) => res.data);
}



//Rentaldetail
export async function iurentaldetail(state) {
  return await axiosInstance
    .post(`/iurentaldetail`, state)
    .then((res) => res.data);
}
export async function getRentaldetailForDDL() {
  return await axiosInstance
    .get(`/getRentaldetailForDDL`)
    .then((res) => res.data);
}

export async function getRentaldetailDetail() {
  return await axiosInstance
    .get(`/getRentaldetailDetail`)
    .then((res) => res.data);
}

export async function getRentaldetailDetailByID(PropertydetailID) {
  return await axiosInstance
    .get(`/getRentaldetailDetailByID/${PropertydetailID}`)
    .then((res) => res.data);
}
export async function getRentaldetailDelete(PropertydetailID) {
  return await axiosInstance
    .get(`/getRentaldetailDelete/${PropertydetailID}`)
    .then((res) => res.data);
}




//amenities
export async function iuamenities(state) {
  return await axiosInstance
    .post(`/iuamenities`, state)
    .then((res) => res.data);
}
export async function getAmenitiesForDDL() {
  return await axiosInstance
    .get(`/getAmenitiesForDDL`)
    .then((res) => res.data);
}

export async function getAmenitiesDetail() {
  return await axiosInstance
    .get(`/getAmenitiesDetail`)
    .then((res) => res.data);
}

export async function getAmenitiesDetailByID(PropertydetailID) {
  return await axiosInstance
    .get(`/getAmenitiesDetailByID/${PropertydetailID}`)
    .then((res) => res.data);
}
export async function getAmenitiesDelete(PropertydetailID) {
  return await axiosInstance
    .get(`/getAmenitiesDelete/${PropertydetailID}`)
    .then((res) => res.data);
}
export async function iuamenitymaster(state) {
  return await axiosInstance
    .post(`/iuamenitymaster`, state)
    .then((res) => res.data);
}
export async function getAmenityDetail() {
  return await axiosInstance
    .get(`/getAmenityDetail`)
    .then((res) => res.data);
}



//gallary
export async function iugallary(state) {
  return await axiosInstance
    .post(`/iugallary`, state)
    .then((res) => res.data);
}
export async function getGallaryForDDL() {
  return await axiosInstance
    .get(`/getGallaryForDDL`)
    .then((res) => res.data);
}

export async function getGallaryDetail(propertyid) {
  return await axiosInstance
    .get(`/getGallaryDetail/${propertyid}`)
    .then((res) => res.data);
}

export async function getGallaryDetailByID(id) {
  return await axiosInstance
    .get(`/getGallaryDetailByID/${id}`)
    .then((res) => res.data);
}
// export async function getGallaryDelete(imageid) {
//   return await axiosInstance
//     .get(`/getGallaryDelete/${imageid}`)
//     .then((res) => res.data);
// }

// export async function getPackageGallery(propertyid) {
//   return await axiosInstance
//     .get(`/getpackagegallery/${propertyid}`)
//     .then((res) => res.data);
// }

export async function getPropertyImageCountByPropertyID(propertyid) {
  return await axiosInstance
    .get(`/getpropertyimagecountbypropertyid/${propertyid}`)
    .then((res) => res.data);
}
export async function deleteImage(imageid) {
  return await axiosInstance
    .get(`/deleteimage/${imageid}`)
    .then((res) => res.data);
}

export async function uploadPackageGallery(state) {
  return await axiosInstance
    .post(`/uploadpackagegallery`, state)
    .then((res) => res.data);
}



//apply
export async function iuapply(state) {
  return await axiosInstance
    .post(`/iuapply`, state)
    .then((res) => res.data);
}


//our listing
export async function getPropertylist_Web() {
  return await axiosInstance
    .get(`/getPropertylist_Web`)
    .then((res) => res.data);
}