import React from "react";

function Breadcrums({ data }) {
  return (
    <>
      <div className="md:py-5 md:px-10 px-5">
        <p className="text-sm text-slate-500">
          Home /
          <span className="text-black uppercase"> {data.propertyname}</span>
        </p>
      </div>
    </>
  );
}

export default Breadcrums;
