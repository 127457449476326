import React from "react";

function AdvSearch() {
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700">Advanced Search</p>

        <div className="pt-5 space-x-3 flex md:justify-start justify-center">
          <div className="">
            <button className="py-3 px-4 text-center text-white text-xs font-medium bg-blue-700 rounded">
              Rentals
            </button>
          </div>
          <div className="">
            <button className="py-3 px-4 text-center text-white text-xs font-medium bg-amber-500 rounded">
              Sales
            </button>
          </div>
          <div className="">
            <button className="py-3 px-4 text-center text-white text-xs font-medium bg-amber-500 rounded">
              Invest
            </button>
          </div>
        </div>

        <div className="pt-3">
          <div className="pt-2">
            <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
              <option>Categories</option>
              <option>Apartments</option>
              <option>Candos</option>
              <option>Houses</option>
              <option>Industrial</option>
            </select>
          </div>
          <div className="pt-2">
            <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
              <option selected>Cities</option>
              <option>Pune</option>
              <option>Mumbai</option>
              <option>Delhi</option>
            </select>
          </div>
          <div className="pt-2">
            <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
              <option>Areas</option>
              <option>Katepuram Chowk</option>
              <option>Sai Chowk</option>
            </select>
          </div>
          <div className="pt-2">
            <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
              <option>Bedrooms</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div>
          <div className="pt-2">
            <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
              <option>Min Size (in sqft)</option>
            </select>
          </div>
          <div className="pt-5 grid">
            <button className="h-9 p-2 text-white font-medium bg-blue-700 rounded">
              Send Properties
            </button>
          </div>
        </div>

        <p className="text-xs text-blue-700 font-semibold pt-3">
            More Search Options
        </p>
      </div>
    </>
  );
}

export default AdvSearch;
