import React from "react";
import { Animated } from "react-animated-css";

function Cities() {
  const city = [
    {
      img: "/images/Mumbai.jpg",
      name: "Mumbai",
    },
    {
      img: "/images/Pune.jpg",
      name: "Pune",
    },
    {
      img: "/images/Nashik.jpg",
      name: "Nashik",
    },
    {
      img: "/images/Nagpur.jpg",
      name: "Nagpur",
    },
    {
      img: "/images/Aurangabad.jpg",
      name: "Aurangabad",
    },
    {
      img: "/images/Kolhapur.jpg",
      name: "Kolhapur",
    },
  ];
  return (
    <>
      <div className="grid pt-60 md:pb-10 md:pt-10 place-items-center">
        <p className="md:text-4xl text-2xl font-bold pb-10">Cities</p>
        <Animated animationIn="slideInDown" animationOut="" isVisible={true}>
          <div className="md:px-20 px-5 grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-5">
            {city?.map((c, idx) => (
              <div className="" key={idx}>
                <CitiesBlog {...c} />
              </div>
            ))}
          </div>
        </Animated>
      </div>
    </>
  );
}

function CitiesBlog({ img, name }) {
  return (
    <>
      <div className="transition ease-in-out delay-200 hover:-translate-y-1 hover:scale-110 duration-700">
        <img
          src={img}
          alt=""
          className="w-48 h-32  rounded "
        />
        <p className="text-lg pt-3 font-medium text-center">{name}</p>
      </div>
    </>
  );
}

export default Cities;
