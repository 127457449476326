import React from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../http-common";

function ImperiaGallary({ data }) {
  return (
    <>
      <div className=" w-full">
        {/* <div className="col-span-2">
          <video className="md:w-full shadow-lg" autoPlay loop muted>
            <source src="/images/imperia.mp4" type="video/mp4" />
          </video>
        </div> */}
        {/* <div className="block">
          <div className="relative p-2 pt-5">
            {data.map((item,idx)=>(<div className="bg-black ">
              <img
                src={`${baseURL+'/'+item.PropertyImage}`}
                alt=""
                key={idx}
                className="w-full shadow-lg  opacity-60"
              />
            </div>))}
            <Link to="/more-images&videos">
              <div className="absolute top-32 mx-auto left-0 right-0">
                <p className="text-white text-2xl font-semibold text-center">
                  +
                </p>
                <p className="text-white text-2xl font-semibold text-center">
                  More
                </p>
              </div>
            </Link>
          </div>
        </div> */}

        {/* {data?data.map((item,idx)=>(idx===0?<div className={"carousel-item active relative float-left w-full"}>
              <img
                src={`${baseURL+'/'+item.PropertyImage}`}
                className="block w-full lg:h-[500px] md:h-[400px]"
                alt=""
              />
            </div>:
            <div className={"carousel-item  relative float-left w-full"}>
            <img
              src={`${baseURL+'/'+item.PropertyImage}`}
              className="block w-full lg:h-[500px] md:h-[400px] "
              alt=""
            />
          </div>)):""} */}
        <div
          id="carouselExampleCaptions"
          className="relative container mx-auto"
          data-te-carousel-init
          data-te-carousel-slide
        >
          <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            {data
              ? data.map((item, idx) =>
                  idx === 0 ? (
                    <div
                      class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                      data-te-carousel-active
                      data-te-carousel-item
                    >
                      <img
                        src={`${baseURL + "/" + item.PropertyImage}`}
                        className="block w-full lg:h-[500px] md:h-[400px]"
                        alt=""
                      />
                    </div>
                  ) : (
                    <div
                      class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                      data-te-carousel-item
                    >
                      <img
                        src={`${baseURL + "/" + item.PropertyImage}`}
                        className="block w-full lg:h-[500px] md:h-[400px] "
                        alt=""
                      />
                    </div>
                  )
                )
              : ""}
          </div>
          <button
            className="absolute bottom-0 left-0 top-0 z-[1] flex w-[5%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="prev"
          >
            <span className="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </button>

          <button
            className="absolute bottom-0 right-0 top-0 z-[1] flex w-[5%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="next"
          >
            <span className="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Next
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default ImperiaGallary;
