import React, { useEffect } from "react";

function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <img
        src="/images/privacy-policy.jpg"
        alt=""
        className="h-96 w-full pt-16 md:px-10 px-5"
      />
      <div className="pb-16 pt-10">
        <div className="md:px-32 px-5 space-y-1">
          <p className="md:text-3xl text-2xl text-abhita-100 font-bold py-5">
            Privacy Policy for Abhita Properties
          </p>
          <div className="space-y-2">
            <p className="text-justify text-md leading-relaxed font-normal">
              This Privacy policy is subject to the terms of the Site Policy
              (User agreement) of Abhita Properties. This policy is effective from
              the date and time a user registers with Abhita Properties by filling up
              the Registration form and accepting the terms and conditions laid
              out in the Site Policy.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
              In order to provide a personalized browsing experience, Dream
              Estate may collect personal information from you. Additionally,
              our websites may require you to complete a registration form or
              seek some information from you. When you let us have your
              preferences, we will be able to deliver or allow you to access the
              most relevant information that meets your end.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
              To extend this personalized experience Abhita Properties may track the
              IP address of a user's computer and save certain information on
              your system in the form of cookies. A user has the option of
              accepting or declining the cookies of this website by changing the
              settings of your browser.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
              The personal information provided by the users to Abhita Properties
              will not be provided to third parties without previous consent of
              the user concerned. Information of a general nature may however be
              revealed to external parties.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
              Every effort will be made to keep the information provided by
              users in a safe manner, the information will be displayed on the
              website will be done so only after obtaining consent from the
              users. Any user browsing the site generally is not required to
              disclose his identity or provide any information about him/her, it
              is only at the time of registration you will be required to
              furnish the details in the registration form.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
              A full user always has the option of not providing the information
              which is not mandatory. You are solely responsible for maintaining
              confidentiality of the User password and user identification and
              all activities and transmission performed by the User through his
              user identification and shall be solely responsible for carrying
              out any online or off-line transaction involving credit cards /
              debit cards or such other forms of instruments or documents for
              making such transactions and Abhita Properties assumes no
              responsibility or liability for their improper use of information
              relating to such usage of credit cards / debit cards used by the
              subscriber online/off-line.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
            Abhita Properties provides you the ability to keep your personal
              information accurate and up-to-date. If at any time you would like
              to
              <ol className="list-decimal list-inside">
                <li>
                  rectify, update, correct or delete your personal information;{" "}
                </li>
                <li>
                  obtain confirmation on whether or not your personal
                  information is processed by it;{" "}
                </li>
                <li>
                  access your personal information or exercise your right to
                  data portability; or
                </li>
                <li>
                  exercise your right to restrict the continuing disclosure of
                  your personal information to any third party by Abhita Properties
                  in certain circumstances, you are requested to contact us at{" "}
                  <span className="text-blue-700 underline">
                  info@abhitaproperties.com
                  </span>
                  .
                </li>
              </ol>
              We will require you to provide a valid proof of your identity, in
              order to ensure that your rights are respected.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
              You agree that Abhita Properties may use personal information about you
              to improve its marketing and promotional efforts, to analyze site
              usage, improve the Site's content and product offerings, and
              customise the Site's content, layout, and services. These uses
              improve the Site and better tailor it to meet your needs, so as to
              provide you with a smooth, efficient, safe and customised
              experience while using the Site.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
              You agree that Abhita Properties may use your personal information to
              contact you and deliver information to you that, in some cases,
              are targeted to your interests, such as targeted banner
              advertisements, administrative notices, product offerings, and
              communications relevant to your use of the Site. By accepting the
              User Agreement and Privacy Policy, you expressly agree to receive
              this information. If you do not wish to receive these
              communications, we encourage you to opt out of the receipt of
              certain communications in your profile. You may make changes to
              your profile at any time, if you wish to delete your account on
              Abhita Properties.in you may do so by writing an email to{" "}
              <span className="text-blue-700 underline">
              info@abhitaproperties.com
              </span>{" "}
              or by using the settings available in your account. It is the
              belief of Abhita Properties that privacy of a person can be best
              guaranteed by working in conjunction with the Law enforcement
              authorities.
            </p>
            <p className="text-justify text-md leading-relaxed font-normal">
            Abhita Properties websites comply with all Indian Laws applicable.
            Abhita Properties has always cooperated with all law enforcement
              inquires. Abhita Properties may disclose all or part of your personal
              details in response to a request from the law enforcement
              authorities or in a case of bonafide requirement to prevent an
              imminent breach of the law.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Policy;
