import React from "react";

function OurListings() {
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700">Our Listings</p>
        <div className="pt-5 grid grid-cols-1 divide-y">
          <div className="flex justify-between py-2">
            <p>Apartments</p>
            <p>(17)</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Condos</p>
            <p>(8)</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Houses</p>
            <p>(5)</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Industrial</p>
            <p>(1)</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Land</p>
            <p>(1)</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Offices</p>
            <p>(2)</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurListings;
