import React from "react";
import Icon from "@mdi/react";
import {
  mdiBedKingOutline,
  mdiShower,
  mdiCar,
  mdiArrowExpandAll,
  mdiCalendarBlankOutline,
} from "@mdi/js";

function Overview({data}) {console.log(data);
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700">Overview</p>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-y-5 pt-5">
          <div className="text-sm">
            <p>Updated On:</p>
            <p className="pt-2">{data.updateon}</p>
          </div>

          <div className="text-xs grid grid-cols-2 col-span-5 md:grid-cols-5 gap-5 md:place-items-center place-items-start">
            <div className="grid place-items-center">
              <Icon path={mdiBedKingOutline} className="w-7 h-7" />
              <p>{data.bedroom} Bedrooms</p>
            </div>
            <div className="grid place-items-center">
              <Icon path={mdiShower} className="w-7 h-7" />
              <p>{data.bathroom} Bathrooms</p>
            </div>
            <div className="grid place-items-center">
              <Icon path={mdiArrowExpandAll} className="w-7 h-7" />
              <p>
                {data.parking}
              </p>
            </div>
            <div className="grid place-items-center">
              <Icon path={mdiCalendarBlankOutline} className="w-7 h-7" />
              <p>Year built:{data.yearbuilt}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
