import {
  mdiArrowExpandAll,
  mdiBedKingOutline,
  mdiCalendarBlankOutline,
  mdiCar,
  mdiShower,
  mdiBasketball,
  mdiDumbbell,
  mdiGrass,
  mdiLibraryOutline,
  mdiMeditation,
  mdiPartyPopper,
  mdiPool,
  mdiRoomService,
  mdiTheater,
} from "@mdi/js";
import React, { useEffect } from "react";
import { Carousel, initTE } from "tw-elements";
import Icon from "@mdi/react";

function BannerDetails_Static1() {
  useEffect(() => {
    initTE({ Carousel });
  }, []);
  return (
    <>
      <div className="bg-slate-100">
        {/* <ImperiaGallary/> */}
        <div
          id="carouselExampleCaptions"
          class="relative"
          data-te-carousel-init
          data-te-carousel-slide
        >
          <div
            class="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
            data-te-carousel-indicators
          >
            <button
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide-to="0"
              data-te-carousel-active
              class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide-to="1"
              class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide-to="2"
              class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 3"
            ></button>
          </div>

          <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            <div
              class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-active
              data-te-carousel-item
            >
              <img
                src="/images/image_2.jpeg"
                class="block w-full"
                alt="..."
              />
            </div>
            <div
              class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
            >
              <img
                src="/images/image_2.jpeg"
                class="block w-full"
                alt="..."
              />
            </div>
            <div
              class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
            >
              <img
                src="/images/image_2.jpeg"
                class="block w-full"
                alt="..."
              />
            </div>
          </div>

          <button
            class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="prev"
          >
            <span class="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </span>
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </button>

          <button
            class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="next"
          >
            <span class="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Next
            </span>
          </button>
        </div>
        {/* <Breadcrums /> */}
        <div className="md:py-5 md:px-10 px-5">
          <p className="text-sm text-slate-500">
            Home /<span className="text-black uppercase"> Mahabaleshwar</span>
          </p>
        </div>
        <div className="md:p-10 p-5">
          {/* <ImpHeadingSection /> */}
          <div>
            <div className="flex justify-between">
              <div className="flex ">
                <div className="py-2">
                  <button className="py-2 px-5 text-center text-white text-xs font-medium bg-blue-700 rounded">
                    sales
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="pt-2">
                <p className="md:text-3xl font-semibold">
                  Mahabaleshwar Panchgani Property
                </p>
              </div>
            </div>
            <div className="flex justify-between pt-2">
              <div className="flex space-x-1">
                <span className="text-sm">By</span>
                <p className="uppercase text-sm font-semibold truncate text-blue-700">
                  Mahabaleshwar Panchgani Property
                </p>
              </div>
              <div>
                <p className="text-xs text-gray-400">Onwards*</p>
              </div>
            </div>
          </div>

          <div className="pt-10">
            <div className="grid lg:grid-cols-3 md:gri-cols-3 grid-cols-1 lg:gap-5 md:gap-0 gap-0 lg:space-y-0 md:space-y-5 space-y-5 items-start">
              <div className="col-span-2 space-y-6">
                {/* <Overview /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700">Overview</p>
                  <div className="grid grid-cols-1 md:grid-cols-6 gap-y-5 pt-5">
                    <div className="text-sm">
                      <p>Updated On:</p>
                      <p className="pt-2">12 June 2022</p>
                    </div>

                    <div className="text-xs grid grid-cols-2 col-span-5 md:grid-cols-5 gap-5 md:place-items-center place-items-start">
                      <div className="grid place-items-center">
                        <Icon path={mdiBedKingOutline} className="w-7 h-7" />
                        <p>4 Bedrooms</p>
                      </div>
                      <div className="grid place-items-center">
                        <Icon path={mdiShower} className="w-7 h-7" />
                        <p>5 Bathrooms</p>
                      </div>
                      <div className="grid place-items-center">
                        <Icon path={mdiCar} className="w-7 h-7" />
                        <p>2 BHK</p>
                      </div>
                      <div className="grid place-items-center">
                        <Icon path={mdiArrowExpandAll} className="w-7 h-7" />
                        <p>5 sq. ft.</p>
                      </div>
                      <div className="grid place-items-center">
                        <Icon
                          path={mdiCalendarBlankOutline}
                          className="w-7 h-7"
                        />
                        <p>Year built: 2023</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Description /> */}
                <div className="bg-white p-10">
                  <p className="font-semibold hover:text-blue-700">
                    Description
                  </p>
                  <p className="text-sm text-slate-500 pt-5">
                    Mahabaleshwar is a leading municipal council cum town in the
                    District of Satara in Maharashtra. It is a pilgrimage spot
                    for followers of the Hindu religion and the River Krishna
                    originates from this town. The town was developed into a
                    famous hill station by the British and is still popular
                    amongst tourists every year. The total area of Mahabaleshwar
                    is 137.15 square kilometres at an elevation of 1,353 meters.
                    The population stood at 12,737 people as of the year 2011.
                    The coordinates of Mahabaleshwar are 17.9250°N 73.6575°E.
                    Infrastructure and Details There are many reputed
                    educational institutions here including the Shalom
                    International School and Junior College, Mahabaleshwar
                    School Number 5, Smt. Meenalben Mehta College Panchgani,
                    Giristhan High School, MES English Medium School, and the
                    Giristhan Arts and Commerce College. Leading hospitals in
                    Mahabaleshwar include the Rural Hospital, Ramakant Medical
                    Center and many others. The nearest railway station is
                    Satara which is 60 km away from the area. There are state
                    buses readily available in the area for commuters as well.
                    The Pune International Airport is located approximately 120
                    km away from Mahabaleshwar. Property in Mahabaleshwar You
                    will find several property options in Mahabaleshwar
                    including bungalows, villas, independent houses, and
                    residential plots.
                  </p>
                </div>
                {/* <ImpAddress /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700 text-lg">
                    Address
                  </p>

                  <div className="grid grid-cols-1 md:grid-cols gap-5 pt-5 w-full">
                    <div className="flex">
                      <p className="sm font-semibold">Address</p>
                      <p className="sm font-medium text-slate-500">
                        : Satara, Maharashtra
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-5 pt-5 w-full">
                    <div className="flex ">
                      <p className="sm font-semibold">City</p>
                      <p className="sm font-medium text-slate-500">:Satara</p>
                    </div>
                    <div className="flex ">
                      <p className="sm font-semibold">State</p>
                      <p className="sm font-medium text-slate-500">
                        : Maharashtra
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="sm font-semibold">Zip</p>
                      <p className="sm font-medium text-slate-500">: 412806</p>
                    </div>
                    <div className="flex ">
                      <p className="sm font-semibold">Country</p>
                      <p className="sm font-medium text-slate-500">: India</p>
                    </div>
                  </div>
                </div>
                {/* <Details /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700 text-lg">
                    Details
                  </p>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pt-5 w-full">
                    <div className="flex ">
                      <p className="text-sm font-semibold">Price</p>
                      <p className="text-sm font-medium text-slate-500">
                        : ₹ 20,000/-
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Price Info</p>
                      <p className="text-sm font-medium text-slate-500">
                        : ₹ 20,000 /sq ft
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Property Size</p>
                      <p className="text-sm font-medium text-slate-500">
                        : 3000 sq. ft.
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Bedrooms</p>
                      <p className="text-sm font-medium text-slate-500">: 2</p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Bathrooms</p>
                      <p className="text-sm font-medium text-slate-500">: 4</p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Flats</p>
                      <p className="text-sm font-medium text-slate-500">: 2</p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Furnishing</p>
                      <p className="text-sm font-medium text-slate-500">
                        : Yes
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Parking</p>
                      <p className="text-sm font-medium text-slate-500">
                        : Yes
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="text-sm font-semibold">Floors No</p>
                      <p className="text-sm font-medium text-slate-500">: 12</p>
                    </div>
                  </div>
                </div>
                {/* <ImpAmenities /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700 text-lg">
                    Amenities
                  </p>

                  <div className="pt-3 grid grid-cols-1 md:grid-cols-3 gap-3">
                    <div className="flex space-x-2">
                      <Icon path={mdiGrass} className="w-5 h-5 text-blue-500" />
                      <p className="text-slate-500 text-sm">Lawn</p>
                    </div>

                    <div className="flex space-x-2">
                      <Icon
                        path={mdiDumbbell}
                        className="w-5 h-5 text-blue-500"
                      />
                      <p className="text-slate-500 text-sm">Gym</p>
                    </div>

                    <div className="flex space-x-2">
                      <Icon path={mdiPool} className="w-5 h-5 text-blue-500" />
                      <p className="text-slate-500 text-sm">Swimming Pool</p>
                    </div>
                    <div className="flex space-x-2">
                      <Icon
                        path={mdiMeditation}
                        className="w-5 h-5 text-blue-500"
                      />
                      <p className="text-slate-500 text-sm">Meditation Zone</p>
                    </div>

                    <div className="flex space-x-2">
                      <Icon
                        path={mdiBasketball}
                        className="w-5 h-5 text-blue-500"
                      />
                      <p className="text-slate-500 text-sm">
                        Outdoor & Indoor Spoarts Area
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <Icon
                        path={mdiRoomService}
                        className="w-5 h-5 text-blue-500"
                      />
                      <p className="text-slate-500 text-sm">Spa & Steam room</p>
                    </div>
                    <div className="flex space-x-2">
                      <Icon
                        path={mdiLibraryOutline}
                        className="w-5 h-5 text-blue-500"
                      />
                      <p className="text-slate-500 text-sm">
                        Library & Classroom
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <Icon
                        path={mdiPartyPopper}
                        className="w-5 h-5 text-blue-500"
                      />
                      <p className="text-slate-500 text-sm">
                        Party Lawn & Hall
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <Icon
                        path={mdiTheater}
                        className="w-5 h-5 text-blue-500"
                      />
                      <p className="text-slate-500 text-sm">
                        Lounge, Theater or Cafeteria
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-6 w-full">
                {/* <ImpContactSeller /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700">
                    Contact Seller
                  </p>
                  <div className="pt-5">
                    <div className="flex space-x-2">
                      <div className="pt-1">
                        <img
                          src="/images/The Green Crest Logo.png"
                          alt=""
                          className="w-16 h-16"
                        />
                      </div>
                      <div>
                        <p className="font-semibold">
                          Mahabaleshwar Panchgani Property
                        </p>
                        <p className="md:text-sm text-xs text-slate-400">
                          Mahabaleshwar Panchgani
                        </p>
                        <p className="">+91 86558...</p>
                      </div>
                    </div>
                    <div className="pt-5">
                      <p className="font-medium">Please share your contact</p>
                    </div>
                    <div className="pt-5 space-y-2">
                      <input
                        type="text"
                        placeholder="Enter your Name"
                        className="py-2 px-2 grid w-full border border-slate-300 rounded "
                      />
                      <div className="flex ">
                        <select className="py-2 px-2 grid border-l bg-white border-y border-slate-300 rounded-l">
                          <option>+91</option>
                          <option>+92</option>
                          <option>+93</option>
                          <option>+94</option>
                          <option>+95</option>
                        </select>
                        <input
                          type="text"
                          placeholder="Enter your Phone"
                          className="py-2 px-2 grid w-full border border-slate-300 rounded-r"
                        />
                      </div>
                      <input
                        type="text"
                        placeholder="Enter your Email"
                        className="py-2 px-2 grid w-full border border-slate-300 rounded "
                      />
                    </div>
                    <div className="pt-5 space-y-2">
                      <div
                        className="flex rounded"
                        // onClick={handleClick}
                        // style={{ color: active ? "black" : "gray" }}
                      >
                        <input
                          id="checkbox-item-1"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 rounded border-gray-300"
                        />
                        <label
                          htmlFor="checkbox-item-1"
                          className="ml-2 w-full text-sm rounded"
                        >
                          I agree to be contacted by Housing and other agents
                          via Whatsapp, SMS, phone, email etc
                        </label>
                      </div>
                      <div className="flex rounded">
                        <input
                          id="checkbox-item-2"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 rounded border-gray-300"
                        />
                        <label
                          htmlFor="checkbox-item-2"
                          className="ml-2 w-full text-sm rounded"
                          //   style={{ color: active ? "black" : "gray" }}
                        >
                          I am interested in Home Loans
                        </label>
                      </div>
                    </div>
                    <div className="pt-5 grid">
                      <button className="h-9 p-1 text-white font-medium bg-blue-700 rounded">
                        Get Contact Details
                      </button>
                    </div>
                  </div>
                </div>
                {/* <ChangeMsrmnt /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700">
                    Change Measurement
                  </p>
                  <div className="pt-5">
                    <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
                      <option>
                        square feet - ft<sup>2</sup>
                      </option>
                      <option>
                        suare meters - ft<sup>2</sup>
                      </option>
                      <option>acres - ac</option>
                      <option>
                        square yards - yd<sup>2</sup>
                      </option>
                      <option>hectares - ha</option>
                    </select>
                  </div>
                </div>
                {/* <AdvSearch /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700">
                    Advanced Search
                  </p>

                  <div className="pt-5 space-x-3 flex md:justify-start justify-center">
                    <div className="">
                      <button className="py-3 px-4 text-center text-white text-xs font-medium bg-blue-700 rounded">
                        Rentals
                      </button>
                    </div>
                    <div className="">
                      <button className="py-3 px-4 text-center text-white text-xs font-medium bg-amber-500 rounded">
                        Sales
                      </button>
                    </div>
                    <div className="">
                      <button className="py-3 px-4 text-center text-white text-xs font-medium bg-amber-500 rounded">
                        Invest
                      </button>
                    </div>
                  </div>

                  <div className="pt-3">
                    <div className="pt-2">
                      <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
                        <option>Categories</option>
                        <option>Apartments</option>
                        <option>Candos</option>
                        <option>Houses</option>
                        <option>Industrial</option>
                      </select>
                    </div>
                    <div className="pt-2">
                      <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
                        <option selected>Cities</option>
                        <option>Pune</option>
                        <option>Mumbai</option>
                        <option>Delhi</option>
                      </select>
                    </div>
                    <div className="pt-2">
                      <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
                        <option>Areas</option>
                        <option>Katepuram Chowk</option>
                        <option>Sai Chowk</option>
                      </select>
                    </div>
                    <div className="pt-2">
                      <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
                        <option>Bedrooms</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                    <div className="pt-2">
                      <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
                        <option>Min Size (in sqft)</option>
                      </select>
                    </div>
                    <div className="pt-5 grid">
                      <button className="h-9 p-2 text-white font-medium bg-blue-700 rounded">
                        Send Properties
                      </button>
                    </div>
                  </div>

                  <p className="text-xs text-blue-700 font-semibold pt-3">
                    More Search Options
                  </p>
                </div>
                {/* <OurListings /> */}
                <div className="bg-white md:p-10 p-5">
                  <p className="font-semibold hover:text-blue-700">
                    Our Listings
                  </p>
                  <div className="pt-5 grid grid-cols-1 divide-y">
                    <div className="flex justify-between py-2">
                      <p>Apartments</p>
                      <p>(17)</p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Condos</p>
                      <p>(8)</p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Houses</p>
                      <p>(5)</p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Industrial</p>
                      <p>(1)</p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Land</p>
                      <p>(1)</p>
                    </div>
                    <div className="flex justify-between py-2">
                      <p>Offices</p>
                      <p>(2)</p>
                    </div>
                  </div>
                </div>
                {/* <FeaturedProject /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerDetails_Static1;
