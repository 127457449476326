import React, { useEffect, useState } from "react";
import {
  iulocalitydetail,
  getLocalitydetailForDDL,
  getLocalitydetailDetail,
  getLocalitydetailDelete,
  getLocalitydetailDetailByID,
  getCityForDDL,
  useQuery,
  getCountryForDDL,
  getStateForDDLByCountryID,
  getCityForDDLByStateID,
} from "../../config/api";
import { useNavigate, useLocation } from "react-router-dom";
import { check_islogin } from "../../config/constant";
import Icon from '@mdi/react';
import { mdiCardsDiamondOutline,mdiAlphaDCircleOutline } from '@mdi/js';

function LocalityDetailsPage({ setSmShow, editID }) {
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

  const id = query.get("pid");
  const [state, setState] = useState({
    LocalityID: "0",
    PropertydetailID: id,
    Address: "",
    SocietyName: "",
    Area: "",
    Pincode: "",
    // City: "",
    // State: "",
    // Country: "",
    CityID: "0",
    StateID: "0",
    CountryID: "0",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });



  const [countryddl, setCountryDDL] = useState([]);
  const [stateddl, setStateDDL] = useState([]);
  const [cityddl, setCityDDL] = useState([]);

  useEffect(() => {
    BindCountryDDL();
    // BindStateDDLByCountryID(response[0][0].CountryID);
    // BindCityDDLByStateID(response[0][0].StateID);
    
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(id);
    }
  }, []);

  console.log(state);

  const cityoptions = [
    { value: "JAL", text: "Jalgaon" },
    { value: "PUNE", text: "Pune" },
    { value: "MUM", text: "Mumbai" },
    { value: "DH", text: "Dhule" },
    { value: "AUN", text: "Aurangabad" },
  ];
  const localityoptions = [
    { value: "AL", text: "Apartment" },
    { value: "HL", text: "House" },
    { value: "FL", text: "Flat" },
    { value: "RHL", text: "Row House" },
    { value: "UAL", text: "Village" },
    { value: "CL", text: "City" },
  ];

  function handlechange(e) {
    if(e.target.name==='CountryID'){
      BindStateDDLByCountryID(e.target.value);
    }
    if(e.target.name==='StateID'){
      BindCityDDLByStateID(e.target.value);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  }

  async function SaveData() {
    let errors = {};
    if (validate()) {
      await iulocalitydetail(state)
        .then((response) => {
          
          if (response.ID !== "exists") {
            alert("Locality Details Saved Successfully");
            ResetState();
            navigate(`/post-your-property/amenities-detail?pid=${id}`);
          }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }





  async function BindCountryDDL() {
    await getCountryForDDL(sessionStorage.getItem("UserID"))
      .then((response) => {
        setCountryDDL(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function BindStateDDLByCountryID(CountryID) {
    await getStateForDDLByCountryID(CountryID)
      .then((response) => {
        setStateDDL(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function BindCityDDLByStateID(StateID) {
    await getCityForDDLByStateID(StateID)
      .then((response) => {
        setCityDDL(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }



  console.log(countryddl);

  async function UpdateData(PropertydetailID) {
    
    await getLocalitydetailDetailByID(PropertydetailID)
      .then((response) => {
        if (response !== "" && response?.length > 0) {
          BindCountryDDL();
          BindStateDDLByCountryID(response[0].CountryID);
          BindCityDDLByStateID(response[0].StateID);
          setState(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function ResetState() {
    setState({
      LocalityID: "0",
      PropertydetailID: id,
      Address: "",
      SocietyName: "",
      Area: "",
      Pincode: "",
      // City: "",
      // State: "",
      // Country: "",
      CityID: "0",
      StateID: "0",
      CountryID: "0",
     // DistrictID: "0",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Address) {
      IsValid = false;
      errors["Address"] = "Address Name is Required";
    }
    if (!state?.SocietyName) {
      IsValid = false;
      errors["SocietyName"] = "Society Name is Required";
    }
    if (!state?.Area) {
      IsValid = false;
      errors["Area"] = "Area is Required";
    }
    if (!state?.Pincode) {
      IsValid = false;
      errors["Pincode"] = "Pincode is Required";
    }
    if (!state?.CityID) {
      IsValid = false;
      errors["CityID"] = "City Name is Required";
    }
    if (!state?.StateID) {
      IsValid = false;
      errors["StateID"] = "State is Required";
    }
    if (!state?.CountryID) {
      IsValid = false;
      errors["CountryID"] = "Country is Required";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  console.log(state);

  return (
    <>
       <div className=" text-xl font-semibold  underline underline-offset-[6px] decoration-abhita-200 decoration-[2px]">
      
          {/* <p className="relative before:block before:w-20 before:h-0.5 before:bg-cyan-900 before:left-72 before:top-3 before:absolute"> */}
           <p>
            Locality Details
            </p>
        </div>
      <div className="px-5 pt-5 grid md:grid-cols-3 grid-cols-1 gap-2">
          <div className="mb-2">
            <label
              for="Address"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
             Address
            </label>
            <input
              type="text"
              id="Address"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder="Enter address "
              name="Address"
              value={state.Address}
              onChange={handlechange}
            />
              {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.Address}</div>
          ) : (
            ""
          )}
          </div>

          <div className="mb-2">
          <label
              for="SocietyName"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
            Society
            </label>
            <input
              type="text"
              id="SocietyName"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
            
              name="SocietyName"
              value={state.SocietyName}
              onChange={handlechange}
            />
             {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.SocietyName}</div>
          ) : (
            ""
          )}
          </div>

         
          <div className="mb-2">
          <label
              for="Area"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
            Area
            </label>
            <input
              type="text"
              id="Area"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
             
              name="Area"
              value={state.Area}
              onChange={handlechange}
            />
                {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.Area}</div>
          ) : (
            ""
          )}
          </div>

          <div className="mb-2">
          <label
              for="Pincode"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
            Pincode
            </label>
            <input
              type="text"
              id="Pincode"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
             
              name="Pincode"
              value={state.Pincode}
              onChange={handlechange}
            />
            {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.Pincode}</div>
          ) : (
            ""
          )}
          </div>

         
          <div className="mb-2">
          <label
              for="Country"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
            Country
            </label>
            {/* <input
              type="text"
              id="Country"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
              data-name=""
              name="Country"
              value={state.Country}
              onChange={handlechange}
            /> */}
                 <select
            id="CountryID"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            onChange={handlechange}
            value={state.CountryID}
            data-name="CountryID"
            name="CountryID"
          >
            <option className="text-lg">Select</option>
            {/* {widthoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {item.text}
              </option>
            ))} */}
              {countryddl?.map((item, idx) => (
                    <option key={idx} value={item.CountryID}>
                         {" "}
                      {item.CountryName}
                    </option>
                  ))}
          </select>
             {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.CountryID}</div>
          ) : (
            ""
          )}
          </div>
          
         
           <div className="mb-2">
          <label
              for="State"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
           State
            </label>
            {/* <input
              type="text"
              id="State"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
             
              name="State"
              value={state.State}
              onChange={handlechange}
            /> */}
             <select
            id="StateID"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            onChange={handlechange}
            value={state.StateID}
            data-name="StateID"
            name="StateID"
          >
            <option className="text-lg">Select</option>
            {/* {widthoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {item.text}
              </option>
            ))} */}
              {stateddl?.map((item, idx) => (
                    <option key={idx} value={item.StateID}>
                         {" "}
                      {item.StateName}
                    </option>
                  ))}
          </select>
               {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.StateID}</div>
          ) : (
            ""
          )}
          </div>


          <div className="mb-2">
          <label
              for="City"
              className="block mb-1 text-sm font-medium text-black text-left"
            >
            City
            </label>
            {/* <input
              type="text"
              id="City"
              className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
              placeholder=" "
            
              name="City"
              value={state.City}
              onChange={handlechange}
            /> */}
             <select
            id="CityID"
            className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
            onChange={handlechange}
            value={state.CityID}
            data-name="CityID"
            name="CityID"
          >
            <option className="text-lg">Select</option>
            {/* {widthoptions.map((item, idx) => (
              <option value={item.text} key={idx}>
                {item.text}
              </option>
            ))} */}
              {cityddl?.map((item, idx) => (
                    <option  value={item.CityID} key={idx}>
                      {item.CityName}
                    </option>
                  ))}
          </select>
            {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.CityID}</div>
          ) : (
            ""
          )}
          </div>


        </div>
     

      <div className="flex space-x-2 justify-between py-5 px-10">
        <div>
          <button
            type="button"
            className="inline-block px-6 py-2.5  bg-gray-500 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-abhita-300 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={() => {
              navigate(`/post-your-property/property-detail?pid=${id}`);
            }}
            >
            Previous
          </button>
        </div>

        <div>
          <button
            type="button"
            className="inline-block px-6 py-2.5  bg-abhita-300 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={SaveData}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default LocalityDetailsPage;
