import {
  mdiBasketball,
  mdiDumbbell,
  mdiGrass,
  mdiLibraryOutline,
  mdiMeditation,
  mdiPartyPopper,
  mdiPool,
  mdiRoomService,
  mdiTheater,
} from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

function ImpAmenities({ data }) {
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700 text-lg">Amenities</p>

        <div className="pt-3 grid grid-cols-1 md:grid-cols-3 gap-3">
          {data.feature.split(',')?.map((item, index) => (<div className="flex space-x-2" key={index}>
            <Icon path={mdiGrass} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">{item}</p>
          </div>))}
          {/* {data.feature?.includes('Lawn')?<div className="flex space-x-2">
            <Icon path={mdiGrass} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">Lawn</p>
          </div>:""}
          {data.feature?.includes('Gym')?<div className="flex space-x-2">
            <Icon path={mdiDumbbell} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">Gym</p>
          </div>:""}
          {data.feature?.includes('Swimming Pool')?<div className="flex space-x-2">
            <Icon path={mdiPool} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">Swimming Pool</p>
          </div>:""}
          {data.feature?.includes('Meditation Zone')?<div className="flex space-x-2">
            <Icon path={mdiMeditation} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">Meditation Zone</p>
          </div>:""}
          {data.feature?.includes('Outdoor & Indoor Spoarts Area')?<div className="flex space-x-2">
            <Icon path={mdiBasketball} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">
              Outdoor & Indoor Spoarts Area
            </p>
          </div>:""}
          {data.feature?.includes('Spa & Steam room')?<div className="flex space-x-2">
            <Icon path={mdiRoomService} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">Spa & Steam room</p>
          </div>:""}
          {data.feature?.includes('Library & Classroom')?<div className="flex space-x-2">
            <Icon path={mdiLibraryOutline} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">Library & Classroom</p>
          </div>:""}
          {data.feature?.includes('Party Lawn & Hall')?<div className="flex space-x-2">
            <Icon path={mdiPartyPopper} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">Party Lawn & Hall</p>
          </div>:""}
          {data.feature?.includes('Lounge, Theater or Cafeteria')?<div className="flex space-x-2">
            <Icon path={mdiTheater} className="w-5 h-5 text-blue-500" />
            <p className="text-slate-500 text-sm">
              Lounge, Theater or Cafeteria
            </p>
          </div>:""} */}
        </div>
      </div>
    </>
  );
}

export default ImpAmenities;
