import React from "react";

function ContatcUs() {
  return (
    <>
      {/* <div className="relative bg-gradient-to-t from-bluish-900">
       */}
      <div className="relative">
        <div className="bg-contactimg h-300 bg-center bg-cover bg-no-repeat relative after:bg-black after:absolute after:inset-0 after:opacity-90"></div>
        <div className="absolute top-0 z-[2] md:py-20 py-10 text-center">
          <p className="md:text-3xl z-50 text-white font-semibold pb-10 px-5 md:px-60">
            Contact us today if you’d like to know more about how we help buy,
            sell or rent your property
          </p>
          <div className="md:p-10 p-5 flex justify-center">
            <div className="bg-white rounded md:p-10 p-5">
              <p className="md:text-xl text-lg font-semibold">
                Schedule a meeting with our team
              </p>
              <p className="md:text-md text-sm pt-2 text-center">
                Our experts and developers would love to contribute their
                expertise and insights
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-5">
                <select className="pr-24 pl-2 py-2 border rounded text-sm text-gray-900">
                  <option className="text-gray-900">--Select--</option>
                  <option>Buy</option>
                  <option>Rent</option>
                  <option>Commercial</option>
                </select>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-5">
                <input
                  className="pr-24 pl-2 py-2 border rounded text-sm text-gray-900"
                  type="text"
                  placeholder="Full Name"
                />
                <input
                  className="pr-24 pl-2 py-2 border rounded text-sm text-gray-900"
                  type="text"
                  placeholder="Mobile"
                />
                <input
                  className="pr-24 pl-2 py-2 border rounded text-sm text-gray-900"
                  type="email"
                  placeholder="Email"
                />
                <input
                  className="pr-24 pl-2 py-2 border rounded text-sm text-gray-900"
                  type="text"
                  placeholder="City"
                />
              </div>
              <div className="pt-4 grid grid-rows-2">
                <div className="pt-1">
                  <textarea
                    id="message"
                    className="block p-2.5 w-full text-sm text-gray-900 rounded border border-gray-200"
                    placeholder="Your message..."
                  ></textarea>
                </div>
                <div className="pt-5 grid">
                  <button className="h-9 p-2 text-white font-medium bg-abhita-300 rounded">
                    Send Email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContatcUs;
