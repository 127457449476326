import React from "react";

function FeaturedProperties() {
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700">Featured Properties</p>

        <div className="pt-5 space-y-3">
          <div className="flex space-x-3">
            <div>
              <img
                src="/images/house3.jpg"
                className="rounded w-24 h-16"
                alt=""
              />
            </div>
            <div >
              <p className="text-sm font-medium">Kalpataru Society in Pune</p>
              <p className="text-sm font-semibold text-blue-700">₹860,000</p>
            </div>
          </div>
          <div className="flex space-x-3">
            <div>
              <img
                src="/images/house2.jpg"
                className="rounded w-24 h-16"
                alt=""
              />
            </div>
            <div >
              <p className="text-sm font-medium">Kalpataru Society in Pune</p>
              <p className="text-sm font-semibold text-blue-700">₹860,000</p>
            </div>
          </div>
          <div className="flex space-x-3">
            <div>
              <img
                src="/images/house1.jpg"
                className="rounded w-24 h-16"
                alt=""
              />
            </div>
            <div >
              <p className="text-sm font-medium">Kalpataru Society in Pune</p>
              <p className="text-sm font-semibold text-blue-700">₹860,000</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturedProperties;
