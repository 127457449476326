import React from "react";
import Icon from "@mdi/react";
import {
  mdiSilverwareForkKnife,
  mdiDumbbell,
  mdiWashingMachine,
  mdiTelevision,
  mdiFlowerOutline,
  mdiBasketball,
  mdiHomeVariantOutline,
  mdiGarage,
  mdiHotTub,
  mdiPool,
  mdiCameraIris,
  mdiHomeLightningBoltOutline,
  mdiHeatingCoil,
  mdiMeterGasOutline,
  mdiHvac,
  mdiWaterOutline,
  mdiWheelchair,
  mdiSwapVertical,
  mdiFireplace,
  mdiSmokeDetectorVariant,
  mdiWifi,
} from "@mdi/js";

function OurFeatures() {
  return (
    <>
      <div className="bg-white p-10">
        <p className="font-semibold hover:text-blue-700">Features</p>

        <div className="pt-3">
          <div className="pt-2">
            <p className="font-semibold text-sm">Interior Details</p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 py-2">
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiSilverwareForkKnife}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Equipped Kitchen</p>
              </div>
              <div className="flex space-x-2">
                <Icon path={mdiDumbbell} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Gym</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiWashingMachine}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Laundry</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiTelevision} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Media Room</p>
              </div>
            </div>
          </div>
          <div className="pt-2">
            <p className="font-semibold text-sm">Outdoors Details</p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 py-2">
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiFlowerOutline}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Back yard</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiBasketball} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Basketball court</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiHomeVariantOutline}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Front yard</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiGarage} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Garage Attached</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiHotTub} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Hot Bath</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiPool} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Pool</p>
              </div>
            </div>
          </div>
          <div className="pt-2">
            <p className="font-semibold text-sm">Utilities</p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 py-2">
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiCameraIris} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Central Air</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiHomeLightningBoltOutline}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Electricity</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiHeatingCoil} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Heating</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiMeterGasOutline}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Natural Gas</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiHvac} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Ventilation</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiWaterOutline}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Water</p>
              </div>
            </div>
          </div>
          <div className="pt-2">
            <p className="font-semibold text-sm">Other Features</p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 py-2">
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiWheelchair} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Chair Accessible</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiSwapVertical}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Elevator</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiFireplace} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">Fireplace</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon
                  path={mdiSmokeDetectorVariant}
                  className="w-5 h-5 text-blue-500"
                />
                <p className="text-slate-500 text-sm">Smoke detectors</p>
              </div>
              <div className="flex space-x-2">
                {" "}
                <Icon path={mdiWifi} className="w-5 h-5 text-blue-500" />
                <p className="text-slate-500 text-sm">WiFi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurFeatures;
