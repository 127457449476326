import React from 'react'
import { mdiCheckAll, mdiClose } from '@mdi/js';
import Icon from '@mdi/react'

function BuyerPlans() {
  const plans =[
    {
      title:"Basic Plan",
      rupees:"Free",
      day:"Per Month",
      list:"Lorem ipsum dolor sit ame",
      mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
      mdiClose:<Icon path={mdiClose} size={1} />,
      button:"Buy Now",
      color: "bg-gradient-to-t from-violet-700 to-violet-900 p-5 shadow-md shadow-slate-500",
    },
    
      {
        title:"Standard Plan",
        rupees:"Free",
        day:"Per Month",
        list:"Lorem ipsum dolor sit ame",
        mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
        mdiClose:<Icon path={mdiClose} size={1} />,
        button:"Buy Now",
        color: "bg-gradient-to-t from-lime-500 to-lime-700 p-5 shadow-md shadow-slate-500",
      },
      {
        title:"Basic Plan",
        rupees:"Free",
        day:"Per Month",
        list:"Lorem ipsum dolor sit ame",
        mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
        mdiClose:<Icon path={mdiClose} size={1} />,
        button:"Buy Now",
        color: "bg-gradient-to-t from-amber-500 to-amber-800 p-5 shadow-md shadow-slate-500",
      },
      {
        title:"Basic Plan",
        rupees:"Free",
        day:"Per Month",
        list:"Lorem ipsum dolor sit ame",
        mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
        mdiClose:<Icon path={mdiClose} size={1} />,
        button:"Buy Now",
        color: "bg-gradient-to-t from-cyan-500 to-cyan-800 p-5 shadow-md shadow-slate-500",
    },
  ];
  return (
    <>
    <div className="pt-40 pb-20 md:px-20 px-5 w-full bg-gray-50">
        <p className="text-center font-semibold text-3xl">
            Buyer Plans
        </p>
        <div className="grid md:grid-cols-4 grid-cols-1 md:gap-5 gap-y-20 place-items-center py-20">
        {plans?.map((p, idx) => (
          <div className='relative bg-white px-10 pb-10 pt-28 shadow-md shadow-slate-200' key={idx}
          >
            <ByuerBlog {...p} />
            
          </div>
           ))}

        </div>
      </div>
    </>
  )
}


function ByuerBlog ({title, color, rupees, day, list, mdiClose, mdiCheckAll, button}){
  return (
    <>
     <div >
            <div className='absolute -top-10 w-full left-0 px-10'>
              <div className={color}>
                 <p className='text-white opacity-80 font-medium text-center'>{title}</p>
                 <p className='text-white font-semibold text-3xl text-center uppercase'>{rupees}</p>
                 <p className='text-white opacity-80 font-medium text-center'>{day}</p>
              </div>
            </div>
           
            <ul>
              <li className='flex space-x-2'>
                <i className="text-orange-400">{mdiCheckAll}</i>
                <p>{list}</p>
              </li>
              <li className='flex space-x-2'>
                <i className="text-orange-400">{mdiCheckAll}</i>
                <p>{list}</p>
              </li>
              <li className='flex space-x-2'>
                <i className="text-orange-400">{mdiCheckAll}</i>
                <p>{list}</p>
              </li>
              <li className='flex space-x-2'>
                <i className="text-green-600">{mdiClose}</i>
                <p>{list}</p>
              </li>
              <li className='flex space-x-2'>
                <i className="text-green-600">{mdiClose}</i>
                <p>{list}</p>
              </li>
            </ul>
            <div className='pt-5'>
            <button className='bg-orange-500 text-white font-semibold px-10 py-3 w-full'>{button}</button></div>
          </div>
    </>
  );
}

export default BuyerPlans