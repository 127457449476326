export const propertyprice = [{ value: "500000", label: "₹ 5 lac" },
{ value: "2500000", label: "₹ 25 lac" },
{ value: "5000000", label: "₹ 50 lac" },
{ value: "10000000", label: "₹ 1 Cr" },
{ value: "50000000", label: "₹ 5 Cr" },
{ value: "500000000", label: "₹ 50 Cr" },
{ value: "750000000", label: "₹ 75 Cr" },]

export const propertytype_buy = [
    { value: "Apartment", label: "Apartment" },
    { value: "Independent Hous", label: "Independent Hous" },
    { value: "Plot", label: "Plot" },
    { value: "Studio", label: "Studio" },
    { value: "Villa", label: "Villa" },
    { value: "Agricultural Land", label: "Agricultural Land" },
    { value: "Other", label: "Other" },]
export const propertytype_commercial = [
    { value: "Office Space", label: "Office Space" },
    { value: "Shop", label: "Shop" },
    { value: "Commercial Plot", label: "Commercial Plot" },
    { value: "Warehouse or godown", label: "Warehouse/ godown" },
    { value: "Other", label: "Other" },]

    export const replaceQuery = (key, value, query) => {
        let queryArr = query.toString().split("&");
        let _query = decodeURIComponent(query);
        let send = "";
        let keyvalue=`${key}=${query.get(key)}`;
        if (_query.includes(key + "=")) {
          send = _query.replace(`${keyvalue}`, `${key}=${value}`);
        } else {
          send = `${_query}&${key}=${value}`;
        }
        return send;
      };
      