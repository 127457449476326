import { mdiCloudUploadOutline } from "@mdi/js";
import Icon from "@mdi/react";
//import React from "react";
import { useDropzone } from "react-dropzone";
import FormControl from "../../components/FormControl";
import React, { useEffect, useState } from "react";
import {
  iugallary,
  getGallaryForDDL,
  getGallaryDetail,
  getGallaryDelete,
  getGallaryDetailByID,
  useQuery,
  uploadPackageGallery,
  getPropertyImageCountByPropertyID,
  deleteImage,
  getPackageGallery,
} from "../../config/api";
import { useNavigate, useLocation } from "react-router-dom";
import { check_islogin } from "../../config/constant";
import { baseURL } from "../../http-common";
//import { baseURL, check_islogin, getFileName } from "../../config/constant";

function UploadGallary({ setSmShow, editID }) {
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();

  //const propertydetailid = query.get("id");

  // const id = query.get("id");
  const propertyid = query.get("pid");
  const id = query.get("id");
  const [mode, setMode] = useState("add");
  const [state, setState] = useState({
    // GallaryID: "0",
    // PropertydetailID:id,
    PropertydetailID: "0",
    PropertyImage: "",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  const [uploadfile, setUploadFile] = useState({ File: "", Ext: "" });
  //const id = query.get("id");
  // const id = editID;
  useEffect(() => {
    // BindEducationDDL();
    if (
      propertyid !== "" &&
      propertyid !== null &&
      propertyid !== "0" &&
      propertyid !== 0 &&
      typeof propertyid !== "undefined"
    ) {
      // UpdateData(id);
    }
  }, []);

  const [data, setData] = useState([]);
  console.log(data);
  useEffect(() => {
    if (
      propertyid !== "" &&
      propertyid !== null &&
      propertyid !== "0" &&
      propertyid !== 0 &&
      typeof propertyid !== "undefined"
    ) {
      getData();
    }
  }, []);
  async function getData() {
    await getGallaryDetail(propertyid)
      .then((response) => {
        if (response) {
          setData(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  console.log(data);

  // function handlechange(e) {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // }

  async function _deleteImage(imageid) {
    if (window.confirm("Are you sure to delete.?")) {
      await deleteImage(imageid)
        .then((response) => {
          if (response) {
            //getData();
            alert("Deleted Successfully");
            window.location.reload(true);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  function _updateImage(imageid) {}

  const [progressimage, setProgressimage] = useState();
  let percentimage = 0;
  const configimage = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      percentimage = Math.round((100 * loaded) / total); //Math.floor((loaded * 100) / total)

      console.log(`${loaded}kb of ${total}kb | ${percentimage}%`); // just to see whats happening in the console
      if (percentimage <= 100) {
        setProgressimage(percentimage); // hook to set the value of current level that needs to be passed to the progressbar
      }
    },
    headers: {
      // custom headers goes here
    },
  };
  async function UploadImage() {
    let imagecount = 0;
    await getPropertyImageCountByPropertyID(propertyid).then((response) => {
      imagecount = response[0][0].imagecount;
    });
    const formData = new FormData();
    formData.append(
      "file",
      uploadfile.File,
      "image_" + imagecount + "." + uploadfile.Ext
    );
    formData.append("PropertydetailID", propertyid);
    await iugallary({
      //ID: 0,
      GallaryID: 0,
      PropertydetailID: propertyid,
      PropertyImage:
        "admin/uploads/gallery/" +
        propertyid +
        "/image_" +
        imagecount +
        "." +
        uploadfile.Ext,
      CreatedBy: sessionStorage.getItem("UserID"),
    }).then((response) => {
      uploadPackageGallery(formData)
        .then((response) => {
          // alert(response.success);
          if (response.status === "success") {
            alert("Image Uploaded Successfully");
            window.location.reload(true);
            getData();

            // setTimeout(() => {
            //   //alert("File Uploaded Successfully");
            //   alert("Image Uploaded Successfully");
            //   ResetState();
            // }, 600);
          }
        })
        .catch((error) => {
          alert(error);
        });
    });
  }
  async function handlechangeimage(e) {
    setUploadFile({
      File: e.target.files[0],
      Ext: e.target.files[0].name.split(".").pop(),
    });
  }

  async function ResetState() {
    setState({
      GallaryID: "0",
      PropertydetailID: id,
      PropertyImage: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.PropertyImage) {
      IsValid = false;
      errors["PropertyImage"] = "Property Image is Required";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }
  return (
    <>
      <div className=" text-xl font-semibold  underline underline-offset-[6px] decoration-abhita-200 decoration-[2px]">
        <p>Upload Gallary</p>
      </div>
      <div className="row g-3 px-10 pt-10">
        <input
          label="Property Type Image"
          type="file"
          data-name="PropertyImage"
          id="PropertyImage"
          name="PropertyImage"
          disabled={mode === "view" ? true : false}
          onChange={handlechangeimage}
          accept=".jpg ,.jpeg"
          className="grid grid-cols-1 md:text-base text-sm"
          // error={
          //   state.errors
          //     ? state.errors.PropertyImage
          //       ? state.errors.PropertyImage
          //       : ""
          //     : ""
          // }
        />
          {state.errors ? (
                  <div className="text-red-600 text-left text-xs">
                    {state.errors.Image}
                  </div>
                ) : (
                  ""
                )}
        <span>{state.PropertyImage}</span>
        <div className="relative z-0 p-10 grid place-items-center">
          {/* <button onClick={ResetState} className="btn btn-warning me-3">reset</button> */}
          <button
            onClick={UploadImage}
            className="h-9 p-2 text-white font-medium bg-amber-500 rounded"
          >
            Upload
          </button>
        </div>

        <div className="col-12">
          <div className="text-center">
            <div className="d-flex p-3 gap-3 flex-wrap">
              {data?.map((item, idx) => (
                <div
                  className="d-flex justify-content-center flex-wrap border rounded w-24 p-2"
                  key={idx}
                >
                  <a href={baseURL + "/" + item.PropertyImage} target="_blank">
                    <img
                      src={baseURL + "/" + item.PropertyImage}
                      width="100"
                      alt=""
                      className="d-block"
                    />
                  </a>
                  <div className="w-100 text-center pt-1 justify-content-center pb-2">
                    {/* <span>{item.PropertyImage}</span> */}
                  </div>
                  <button
                    onClick={() => _deleteImage(item.ImageID)}
                    className="mx-auto bg-white border rounded text-primary"
                  >
                    delete
                  </button>
                </div>
              ))}
            </div>

            {state.errors ? (
                  <div className="text-red">
                    {state.errors.PropertyImage}
                  </div>
                ) : (
                  ""
                )}
         
            <div className="relative z-0 p-10">


            <div className="flex space-x-2 justify-between py-5 px-10">
          <div>
            <button
              type="button"
              className="h-9 p-2 text-white font-medium bg-amber-500 rounded"
              onClick={() => {
                navigate(`/post-your-property/amenities-detail?pid=${propertyid}`);
              }}
            >
              Previous
            </button>
          </div>
              {/* <button className="h-9 p-2 text-white font-medium bg-amber-500 rounded"
                onClick={() => {
                  navigate(`/post-your-property/amenities-detail?id=1`);
                }}>
                PREVIOUS
              </button> */}
              <button
                className="h-9 p-2 text-white font-medium bg-amber-500 rounded"
                onClick={() => {
                  navigate(`/my_listing`);
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

//   return (
//     <>
//       <div className="bg-abhita-100 rounded-t-lg">
//         <p className="text-2xl font-bold text-white px-10 py-5">
//           Preferred Tenants
//         </p>
//       </div>
//       <div className="p-10">
//         <div
//           className="grid place-items-center py-20 border-dashed border-4
//          hover:border-blue-600 hover:bg-blue-50"
//         >
//           <Icon
//             path={mdiCloudUploadOutline}
//             className="w-10 h-10 text-center text-blue-500"
//           />
//           <Dropzone />
//         </div>
//       </div>
//     </>
//   );
// }

// function Dropzone({ open }) {
//   const { getRootProps, getInputProps } = useDropzone({});
//   return (
//     <div {...getRootProps({ className: "dropzone" })}>
//       <input className="input-zone" {...getInputProps()} />
//       <div className="text-center">
//         <p className="dropzone-content hover:underline text-blue-500 text-lg">
//           Drag & Drop file here
//         </p>
//       </div>
//     </div>
//   );
// }

export default UploadGallary;
