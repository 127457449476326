import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./layouts/Navbar";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Footer from "./layouts/Footer";
import MainComponents from "./components/MainComponents";
import PropertyListing from "./pages/PropertyListing";
import PLHorizontal from "./propListings/PLHorizontal";
import PropViewDetails from "./pages/PropViewDetails";
import Policy from "./pages/Policy";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import UserGuide from "./pages/UserGuide";
import BannerDetails2 from "./pages/BannerDetails2";
import Profile from "./pages/Profile";
import NewContactUs from "./pages/NewContactUs";

import NewPostProperty from "./pages/NewPostProperty";
import PersonalDetailsPage from "./pages/NewPostProperty/PersonalDetailsPage";
import PropertyDetailsPage from "./pages/NewPostProperty/PropertyDetailsPage";
import LocalityDetailsPage from "./pages/NewPostProperty/LocalityDetailsPage";
import PropertyFeaturesPage from "./pages/NewPostProperty/PropertyFeaturesPage";
import UploadGallaryPage from "./pages/NewPostProperty/UploadGallaryPage";

import TenantPlans from "./plans/TenantPlans";
import SellerPlans from "./plans/SellerPlans";
import BuyerPlans from "./plans/BuyerPlans";
import NewPropertyDetails from "./pages/NewPostProperty/NewPropertyDetails";
import BannerDetails_Static1 from "./pages/StaticData/BannerDetails_Static1";
import BannerDetails_Static2 from "./pages/StaticData/BannerDetails_Static2";
import BannerDetails_Static3 from "./pages/StaticData/BannerDetails_Static3";
import Property_Static1 from "./pages/StaticData/Property_Static1";
import Property_Static2 from "./pages/StaticData/Property_Static2";
import Property_Static3 from "./pages/StaticData/Property_Static3";
import PropertiesList_Static from "./pages/StaticData/PropertiesList_Static";
import MyLising from "./pages/MyLising";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainComponents />} />
        <Route path="/services" element={<Services />} />
        <Route path="/user-guide" element={<UserGuide />} />
        <Route path="/post-your-property" element={<NewPostProperty />}>
          <Route path="personal-detail" element={<PersonalDetailsPage />} />
          {/* <Route path="property-detail1" element={<PropertyDetailsPage />} /> */}
          <Route path="property-detail" element={<NewPropertyDetails />} />
          <Route path="locality-detail" element={<LocalityDetailsPage />} />
          <Route path="amenities-detail" element={<PropertyFeaturesPage />} />
          <Route path="gallery" element={<UploadGallaryPage />} />
        </Route>
        <Route path="/profile" element={<Profile />} />
        <Route path="/my_listing" element={<MyLising />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/property-list" element={<PropertyListing />} />
        <Route path="/property-hlist" element={<PLHorizontal />} />
        <Route path="/details" element={<PropViewDetails />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<NewContactUs />} />

        <Route path="/property-detail" element={<BannerDetails2 />} />

        <Route path="/tenant-plans" element={<TenantPlans />} />
        {/* <Route path="/owner-plans" element={<OwnerPlans />} />       */}
        <Route path="/seller-plans" element={<SellerPlans />} />
        <Route path="/buyer-plans" element={<BuyerPlans />} />
        {/* <Route path="/commercial-plans" element={<CommercialPlans />} /> */}

        {/* Start Static data component  */}
        <Route
          path="/propertiesliststatic"
          element={<PropertiesList_Static />}
        />
        <Route
          path="/property-detail-static1"
          element={<BannerDetails_Static1 />}
        />
        <Route
          path="/property-detail-static2"
          element={<BannerDetails_Static2 />}
        />
        <Route
          path="/property-detail-static3"
          element={<BannerDetails_Static3 />}
        />
        <Route path="/property-detail-static4" element={<Property_Static1 />} />
        <Route path="/property-detail-static5" element={<Property_Static2 />} />
        <Route path="/property-detail-static6" element={<Property_Static3 />} />
        {/* End Static data component  */}
      </Routes>

      <Footer />
    </>
  );
}

export default App;
