import React, { useEffect } from "react";
import HeadSection from "../homeSection/HeadSection";
import SearchResult from "../homeSection/SearchResult";
import Overview from "../homeSection/Overview";
import OurListings from "../homeSection/OurListings";
import WhyChoose from "../homeSection/WhyChoose";
import ContatcUs from "../homeSection/ContatcUs";
import Testimonials from "../homeSection/Testimonials";
import Cities from "../homeSection/Cities";
import Banner from "../homeSection/Banner";

function MainComponents() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="space-y-20">
        <div className="relative">
          <HeadSection />
          <div className="absolute z-40 w-full">
            <SearchResult />
          </div>
        </div>
        <Cities />
        <Overview />
        <Banner />
         <OurListings />
        <WhyChoose />
        <ContatcUs />
        <Testimonials />
      </div>
    </>
  );
}

export default MainComponents;
