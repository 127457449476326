import React from "react";

function Description() {
  return (
    <>
      <div className="bg-white p-10">
        <p className="font-semibold hover:text-blue-700">Description</p>
        <p className="text-sm text-slate-500 pt-5">
          Beautiful, updated, ground level Co-op apartment in the desirable Bay
          Terrace neighborhood. This home features hardwood floors throughout,
          brand new bathrooms, newer EIK, modern front-load washer/dryer, full
          dining room, large living area, 3 spacious bedrooms and plenty of
          storage. <br /><br/>
          Master bedroom includes both a standard closet and custom closet wall
          unit. Large windows face many directions for tons of natural light.
          Just steps away from QM2 express bus to Manhattan and local buses;
          only minutes from the LIRR.
          <br /><br />
          Beautiful, updated, ground level Co-op apartment in the desirable Bay
          Terrace neighborhood. This home features hardwood floors throughout,
          brand new bathrooms, newer EIK, modern front-load washer/dryer, full
          dining room, large living area, 3 spacious bedrooms and plenty of
          storage. <br /><br />
          Master bedroom includes both a standard closet and custom closet wall
          unit. Large windows face many directions for tons of natural light.
          Just steps away from QM2 express bus to Manhattan and local buses;
          only minutes from the LIRR.
        </p>
      </div>
    </>
  );
}

export default Description;
