import React, { useEffect, useState } from "react";
import {
  getPropertydetailDetailByID,
  iupropertydetail,
  useQuery,
} from "../../config/api";
import { useNavigate, useLocation } from "react-router-dom";
function NewPropertyDetails() {
  const [BuyShow, setBuyShow] = useState(true);
  const [RentShow, setRentShow] = useState(false);
  const [CommShow, setCommShow] = useState(false);

  const [CommBuyShow, setCommBuyShow] = useState(false);
  const [CommRentShow, setCommRentShow] = useState(false);
  const [CommSellShow, setCommSellShow] = useState(false);

  const [isActiveBuy, setIsActiveBuy] = useState(true);
  const [isActiveSellRent, setIsActiveSellRent] = useState(false);
  const [isActiveComm, setIsActiveComm] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();
  let query = useQuery();
  const uid = query.get("uid");
  const id = query.get("pid");
  const [state, setState] = useState({
    PropertydetailID: "0",
    Name: "",
    PlanType: "Buy",
    SubPlanType: "",
    PropertyType: "",
    MinPropertyPrice: "",
    MinPropertyPriceType: "",
    MaxPropertyPrice: "",
    MaxPropertyPriceType: "",
    RentalPrice: "",
    RentalPriceType: "",
    PropertyPriceInfo: "",
    PropertySize: "",
    PropertySizeType: "",
    Availability: "",
    Furnishing: "",
    YearBuilt: "",
    ConstructionStatus: "",
    BHKType: "",
    Specification: "",
    CompletionMonth: "",
    CompletionYear: "",
    BHK_1_MinBudget: "",
    BHK_1_MinBudgetType: "",
    BHK_1_MaxBudget: "",
    BHK_1_MaxBudgetType: "",
    BHK_1_PropertySize: "",
    BHK_1_PropertySizeType: "",
    BHK_2_MinBudget: "",
    BHK_2_MinBudgetType: "",
    BHK_2_MaxBudget: "",
    BHK_2_MaxBudgetType: "",
    BHK_2_PropertySize: "",
    BHK_2_PropertySizeType: "",
    BHK_3_MinBudget: "",
    BHK_3_MinBudgetType: "",
    BHK_3_MaxBudget: "",
    BHK_3_MaxBudgetType: "",
    BHK_3_PropertySize: "",
    BHK_3_PropertySizeType: "",
    BHK_4_MinBudget: "",
    BHK_4_MinBudgetType: "",
    BHK_4_MaxBudget: "",
    BHK_4_MaxBudgetType: "",
    BHK_4_PropertySize: "",
    BHK_4_PropertySizeType: "",
    IsProject: "0",
    CreatedBy: sessionStorage.getItem("UserID"), // 0 means admin,
    errors: [],
  });

  useEffect(() => {
    // BindEducationDDL();
    if (
      id !== "" &&
      id !== null &&
      id !== "0" &&
      id !== 0 &&
      typeof id !== "undefined"
    ) {
      UpdateData(id);
    }
  }, []);
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  console.log(state);
  async function UpdateData(PropertydetailID) {
    debugger;
    await getPropertydetailDetailByID(PropertydetailID)
      .then((response) => {
        setState(response[0]);
        console.log(response);
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function SaveData() {
    debugger;
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iupropertydetail(state)
        .then((response) => {
          if (response[0].ID !== "exists") {
            alert("Property Details Saved Successfully");
            ResetState();
            // window.location.reload(true);
            navigate(
              `/post-your-property/locality-detail?pid=${response[0].ID}`
            );

          } else {
            alert("This Property Name Already exists...");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Name) {
      IsValid = false;
      errors["Name"] = "Name is Required";
    }

    // if (!state?.YearBuilt) {
    //   IsValid = false;
    //   errors["YearBuilt"] = "Year Built is Required";
    // }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }
  async function ResetState() {
    setState({
      PropertydetailID: "0",
      Name: "",
      PlanType: "Buy",
      SubPlanType: "",
      PropertyType: "",
      MinPropertyPrice: "",
      MinPropertyPriceType: "",
      MaxPropertyPrice: "",
      MaxPropertyPriceType: "",
      RentalPrice: "",
      RentalPriceType: "",
      PropertyPriceInfo: "",
      PropertySize: "",
      PropertySizeType: "",
      Availability: "",
      Furnishing: "",
      YearBuilt: "",
      ConstructionStatus: "",
      BHKType: "",
      Specification: "",
      CompletionMonth: "",
      CompletionYear: "",
      BHK_1_MinBudget: "",
      BHK_1_MinBudgetType: "",
      BHK_1_MaxBudget: "",
      BHK_1_MaxBudgetType: "",
      BHK_1_PropertySize: "",
      BHK_1_PropertySizeType: "",
      BHK_2_MinBudget: "",
      BHK_2_MinBudgetType: "",
      BHK_2_MaxBudget: "",
      BHK_2_MaxBudgetType: "",
      BHK_2_PropertySize: "",
      BHK_2_PropertySizeType: "",
      BHK_3_MinBudget: "",
      BHK_3_MinBudgetType: "",
      BHK_3_MaxBudget: "",
      BHK_3_MaxBudgetType: "",
      BHK_3_PropertySize: "",
      BHK_3_PropertySizeType: "",
      BHK_4_MinBudget: "",
      BHK_4_MinBudgetType: "",
      BHK_4_MaxBudget: "",
      BHK_4_MaxBudgetType: "",
      BHK_4_PropertySize: "",
      BHK_4_PropertySizeType: "",
      IsProject: "0",
      CreatedBy: sessionStorage.getItem("UserID"), // 0 means admin,
      errors: [],
    });
  }
  const funBuy = () => {
    setBuyShow(true);
    setRentShow(false);
    setCommShow(false);
    setCommBuyShow(false);
    setCommSellShow(false);
    setCommRentShow(false);

    setIsActiveBuy(true);
    setIsActiveSellRent(false);
    setIsActiveComm(false);

    setState({ ...state, PlanType: "Buy" });
  };
  const funRent = () => {
    setRentShow(true);
    setBuyShow(false);
    setCommShow(false);
    setCommBuyShow(false);
    setCommSellShow(false);
    setCommRentShow(false);

    setIsActiveBuy(false);
    setIsActiveSellRent(true);
    setIsActiveComm(false);

    setState({ ...state, PlanType: "SellOrRent" });
  };
  const funComm = () => {
    setCommShow(true);
    setBuyShow(false);
    setRentShow(false);
    setCommBuyShow(true);
    setCommSellShow(false);
    setCommRentShow(false);

    setIsActiveBuy(false);
    setIsActiveSellRent(false);
    setIsActiveComm(true);

    setState({ ...state, PlanType: "Commercial" });
  };

  const funCommBuyShow = () => {
    setCommBuyShow(true);
    setCommSellShow(false);
    setCommRentShow(false);

    setState({ ...state, SubPlanType: "Buy" });
  };
  const funCommRentShow = () => {
    setCommRentShow(true);
    setCommSellShow(false);
    setCommBuyShow(false);

    setState({ ...state, SubPlanType: "Rent" });
  };
  const funCommSellShow = () => {
    setCommSellShow(true);
    setCommRentShow(false);
    setCommBuyShow(false);

    setState({ ...state, SubPlanType: "Sell" });
  };

  const propertytypeoptions = [
    { value: "Apartment", text: "Apartment" },
    { value: "Independent House", text: "Independent House" },
    { value: "Plot", text: "Plot" },
    { value: "Studio", text: "Studio" },
    { value: "Villa", text: "Villa" },
    { value: "Agricultural Land", text: "Agricultural Land" },
    { value: "Other", text: "Other" },
  ];

  const propertytypeoptionscommercial = [
    { value: "Office Space", text: "Office Space" },
    { value: "Shop", text: "Shop" },
    { value: "Commercial Plot", text: "Commercial Plot" },
    { value: "Warehouse / Godown", text: "Warehouse / Godown" },
    { value: "Other", text: "Other" },
  ];

  const SqFtSqtr = [
    { value: "Sq. Ft", text: "Sq. Ft" },
    { value: "Sq. Mtr", text: "Sq. Mtr" },
  ];

  const availability = [
    { value: "Within 15 days", text: "Within 15 days" },
    { value: "Within 1 month", text: "Within 1 month" },
    { value: "After a month", text: "After a month" },
  ];

  const yearBuilt = [
    {
      value: "2000-2005",
      text: "2000-2005",
    },
    {
      value: "2006-2010",
      text: "2006-2010",
    },
    {
      value: "2011- 2015",
      text: "2011- 2015",
    },
    {
      value: "2016-2020",
      text: "2016-2020",
    },
    {
      value: "2021-2023",
      text: "2021-2023",
    },
  ];

  const constructionStatus = [
    {
      value: "Ready to Move",
      text: "Ready to Move",
    },
    {
      value: "Under Construction",
      text: "Under Construction",
    },
  ];

  const Budget = [
    {
      value: "Lacs",
      text: "Lacs",
    },
    {
      value: "Crore",
      text: "Crore",
    },
  ];
  const furnishing = [
    {
      value: "Fully Furnished",
      text: "Fully Furnished",
    },
    {
      value: "Semi Furnished",
      text: "Semi Furnished",
    },
    {
      value: "Unfurnished",
      text: "Unfurnished",
    },
  ];
  return (
    <>
      <div className="py-5 px-7">
        <div className="flex space-x-2">
          <div className="">
            <p
              className={
                isActiveBuy
                  ? "font-semibold text-sm cursor-pointer rounded px-5 py-2  bg-abhita-300 text-white"
                  : "text-abhita-300 bg-gray-300 font-semibold text-sm cursor-pointer rounded px-5 py-2 "
              }
              onClick={funBuy}
            >
              Buy
            </p>
          </div>
          <div>
            <p
              className={
                isActiveSellRent
                  ? "font-semibold text-sm cursor-pointer rounded px-5 py-2  bg-abhita-300 text-white"
                  : "text-abhita-300 bg-gray-300 font-semibold text-sm cursor-pointer rounded px-5 py-2 "
              }
              onClick={funRent}
            >
              Sell / Rent
            </p>
          </div>
          <div>
            <p
              className={
                isActiveComm
                  ? "font-semibold text-sm cursor-pointer rounded px-5 py-2  bg-abhita-300 text-white"
                  : "text-abhita-300 bg-gray-300 font-semibold text-sm cursor-pointer rounded px-5 py-2 "
              }
              onClick={funComm}
            >
              Commercial
            </p>
          </div>
        </div>
        <div className="py-5">
          {CommShow ? (
            <div className="flex mb-5 space-x-10">
              <div className="flex space-x-1">
                <input
                  type="radio"
                  name="byuRentComm"
                  onClick={funCommBuyShow}
                  defaultChecked
                />
                <label className="text-sm font-medium" onClick={funCommBuyShow}>
                  Buy
                </label>
              </div>
              <div className="flex space-x-1">
                <input
                  type="radio"
                  name="byuRentComm"
                  onClick={funCommRentShow}
                />
                <label
                  className="text-sm font-medium"
                  onClick={funCommRentShow}
                >
                  Rent
                </label>
              </div>
              <div className="flex space-x-1">
                <input
                  type="radio"
                  name="byuRentComm"
                  onClick={funCommSellShow}
                />
                <label
                  className="text-sm font-medium"
                  onClick={funCommSellShow}
                >
                  Sell
                </label>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* Property type */}
          <div className="">
            {BuyShow || RentShow || CommShow ? (
              <div className=" mb-2">
                <div>
                  <label
                    for="PropertyType"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Property Type
                  </label>

                  <select
                    id="PropertyType"
                    className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    // onChange={handlechange}
                    // value={state.PropertyType}
                    data-name="PropertyType"
                    name="PropertyType"
                    value={state.PropertyType}
                    onChange={handleChange}
                  >
                    <option className="text-lg">Select Property Type</option>
                    {(CommShow
                      ? propertytypeoptionscommercial
                      : propertytypeoptions
                    ).map((item, idx) => (
                      <option value={item.text} key={idx}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* Property Price */}
          <div className="">
            {BuyShow || CommBuyShow ? (
              <div className="mb-2">
                <label
                  for="PropertyPrice"
                  className="block mb-1 text-sm font-medium text-black text-left"
                >
                  Property price
                </label>
                <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
                  <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-3">
                    <div className="col-span-2">
                      <label
                        for="MinBudget"
                        className="block mb-1 text-sm font-medium text-black text-left"
                      >
                        Min Budget
                      </label>
                      <input
                        type="text"
                        id="MinBudget"
                        className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                        placeholder="Enter Min Budget"
                        name="MinPropertyPrice"
                        value={state.MinPropertyPrice}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label
                        for="LacsCrore"
                        className="block mb-1 text-sm font-medium text-black text-left"
                      >
                        Lacs / Crore
                      </label>
                      <select
                        id="LacsCrore"
                        className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                        data-name="LacsCrore"
                        name="MinPropertyPriceType"
                        value={state.MinPropertyPriceType}
                        onChange={handleChange}
                      >
                        <option className="text-lg">Select</option>
                        {Budget.map((item, idx) => (
                          <option value={item.text} key={idx}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-3">
                    <div className="col-span-2">
                      <label
                        for="PropertySize"
                        className="block mb-1 text-sm font-medium text-black text-left"
                      >
                        Max Budget
                      </label>
                      <input
                        type="text"
                        id="MaxBudget"
                        className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                        placeholder="Enter Max Budget"
                        name="MaxPropertyPrice"
                        value={state.MaxPropertyPrice}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label
                        for="PropertySize"
                        className="block mb-1 text-sm font-medium text-black text-left"
                      >
                        Lacs / Crore
                      </label>
                      <select
                        id="LacsCrore"
                        className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                        name="MaxPropertyPriceType"
                        value={state.MaxPropertyPriceType}
                        onChange={handleChange}
                      >
                        <option className="text-lg">Select</option>
                        {Budget.map((item, idx) => (
                          <option value={item.text} key={idx}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {RentShow || CommRentShow || CommSellShow ? (
              <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
                <div className="mb-2">
                  <label
                    for="PropertyPrice"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Property Price
                  </label>
                  <input
                    type="text"
                    id="PropertyPrice"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter Property Price"
                    name="MinPropertyPrice"
                    value={state.MinPropertyPrice}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label
                    for="LacsCrore"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Lacs / Crore
                  </label>
                  <select
                    id="LacsCrore"
                    className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    data-name="LacsCrore"
                    name="MinPropertyPriceType"
                    value={state.MinPropertyPriceType}
                    onChange={handleChange}
                  >
                    <option className="text-lg">Select</option>
                    {Budget.map((item, idx) => (
                      <option value={item.text} key={idx}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2">
                  <label
                    for="Rental price"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Rental Price
                  </label>
                  <input
                    type="text"
                    id="Rental price"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter rental price"
                    name="RentalPrice"
                    value={state.RentalPrice}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label
                    for="LacsCrore"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Lacs / Crore
                  </label>
                  <select
                    id="LacsCrore"
                    className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    name="RentalPriceType"
                    value={state.RentalPriceType}
                    onChange={handleChange}
                  >
                    <option className="text-lg">Select</option>
                    {Budget.map((item, idx) => (
                      <option value={item.text} key={idx}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
            {/* Property name */}
            <div className="mb-2">
              <label
                for="PropertyName"
                className="block mb-1 text-sm font-medium text-black text-left"
              >
                Property Name
              </label>
              <input
                type="text"
                id="PropertyName"
                className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                placeholder="Enter Property Name"
                name="Name"
                value={state.Name}
                onChange={handleChange}
                required
              />
            </div>



            {/* Property size */}
            <div className="grid grid-cols-3 gap-5 mb-2">
              <div className="col-span-2">
                <label
                  for="PropertySize"
                  className="block mb-1 text-sm font-medium text-black text-left"
                >
                  Property Size
                </label>
                <input
                  type="text"
                  id="PropertySize"
                  className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                  placeholder="Enter Property Name"
                  name="PropertySize"
                  value={state.PropertySize}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  for="sqft"
                  className="block mb-1 text-sm font-medium text-black text-left"
                >
                  Sq. Ft / Sq. Mtr
                </label>

                <select
                  id="sqft"
                  className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                  name="PropertySizeType"
                  value={state.PropertySizeType}
                  onChange={handleChange}
                >
                  <option className="text-lg">Select </option>
                  {SqFtSqtr.map((item, idx) => (
                    <option value={item.text} key={idx}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Availability */}
            <div className="mb-2">
              <label
                for="Availability"
                className="block mb-1 text-sm font-medium text-black text-left"
              >
                Availability
              </label>

              <select
                id="Availability"
                className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                name="Availability"
                value={state.Availability}
                onChange={handleChange}
              >
                <option className="text-lg">Select</option>
                {availability.map((item, idx) => (
                  <option value={item.text} key={idx}>
                    {item.text}
                  </option>
                ))}
              </select>
            </div>

            {/* Furnishing */}
            <div className="mb-2">
              <label
                for="Furnishing"
                className="block mb-1 text-sm font-medium text-black text-left"
              >
                Furnishing
              </label>
              <select
                id="Furnishing"
                className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                name="Furnishing"
                value={state.Furnishing}
                onChange={handleChange}
              >
                <option className="text-lg">Select</option>
                {furnishing.map((item, idx) => (
                  <option value={item.text} key={idx}>
                    {item.text}
                  </option>
                ))}
              </select>
            </div>

            {/* Year Built */}
            <div className="mb-2">
              <label
                for="YearBuild"
                className="block mb-1 text-sm font-medium text-black text-left"
              >
                Year Built
              </label>

              <select
                id="YearBuilt"
                className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                name="YearBuilt"
                value={state.YearBuilt}
                onChange={handleChange}
              >
                <option className="text-lg">Select</option>
                {yearBuilt.map((item, idx) => (
                  <option value={item.text} key={idx}>
                    {item.text}
                  </option>
                ))}
              </select>
            </div>

            {/* Construction Status */}
            {BuyShow || CommBuyShow || CommRentShow || CommSellShow ? (
              <div className="mb-2">
                <label
                  for="Construction Status"
                  className="block mb-1 text-sm font-medium text-black text-left"
                >
                  Construction Status
                </label>

                <select
                  id="Construction Status"
                  className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                  name="ConstructionStatus"
                  value={state.ConstructionStatus}
                  onChange={handleChange}
                >
                  <option className="text-lg">Select</option>
                  {constructionStatus.map((item, idx) => (
                    <option value={item.text} key={idx}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}

            {/* OtherSpecification */}
            <div className="mb-2">
              <label
                for="OtherSpecification"
                className="block mb-1 text-sm font-medium text-black text-left"
              >
                Other Specification
              </label>
              <textarea
                type="text"
                id="OtherSpecification"
                className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                placeholder="Enter Property Name"
                name="Specification"
                value={state.Specification}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-2 justify-between py-5 px-10">
        {/* <div>
          <button
            type="button"
            className="inline-block px-6 py-2.5  bg-gray-500 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-abhita-300 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={() => {
              navigate(`/post-your-property/property-detail?pid=${id}`);
            }}
            >
            Previous
          </button>
        </div> */}

        <div>
          <button
            type="button"
            className="inline-block px-6 py-2.5  bg-abhita-300 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={SaveData}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default NewPropertyDetails;
