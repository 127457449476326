import { mdiStarCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect } from "react";
import { Animated } from "react-animated-css";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <div className="md:p-20 p-5 md:pt-10 pt-20">
        <div className="relative flex justify-end">
          <p className="md:-left-72 -left-20 absolute -rotate-90 md:top-72 top-20 md:text-5xl font-bold md:text-gray-300 text-gray-400 md:tracking-[20px] tracking-[5px]">
          Abhita Properties
          </p>
          <img
            src="/images/newKamdhenu.jpeg"
            alt=""
            className="w-full md:h-[550px] h-[200px] md:pl-40 pl-10"
          />

          <div className="bg-gradient-to-t from-abhita-400 to-abhita-300 opacity-60 shadow-lg shadow-gray-400 absolute md:top-52 top-10 md:left-10 left-5 md:w-80 w-40 md:h-40 h-20"></div>
          <div className="absolute md:top-52 top-3 md:left-10 -left-2 p-10">
            <p className="text-white font-semibold md:text-4xl tracking-wider md:pb-5 pb-2">
            Abhita Properties
            </p>
            <ul className="bg-white flex py-1 md:px-5 px-2 justify-between">
              <li className="flex md:space-x-1 md:text-sm text-xs font-semibold">
                <Icon
                  path={mdiStarCircleOutline}
                  className="md:w-2 w-1 md:h-2 h-1 mt-1.5"
                />
                <p>Buy</p>
              </li>

              <li className="flex space-x-1 md:text-sm text-xs font-semibold">
                <Icon
                  path={mdiStarCircleOutline}
                  className="md:w-2 w-1 md:h-2 h-1 mt-1.5"
                />
                <p>Rent</p>
              </li>
              <li className="flex space-x-1 md:text-sm text-xs font-semibold">
                <Icon
                  path={mdiStarCircleOutline}
                  className="md:w-2 w-1 md:h-2 h-1 mt-1.5"
                />
                <p>Commercial</p>
              </li>
            </ul>
          </div>

          <div className="lg:block md:hidden hidden">
            <div className="absolute -bottom-40 left-52 flex space-x-5">
              <div className="bg-gray-50 shadow-lg rounded shadow-gray-300 p-10 grid place-items-center">
                <img
                  src="/images/consultation.png"
                  alt=""
                  className="w-20 h-20"
                />
                <div className="pt-5 grid place-items-center">
                  <p className="text-lg font-semibold">Consultation</p>
                  <p className="text-md text-gray-400 font-semibold">Service</p>
                </div>
              </div>
            </div>
            <div className="absolute -bottom-[420px] left-52 flex space-x-5">
              <div className="bg-gray-50 shadow-lg rounded shadow-gray-300 p-10 grid place-items-center">
                <img src="/images/homeLoan.png" alt="" className="w-20 h-20" />
                <div className="pt-5 grid place-items-center">
                  <p className="text-lg font-semibold">Home Loan</p>
                  <p className="text-md text-gray-400 font-semibold">Service</p>
                </div>
              </div>
            </div>
            <div className="absolute -bottom-52 left-[420px] flex space-x-5">
              <div className="bg-gray-50 shadow-lg rounded shadow-gray-300 p-10 grid place-items-center">
                <img src="/images/valuation.png" alt="" className="w-20 h-20" />
                <div className="pt-5 grid place-items-center">
                  <p className="text-lg font-semibold">Valuation</p>
                  <p className="text-md text-gray-400 font-semibold">Service</p>
                </div>
              </div>
            </div>
            <div className="absolute -bottom-[470px] left-[420px] flex space-x-5">
              <div className="bg-gray-50 shadow-lg rounded shadow-gray-300 p-10 grid place-items-center">
                <img src="/images/deed.png" alt="" className="w-20 h-20" />
                <div className="pt-5 grid place-items-center">
                  <p className="text-lg font-semibold">Sales Deed</p>
                  <p className="text-md text-gray-400 font-semibold">Service</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 lg:gap-y-32 md:gap-y-0">
          <div></div>
          <div className="relative grid place-content-end lg:pl-10">
            <p
              className="md:text-3xl text-2xl text-left text-abhita-300 font-semibold md:pt-20 pt-10 md:pb-0 pb-2
          md:before:w-[430px] md:before:h-0.5 md:before:bg-abhita-400 md:before:absolute relative md:before:top-24 md:md:before:left-32"
            >
              About Us
            </p>

            <p className="md:text-md text-sm text-justify md:pt-5">
            Abhita Properties is a platform that connects the homebuyers and
              investors with the rightful Property Dealers, Owners and Sellers.
              It facilitates the posting of current and upcoming Projects,
              guiding the Buyers and Tenant towards verified Property.
            </p>
            <p className="md:text-md text-sm text-justify pt-2">
              It helps you in posting your property, browsing through the listed
              properties and finding the best property fulfilling your needs.
            </p>
            <p className="md:text-md text-sm text-justify pt-2">
            Abhita Properties is the best Solution for all your property search,
              search for an authentic buyer and tenant.
            </p>
            <p className="md:text-md text-sm text-justify pt-2">
              Connect with Abhita Properties for an incredible experience.
            </p>
          </div>
          <div className="lg:pl-40 md:pl-0 md:mb-0 mb-10">
            <p
              className="md:text-3xl text-2xl text-left text-abhita-300 font-semibold md:pt-20 pt-10 pb-2 
            md:before:w-[210px] md:before:h-0.5 md:before:bg-abhita-400 md:before:absolute relative md:before:top-24 md:before:left-[270px]"
            >
              Why you Choose us
            </p>

            <p className="md:text-md text-sm text-justify md:pt-5">
              Our well experienced Property Advisors guides you towards the best
              Property for your investment. We not only feature Residential and
              Commercial Property but also Plots, Agricultural Land, etc. We
              bring you the Key to your Dream Home.
            </p>
          </div>
          <div className="md:relative lg:block md:hidden hidden">
            <img
              src="/images/que-man.png"
              alt=""
              className="w-80 md:absolute md:-top-20 md:left-40"
            />
          </div>

          {window.screen.availWidth > 767 && (
            <img
              src="/images/que-man.png"
              alt=""
              className="w-fit h-40 absolute -bottom-60 right-0 opacity-40 md:hidden block"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AboutUs;
