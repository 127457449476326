import React, { useEffect } from "react";
import { Animated } from "react-animated-css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

function UserGuide() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const guide = [
  //   {
  //     img: "/images/mirror-engine.png",
  //   },
  //   {
  //     title: "Fill up the basic details",
  //     desc: "Provide your property information and pictures / videos",
  //     img: "/images/basicDetails.png",
  //     step: "01",
  //   },
  //   {
  //     title: "Property review Process",
  //     desc: "Your property details will be verified by our team",
  //     img: "/images/1 png.png",
  //     step: "02",
  //   },
  //   {
  //     title: "Your Property goes live on our Website",
  //     img: "/images/LiveWebsite.png",
  //     step: "03",
  //   },
  // ];

  return (
    <>
      <div className=" md:px-20 py-20 px-5 md:py-32 p-5">
        <p className="font-semibold md:text-4xl text-3xl text-left text-abhita-300 py-5 border-b border-dashed">
          Sell & Rent Property Listing Process
        </p>

        <div className="divide-y">
          {/* How to Post Property */}
          <div className="py-20">
            <div className="grid place-items-start">
              <Animated
                animationIn="fadeInLeft"
                animationOut=""
                isVisible={true}
              >
                <div className="bg-gradient-to-r from-[#5a3a0a] bg-[#966f33] rounded py-3 px-5 relative">
                  <div className="absolute top-1 border border-[#966f33] -right-5 -z-10 w-[45px] h-[45px] bg-[#966f33] rotate-45"></div>
                  <p className="text-xl font-semibold text-center text-white opacity-80">
                    How to post your property
                  </p>
                </div>
              </Animated>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-center gap-20 py-5 px-10 pt-40">
              <div className="relative bg-white border border-dashed  flex justify-center w-[250px] h-[250px] ">
                <div className="absolute -top-24 z-10">
                  <div className="relative pt-5 bg-white border-[3px] border-abhita-300 rounded-full p-2 w-20 h-20 ">
                    <p className="font-semibold text-abhita-400 text-2xl text-center">
                      01
                    </p>
                    <img
                      src="/images/step.png"
                      alt=""
                      className="w-16 absolute top-1 right-1.5 "
                    />
                  </div>
                </div>
                <div className="w-[200px] h-[265px] absolute -top-2 -z-10 bg-abhita-400"></div>
                <div className="bg-abhita-300 p-1 absolute top-4 w-[280px] z-20">
                  <p className="text-white text-lg text-semibold text-center">
                    Fill up the basic details
                  </p>
                </div>
                <div className="absolute top-28 px-3 text-center z-30">
                  <p className="md:text-md justify-center font-medium flex text-center px-5">
                    Provide your property information and pictures / videos
                  </p>
                </div>
                <div className=" opacity-30">
                  <img
                    src="/images/basicDetails.png"
                    alt=""
                    className="w-full h-fit"
                  />
                </div>
              </div>
              <div className="relative bg-white border border-dashed  flex justify-center w-[250px] h-[250px] ">
                <div className="absolute -top-24 z-10">
                  <div className="relative pt-5 bg-white border-[3px] border-abhita-300 rounded-full p-2 w-20 h-20 ">
                    <p className="font-semibold text-abhita-400 text-2xl text-center">
                      02
                    </p>
                    <img
                      src="/images/step.png"
                      alt=""
                      className="w-16 absolute top-1 right-1.5 "
                    />
                  </div>
                </div>
                <div className="w-[200px] h-[265px] absolute -top-2 -z-10 bg-abhita-400"></div>
                <div className="bg-abhita-300 p-1 absolute top-4 w-[280px] z-20">
                  <p className="text-white text-lg text-semibold text-center">
                    Property review Process
                  </p>
                </div>
                <div className="absolute top-28 px-3 text-center z-10">
                  <p className="md:text-md justify-center font-medium flex text-center px-5">
                    Your property details will be verified by our team
                  </p>
                </div>
                <div className=" opacity-30">
                  <img
                    src="/images/1 png.png"
                    alt=""
                    className="w-full h-fit"
                  />
                </div>
              </div>
              <div className="relative bg-white border border-dashed  flex justify-center w-[250px] h-[250px] ">
                <div className="absolute -top-24 z-10">
                  <div className="relative pt-5 bg-white border-[3px] border-abhita-300 rounded-full p-2 w-20 h-20 ">
                    <p className="font-semibold text-abhita-400 text-2xl text-center">
                      03
                    </p>
                    <img
                      src="/images/step.png"
                      alt=""
                      className="w-16 absolute top-1 right-1.5 "
                    />
                  </div>
                </div>
                <div className="w-[200px] h-[265px] absolute -top-2 -z-10 bg-abhita-400"></div>
                <div className="bg-abhita-300 p-1 absolute top-4 w-[280px] z-20">
                  <p className="text-white text-lg text-semibold text-center">
                    Property goes live
                  </p>
                </div>
                <div className="absolute top-28 px-3 text-center z-10">
                  <p className="md:text-md justify-center font-medium flex text-center px-5">
                    Your Property goes live on our Website
                  </p>
                </div>
                <div className=" opacity-30">
                  <img
                    src="/images/LiveWebsite.png"
                    alt=""
                    className="w-full h-fit"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* How to search Property */}
          <div className="pt-20">
            <div className="grid place-items-start">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
                <div className="bg-gradient-to-r from-[#5a3a0a] bg-[#966f33] rounded py-3 px-5 relative">
                  <div className="absolute top-1 border border-[#966f33] -right-5 -z-10 w-[45px] h-[45px] bg-[#966f33] rotate-45"></div>
                  <p className="text-xl font-semibold text-center text-white opacity-80">
                    How to search property
                  </p>
                </div>
              </AnimationOnScroll>
            </div>
            

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-center gap-20 py-5 px-10 pt-40">
              <div className="relative bg-white border border-dashed  flex justify-center w-[250px] h-[250px] ">
                <div className="absolute -top-24 z-10">
                  <div className="relative pt-5 bg-white border-[3px] border-abhita-300 rounded-full p-2 w-20 h-20 ">
                    <p className="font-semibold text-abhita-400 text-2xl text-center">
                      01
                    </p>
                    <img
                      src="/images/step.png"
                      alt=""
                      className="w-16 absolute top-1 right-1.5 "
                    />
                  </div>
                </div>
                <div className="w-[200px] h-[265px] absolute -top-2 -z-10 bg-abhita-400"></div>
                <div className="bg-abhita-300 p-1 absolute top-4 w-[280px] z-20">
                  <p className="text-white text-lg text-semibold text-center">
                    Search Location
                  </p>
                </div>
                <div className="absolute top-28 px-3 text-center z-30">
                  <p className="md:text-md justify-center font-medium flex text-center px-5">
                    Enter the Location/District you want to search in the Search
                    Bar
                  </p>
                </div>
                <div className=" opacity-30">
                  <img src="/images/a.png" alt="" className="w-full h-fit" />
                </div>
              </div>
              <div className="relative bg-white border border-dashed  flex justify-center w-[250px] h-[250px] ">
                <div className="absolute -top-24 z-10">
                  <div className="relative pt-5 bg-white border-[3px] border-abhita-300 rounded-full p-2 w-20 h-20 ">
                    <p className="font-semibold text-abhita-400 text-2xl text-center">
                      02
                    </p>
                    <img
                      src="/images/step.png"
                      alt=""
                      className="w-16 absolute top-1 right-1.5 "
                    />
                  </div>
                </div>
                <div className="w-[200px] h-[265px] absolute -top-2 -z-10 bg-abhita-400"></div>
                <div className="bg-abhita-300 p-1 absolute top-4 w-[280px] z-20">
                  <p className="text-white text-lg text-semibold text-center">
                    Select Property
                  </p>
                </div>
                <div className="absolute top-28 px-3 text-center z-10">
                  <p className="md:text-md justify-center font-medium flex text-center px-5">
                    Select the Property type your looking for
                  </p>
                </div>
                <div className=" opacity-30">
                  <img
                    src="/images/prop-looking-for.png"
                    alt=""
                    className="w-full h-fit"
                  />
                </div>
              </div>
              <div className="relative bg-white border border-dashed  flex justify-center w-[250px] h-[250px] ">
                <div className="absolute -top-24 z-10">
                  <div className="relative pt-5 bg-white border-[3px] border-abhita-300 rounded-full p-2 w-20 h-20 ">
                    <p className="font-semibold text-abhita-400 text-2xl text-center">
                      03
                    </p>
                    <img
                      src="/images/step.png"
                      alt=""
                      className="w-16 absolute top-1 right-1.5 "
                    />
                  </div>
                </div>
                <div className="w-[200px] h-[265px] absolute -top-2 -z-10 bg-abhita-400"></div>
                <div className="bg-abhita-300 p-1 absolute top-4 w-[280px] z-20">
                  <p className="text-white text-lg text-semibold text-center">
                    View Property
                  </p>
                </div>
                <div className="absolute top-28 px-3 text-center z-10">
                  <p className="md:text-md justify-center font-medium flex text-center px-5">
                    View the Properties as per your requirement
                  </p>
                </div>
                <div className=" opacity-30">
                  <img
                    src="/images/asPerReq.png"
                    alt=""
                    className="w-full h-fit"
                  />
                </div>
              </div>
              <div className="relative bg-white border border-dashed  flex justify-center w-[250px] h-[250px] ">
                <div className="absolute -top-24 z-10">
                  <div className="relative pt-5 bg-white border-[3px] border-abhita-300 rounded-full p-2 w-20 h-20 ">
                    <p className="font-semibold text-abhita-400 text-2xl text-center">
                      04
                    </p>
                    <img
                      src="/images/step.png"
                      alt=""
                      className="w-16 absolute top-1 right-1.5 "
                    />
                  </div>
                </div>
                <div className="w-[200px] h-[265px] absolute -top-2 -z-10 bg-abhita-400"></div>
                <div className="bg-abhita-300 p-1 absolute top-4 w-[280px] z-20">
                  <p className="text-white text-lg text-semibold text-center">
                    Advance Search
                  </p>
                </div>
                <div className="absolute top-28 px-3 text-center z-10">
                  <p className="md:text-md justify-center font-medium flex text-center px-5">
                    Find the advanced search option on the left side which helps
                    you in customized search
                  </p>
                </div>
                <div className=" opacity-30">
                  <img
                    src="/images/AdvSearch.png"
                    alt=""
                    className="w-full h-fit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserGuide;
