import React, { useState } from "react";

function ImpContactSeller() {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700">Contact Seller</p>
        <div className="pt-5">
          <div className="flex space-x-2">
            <div className="pt-1">
              <img
                src="/images/The Green Crest Logo.png"
                alt=""
                className="w-16 h-16"
              />
            </div>
            <div>
              <p className="font-semibold">Unimont Imperia</p>
              <p className="md:text-sm text-xs text-slate-400">Unimont Group</p>
              <p className="">+91 86558...</p>
            </div>
          </div>
          <div className="pt-5">
            <p className="font-medium">Please share your contact</p>
          </div>
          <div className="pt-5 space-y-2">
            <input
              type="text"
              placeholder="Enter your Name"
              className="py-2 px-2 grid w-full border border-slate-300 rounded "
            />
            <div className="flex ">
              <select className="py-2 px-2 grid border-l bg-white border-y border-slate-300 rounded-l">
                <option>+91</option>
                <option>+92</option>
                <option>+93</option>
                <option>+94</option>
                <option>+95</option>
              </select>
              <input
                type="text"
                placeholder="Enter your Phone"
                className="py-2 px-2 grid w-full border border-slate-300 rounded-r"
              />
            </div>
            <input
              type="text"
              placeholder="Enter your Email"
              className="py-2 px-2 grid w-full border border-slate-300 rounded "
            />
          </div>
          <div className="pt-5 space-y-2">
            <div
              className="flex rounded"
              onClick={handleClick}
              style={{ color: active ? "black" : "gray" }}
            >
              <input
                id="checkbox-item-1"
                type="checkbox"
                value=""
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="checkbox-item-1"
                className="ml-2 w-full text-sm rounded"
              >
                I agree to be contacted by Housing and other agents via
                Whatsapp, SMS, phone, email etc
              </label>
            </div>
            <div className="flex rounded">
              <input
                id="checkbox-item-2"
                type="checkbox"
                value=""
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor="checkbox-item-2"
                className="ml-2 w-full text-sm rounded"
                style={{ color: active ? "black" : "gray" }}
              >
                I am interested in Home Loans
              </label>
            </div>
          </div>
          <div className="pt-5 grid">
            <button className="h-9 p-1 text-white font-medium bg-blue-700 rounded">
              Get Contact Details
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImpContactSeller;
