import React from "react";

function ImpAddress({ data }) {
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700 text-lg">Address</p>

        <div className="grid grid-cols-1 md:grid-cols gap-5 pt-5 w-full">
          <div className="flex">
            <p className="sm font-semibold">Address</p>
            <p className="sm font-medium text-slate-500">
              :{data.address}
            </p>
          </div>

          <div className="flex ">
            <p className="sm font-semibold">Society Name</p>
            <p className="sm font-medium text-slate-500">: {data.societyname}</p>
          </div>
          <div className="flex ">
            <p className="sm font-semibold">Area</p>
            <p className="sm font-medium text-slate-500">: {data.area}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-5 pt-5 w-full">
          <div className="flex ">
            <p className="sm font-semibold">City</p>
            <p className="sm font-medium text-slate-500">:{data.city}</p>
          </div>
          <div className="flex ">
            <p className="sm font-semibold">State</p>
            <p className="sm font-medium text-slate-500">: {data.state}</p>
          </div>
          <div className="flex ">
            <p className="sm font-semibold">Zip</p>
            <p className="sm font-medium text-slate-500">
              : {data.pincode}
            </p>
          </div>
          <div className="flex ">
            <p className="sm font-semibold">Country</p>
            <p className="sm font-medium text-slate-500">
              : {data.country}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImpAddress;
