import React from "react";
import { Link } from "react-router-dom";

function PLHeadSection() {
  return (
    <>
      <div className="pt-3 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-2">
        <div>
          <p className="text-2xl text-slate-700 font-medium">Properties</p>
        </div>
      </div>
      <div className="flex space-x-2">
        <p className="text-sm text-slate-600 hover:text-blue-700">
          <Link to="/">Home</Link> /
        </p>
        <p className="text-sm text-blue-700">Properties</p>
      </div>
    </>
  );
}

export default PLHeadSection;
