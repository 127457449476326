import {
  mdiHeartOutline,
  mdiMapMarkerOutline,
  mdiPrinterOutline,
  mdiShareVariant,
} from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

function ImpHeadingSection({data}) {
  return (
    <>
      <div className="flex justify-between">
        <div className="flex ">
          <div className="py-2">
            <button className="py-2 px-5 text-center text-white text-xs font-medium bg-blue-700 rounded">
              {data.type}
            </button>
          </div>
          {/* <div className="p-2">
            <button className="py-2 px-5 text-center text-white text-xs font-medium bg-blue-700 rounded">
              Houses
            </button>
          </div> */}
        </div>
        {/* <div className="pt-5">
          <p className="text-gray-600 md:text-sm">Last Updated: Dec 29, 2022</p>
        </div> */}
      </div>
      <div className="flex justify-between">
        <div className="pt-2">
          <p className="md:text-3xl font-semibold">{data.propertyname}</p>
        </div>
        {/* <div>
          <p className=" md:text-3xl font-medium">₹22 L - 43 L</p>
          <p className="text-sm text-blue-700 text-center font-semibold">
            EMI starts at ₹11.65 K
          </p>
        </div> */}
      </div>
      <div className="flex justify-between pt-2">
        <div className="flex space-x-1">
          <span className="text-sm">By</span>
          <p className="uppercase text-sm font-semibold truncate text-blue-700">
            {data.name}
          </p>
        </div>
        <div>
          <p className="text-xs text-gray-400">Onwards*</p>
        </div>
      </div>

      {/* <div className="md:flex md:justify-between">
        <div className="flex pt-4 text-sm text-slate-600">
          <Icon path={mdiMapMarkerOutline} className="h-5 w-5" />
          <p className="truncate">
            CTS No.3531, Near Unimont Empire, NH4- Mumbai Pune National Highway,
            Khopoli, Navi Mumbai
          </p>
        </div>
        <div className="flex">
          <div className="p-2">
            <button className="flex space-x-1 py-2 px-5 text-center text-xs font-medium bg-white rounded">
              <Icon path={mdiShareVariant} className="h-4 w-4" />
              <p>Share</p>
            </button>
          </div>
          <div className="p-2">
            <button className="flex space-x-1 py-2 px-5 text-center text-xs font-medium bg-white rounded">
              <Icon path={mdiHeartOutline} className="h-4 w-4" />
              <p>Favourite</p>
            </button>
          </div>
          <div className="p-2">
            <button className="flex space-x-1 py-2 px-5 text-center text-xs font-medium bg-white rounded">
              <Icon path={mdiPrinterOutline} className="h-4 w-4" />
              <p>Print</p>
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ImpHeadingSection;
