import { mdiChevronRight, mdiEmail, mdiMapMarker, mdiPhone } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

function Footer() {
  return (
    <>
      <div className="relative">
        <div className="bg-footerimg md:h-[400px] h-[950px] bg-center bg-cover bg-no-repeat relative after:bg-black after:absolute after:inset-0 after:opacity-90"></div>
        <footer className="absolute top-0 bottom-0 text-white">
          <div className="md:px-20 px-5">
            <div className="md:py-10 pt-10 md:px-10 grid grid-cols-1 md:grid-cols-5 md:gap-x-10 gap-y-10">
              <div className="col-span-2 opacity-90">
                <div className="space-y-5">
                  <a href="/" className="flex items-center">
                    <img
                      src="/images/Abhita-Properties-Logo-for-footer.jpg"
                      alt=""
                      className="w-40"
                    />
                  </a>
                  <p className="md:text-md text-sm text-left  hover:opacity-100">
                    We are recognized for exceeding client expectations and
                    delivering great results through dedication, ease of
                    process, and extraordinary services to our worldwide
                    clients. Highlight the best of your properties by using the
                    List Category shortcode. You can list categories, types,
                    cities, areas and states of your choice.
                  </p>
                </div>
              </div>
              <div className="col-span-3 grid md:grid-cols-3 grid-cols-1 md:gap-y-0 gap-y-5 opacity-90">
                <div className="md:pt-6 opacity-90">
                  <div className="space-y-4">
                    <h5 className="uppercase font-bold mb-2.5 md:text-center underline underline-offset-4">
                      Official Info
                    </h5>
                    <div className="space-y-3">
                      <div className="flex space-x-2  hover:opacity-100">
                        <div>
                          <Icon path={mdiMapMarker} className="w-5 h-5" />
                        </div>
                        <div>
                          <p className="md:text-md text-sm">
                            Office No-1208 12th Floor, International Trade
                            Centre, Kamdhenu, Sector-14, Kharghar, Navi Mumbai -
                            410210
                          </p>
                        </div>
                      </div>

                      <div className="md:text-md text-sm flex space-x-2  hover:opacity-100">
                        <div>
                          <Icon path={mdiEmail} className="w-5 h-5" />
                        </div>
                        <div>
                          <p className="text-left  hover:opacity-100">
                            info@abhitaproperties.com
                          </p>
                        </div>
                      </div>
                      <div className="md:text-md text-sm flex space-x-2  hover:opacity-100">
                        <div>
                          <Icon path={mdiPhone} className="w-5 h-5" />
                        </div>
                        <div>
                          <p className="text-left  hover:opacity-100">
                            +91 7710073844
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:pt-6 md:flex md:justify-center">
                  <div>
                    <h5 className="uppercase font-bold mb-2.5 pl-1 text-left underline underline-offset-4">
                      Quick Links
                    </h5>
                    <ul className="list-none space-y-2">
                      <li className="flex space-x-1">
                        <Icon
                          path={mdiChevronRight}
                          className="w-5 h-5 text-white"
                        />
                        <a
                          href="/about-us"
                          className="md:text-md text-sm text-white hover:underline  hover:opacity-100"
                        >
                          About Us
                        </a>
                      </li>
                      <li className="flex space-x-1">
                        <Icon
                          path={mdiChevronRight}
                          className="w-5 h-5 text-white"
                        />
                        <a
                          href="/contact-us"
                          className="md:text-md text-sm text-white hover:underline  hover:opacity-100"
                        >
                          Contact Us
                        </a>
                      </li>
                      <li className="flex space-x-1">
                        <Icon
                          path={mdiChevronRight}
                          className="w-5 h-5 text-white"
                        />
                        <a
                          href="/post-your-property"
                          className="md:text-md text-sm text-white hover:underline  hover:opacity-100"
                        >
                          Post Your Property
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="md:pt-6 space-y-5">
                  <div className="">
                    <h5 className="uppercase md:pt-0 pt-10 font-bold mb-2.5 md:text-center underline underline-offset-4">
                      Our Partner
                    </h5>
                    <div>
                      <div
                        id="carouselExampleSlidesOnly"
                        className="carousel slide relative md:grid md:justify-center"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-inner relative w-full ">
                          <div className="carousel-item active relative w-full">
                            <img
                              src="/images/abhitaland.png"
                              className="lg:w-40 w-40 "
                              alt=""
                            />
                          </div>
                          {/* <div className="carousel-item relative float-left w-full">
                            <img
                              src="/images/logolegavac.png"
                              className="block md:w-full w-40"
                              alt=""
                            />
                          </div>
                          <div className="carousel-item relative float-left w-full">
                            <img
                              src="/images/abhitafinance.png"
                              className="block md:w-full w-40"
                              alt=""
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="flex mb-6 md:justify-center">
                        <a
                          href="https://www.facebook.com/abhitapropertiespvtltd/"
                          type="button"
                          className="rounded-full border-2 border-white text-white leading-normal uppercase  hover:bg-blue-600 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
                          target="_blank"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="facebook-f"
                            className="w-2 h-full mx-auto"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                            ></path>
                          </svg>
                        </a>

                        <a
                          href="https://www.instagram.com/abhitapropertiespvtltd/"
                          type="button"
                          className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-gradient-to-l  to-yellow-600 via-pink-500 from-purple-500  focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
                          target="_blank"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="instagram"
                            className="w-3 h-full mx-auto"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            ></path>
                          </svg>
                        </a>

                        <a
                          href="#!"
                          type="button"
                          className="rounded-full border-2 border-white text-white leading-normal uppercase  hover:bg-blue-400 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
                          target="_blank"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="twitter"
                            className="w-3 h-full mx-auto"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                            ></path>
                          </svg>
                        </a>

                        <a
                          href="https://www.linkedin.com/company/88435348/admin/feed/posts/"
                          type="button"
                          className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-sky-600  focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
                          target="_blank"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="linkedin-in"
                            className="w-3 h-full mx-auto"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-2 md:py-0 py-5 md:flex md:justify-between md:px-20 px-3 opacity-90">
            <div className="flex md:space-x-2">
              <div className="md:text-sm text-xs">
                © {new Date().getFullYear()} Copyright :{" "}
                <a className="text-white hover:font-semibold" href="/">
                Abhita Properties 
                </a>{" "}
                <span> Design & Developed by</span>{" "}
                <a
                  href="https://www.dthrill.com/"
                  target="blank"
                  className="hover:font-semibold"
                >
                  DThrill
                </a>
              </div>
            </div>
            <div className="flex justify-center space-x-5">
              <a
                href="/privacy-policy"
                className="md:text-sm text-xs text-white hover:underline  hover:opacity-100"
              >
                Privacy Policy
              </a>
              <a
                href="#!"
                className="md:text-sm text-xs text-white hover:underline  hover:opacity-100"
              >
                Terms & Conditions
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
