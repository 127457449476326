/* eslint-disable no-undef */
import React from "react";
import Icon from "@mdi/react";
import {
  mdiSofaSingleOutline,
  mdiHumanMaleFemaleChild,
  mdiDomain,
  mdiMapMarkerOutline,
  mdiKeyOutline,
  mdiPencil,
} from "@mdi/js";
import { Link } from "react-router-dom";
import { baseURL } from "../http-common";

function PLHorizontal({ data,type }) {
  return (
    <>
      <div className="space-y-4">
        <div className="bg-white border rounded-xl shadow">
          <PropertyBlog data={data} type={type} />

        </div>
      </div>
    </>
  );
}

function PropertyBlog({ data,type }) {
  return (
    <>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
        <div className="relative">
          <img
            src={baseURL + "/" + data.image}
            alt=""
            className="lg:w-72 md:w-72 w-full h-52 lg:rounded-l md:rounded-l rounded-t"
          />
          <div className="px-2">
            <button className="absolute z-10 top-2 py-1 px-3 text-white font-semibold text-xs rounded bg-abhita-400">
              {data.PlanType}
            </button>
          
          {type==="seller"?
            <button className="absolute z-10 top-2 py-1 px-3 text-white font-semibold text-xs rounded bg-abhita-400"
            style={{marginLeft: "10em"}}>
              {data.IsVerify==='1'||data.IsVerify===1 ?"Verified":"Not Verify"}
            </button>
          :""}
          </div>
        </div>
        <div className="py-3 lg:pl-0 lg:pr-10 md:pl-5 md:pr-10 px-5 w-full col-span-2">
          <div className="pt-1">
            <div className="flex justify-between">
              <p className="text-2xl text-slate-900 font-bold">{data.Name}</p>
              {type==="seller"?<Link to={`/post-your-property/property-detail?pid=${data.PropertydetailID}`}>
                <Icon path={mdiPencil} className="h-7 w-7 text-green-600" />
              </Link>:""}
            </div>
            <div className="flex py-1 text-sm text-slate-700">
              <Icon path={mdiMapMarkerOutline} className="h-5 w-5" />
              <p>{data.Address}</p>
            </div>
          </div>
          <div className="flex divide-x divide-slate-600 pt-2">
            <div className="flex pr-5">
              <p className="text-sm">Beds: {data.Bedroom}</p>
            </div>
            <div className="flex px-5">
              <p className="text-sm">Baths: {data.Bathroom}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 pt-2">
            <div className="flex text-slate-700  space-x-2">
              <Icon path={mdiSofaSingleOutline} className="h-5 w-5" />
              <p className="text-sm pt-1">{data.Furnishing}</p>
            </div>

            <div className="flex text-slate-700 space-x-2">
              <Icon path={mdiKeyOutline} className="h-5 w-5" />
              <p className="text-sm pt-1">{data.Availability}</p>
            </div>
          </div>
          <div></div>
          <div className="md:flex justify-between pt-4 md:space-y-0 space-y-2">
            <div className="md:text-center text-left">
              <p className="text-2xl leading-10">₹ {data.minPrice}</p>
            </div>
            <div className="text-center">
              <Link to={`/property-detail?id=${data.PropertydetailID}`}>
                <button className=" py-2 px-5 md:w-full text-white font-medium bg-abhita-300 rounded flex justify-end">
                  View Details
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>


      {/* <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="exampleModalCenteredScrollable"
        tabIndex="-1"
        aria-labelledby="exampleModalCenteredScrollable"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-bold leading-normal text-gray-800"
                id="exampleModalCenteredScrollableLabel"
              >
                Disclaimer
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">
              <p className="text-justify pt-2 font-semibold">
                The data (based on the search query performed), on the webpage
                of dreamestates.in has been made available for informational
                purposes only and no representation or warranty is expressly or
                impliedly given as to its accuracy. Any investment decisions
                that you take should not be based solely on the information that
                is available on the website dreamestates.in or downloaded from
                it. Nothing contained herein shall be deemed to constitute legal
                advice, solicitation, invitation to acquire by the
                developer/builder or any other entity. You are advised to visit
                the relevant RERA website and contact builder/advertisers
                directly to know more about the project before taking any
                decision based on the contents displayed on the website
                dreamestates.in. If you have any question or want to share
                feedback, feel free to write to us at support@dreamestates.in.
                Trademarks belong to the respective owners.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default PLHorizontal;
