import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import axiosInstance, { useQuery } from "../http-common";
import AdvSearch from "../propListings/AdvSearch";
import PLHeadSection from "../propListings/PLHeadSection";
import PLHorizontal from "../propListings/PLHorizontal";

function PropertyListing() {
  const [searchParams] = useSearchParams();
  const url = useLocation();
  const query = useQuery();
  const [filter, setFilter] = useState({
    location: query.get("l"),
    PType: query.get("t"),
    PropertyType: query.get("pt"),
    PropertyPrice: query.get("b"),
    BHKType: query.get("bt"),
    Availability: query.get("a"),
    PreferredTenant: query.get("pta"),
    Furnishing: query.get("f"),
    Parking: query.get("pw"),
    IsVerify: 1,
    CreatedBy: '0',
  });
  const [data, setData] = useState([]);



  useEffect(() => {
    // document.getElementById("root").style.background = "rgb(241 245 249)";
    // bindMetaData();
    bindData();
  }, [searchParams]);
  useEffect(() => {
    bindData();
  }, [filter]);

  useEffect(() => {
    const qKey = ["l", "t",
      "pt",
      "b", "bt", "a", "pta", "f", "pw"];
    const stateKey = [
      "location", "PType",
      "PropertyType",
      "PropertyPrice", "BHKType",
      "Availability",
      "PreferredTenant",
      "Furnishing",
      "Parking",
    ];
    const tempObj = { ...filter };
    for (let [key, value] of searchParams.entries()) {
      const idx = qKey.findIndex((val) => val === key);
      tempObj[stateKey[idx]] = value;
    }
    setFilter(tempObj);
  }, [url]);

  function bindData() {
    let __l = "0";
    let __t = "0";
    let __pt = "0";
    let __b = "0";
    let __bt = "0";
    let __a = "0";
    let __pta = "0";
    let __f = "0";
    let __pw = "0";
    if (
      filter.location !== undefined &&
      filter.location !== "undefined" &&
      filter.location !== null &&
      filter.location !== ""
    ) {
      __l = filter.location;
    }
    if (
      filter.PType !== undefined &&
      filter.PType !== "undefined" &&
      filter.PType !== null &&
      filter.PType !== ""
    ) {
      __t = filter.PType;
    }
    if (
      filter.PropertyType !== undefined &&
      filter.PropertyType !== "undefined" &&
      filter.PropertyType !== null &&
      filter.PropertyType !== ""
    ) {
      __pt = filter.PropertyType;
    }
    if (
      filter.PropertyPrice !== undefined &&
      filter.PropertyPrice !== "undefined" &&
      filter.PropertyPrice !== null &&
      filter.PropertyPrice !== ""
    ) {
      __b = filter.PropertyPrice;
    }
    if (
      filter.BHKType !== undefined &&
      filter.BHKType !== "undefined" &&
      filter.BHKType !== null &&
      filter.BHKType !== ""
    ) {
      __bt = filter.BHKType;
    }
    if (
      filter.Availability !== undefined &&
      filter.Availability !== "undefined" &&
      filter.Availability !== null &&
      filter.Availability !== ""
    ) {
      __a = filter.Availability;
    }
    if (
      filter.PreferredTenant !== undefined &&
      filter.PreferredTenant !== "undefined" &&
      filter.PreferredTenant !== null &&
      filter.PreferredTenant !== ""
    ) {
      __pta = filter.PreferredTenant;
    }
    if (
      filter.Furnishing !== undefined &&
      filter.Furnishing !== "undefined" &&
      filter.Furnishing !== null &&
      filter.Furnishing !== ""
    ) {
      __f = filter.Furnishing;
    }
    if (
      filter.Parking !== undefined &&
      filter.Parking !== "undefined" &&
      filter.Parking !== null &&
      filter.Parking !== ""
    ) {
      __pw = filter.Parking;
    }
    console.log({
      Location: __l === "" ? "0" : __l,
      PType: __t === "" ? "0" : __t,
      PropertyType: __pt === "" ? "0" : __pt,
      PropertyPrice: __b === "" ? "0" : __b,
      BHKType: __bt === "" ? "0" : __bt,
      Availability: __a === "" ? "0" : __a,
      PreferredTenant: __pta === "" ? "0" : __pta,
      Furnishing: __f === "" ? "0" : __f,
      Parking: __pw === "" ? "0" : __pw,
      IsVerify: '1',
      CreatedBy: '0',
    });
    axiosInstance
      .post("getPropertydetail_web", {
        Location: __l === "" ? "0" : __l,
        PType: __t === "" ? "0" : __t,
        PropertyType: __pt === "" ? "0" : __pt,
        PropertyPrice: __b === "" ? "0" : __b,
        BHKType: __bt === "" ? "0" : __bt,
        Availability: __a === "" ? "0" : __a,
        PreferredTenant: __pta === "" ? "0" : __pta,
        Furnishing: __f === "" ? "0" : __f,
        Parking: __pw === "" ? "0" : __pw,
        IsVerify: '1',
        CreatedBy: '0',
      })
      .then((res) => {debugger
        if (res?.data) {
          setData(res?.data);
        }
      })

  }
  console.log(data)
  return (
    <>
      <div className="bg-slate-100">
        <div className="py-20 lg:px-10 md:px-10 px-5">
          <PLHeadSection />
          <div className=" pt-5 grid lg:grid-cols-4 md:grid-cols-1 grid-cols-1 items-start lg:gap-5 md:gap-0 lg:gap-y-0 md:gap-y-5 gap-y-5">
            <div className="w-full">
              <AdvSearch />
            </div>
            <div className="col-span-3 space-y-2">
              {data?.map((item, idx) => (
                <PLHorizontal data={item} type="all"/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyListing;
