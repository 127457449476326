export function getFileName(name, file) {
  let today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate(),
    time =
      today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();

  return (
    name.replace(/[^A-Z0-9]+/gi, "-") +
    "-" +
    date +
    time +
    "." +
    file.name.split(".").pop()
  );
}
//export const baseURL = "https://api-waari.dthrill.com/";
//export const baseURL = "https://api-stag.waari.in";

//export const baseURL = "https://api-stag.waari.in";
// export const baseURL_service = "https://api-stag.waari.in/admin/uploads/service/";
// export const baseURL_menu_coverimage = "https://api-stag.waari.in/admin/uploads/menu/coverimage/";
// export const baseURL_gallery = "https://api-stag.waari.in/admin/uploads/gallery/";
// export const baseURL_iconimage_waariwebsite = "https://api-stag.waari.in/admin/uploads/iconimage/waariselect/";

// export const baseURL = "http://localhost:5000";
// export const baseURL_gallery = "http://localhost:5000/admin/uploads/gallery/";

// export const baseURL_service = "http://localhost:5001/admin/uploads/service/";
// export const baseURL_menu_coverimage = "http://localhost:5001/admin/uploads/menu/coverimage/";

// export const baseURL_iconimage_waariwebsite = "http://localhost:5001/admin/uploads/iconimage/waariselect/";
//export const baseURL = url[window.location.host];
export const validateEmail = (email) => {
  const email_reg_exp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(email_reg_exp) === null ? false : true;
};
export const getNumberOfDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  return Math.round(diffInTime / oneDay) + 1;
};
export const getNumberOfNights = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  return Math.round(diffInTime / oneDay);
};
export const validatePassword = (pass) => {
  return pass.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
};
export const onlyNumber = (number) => {
  let num_reg_exp = /^[0-9]+$/;
  return number.match(num_reg_exp);
};

export function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

export const check_islogin = () => {
  if (
    sessionStorage.getItem("UserID") !== null &&
    sessionStorage.getItem("UserID") !== undefined &&
    sessionStorage.getItem("UserID") !== ""
  ) {
    return true;
  } else {
    window.location.href = "/";
  }
};

export const icon_list = [
  { label: "Outline Location", value: "outline-location" },
];

export function getmenuname(name) {
  return name
    .trim()
    .toString()
    .toLowerCase()
    .split(/(?=[A-Z])/)
    .join("-")
    .replace(/[^A-Z0-9]+/gi, "-");
}