import React from "react";
import AdvSearch from "../../propListings/AdvSearch";
import Icon from "@mdi/react";
import {
  mdiSofaSingleOutline,
  mdiHumanMaleFemaleChild,
  mdiDomain,
  mdiKeyOutline,
  mdiMapMarkerOutline,
} from "@mdi/js";
import { Link } from "react-router-dom";

function PropertiesList_Static() {
  const properties = [
    {
      btnaSaleRent: "Sale",
      title: "Green Crest Taloja",
      address: "Karghar",
      beds: "2",
      baths: "2",
      furnishing: "Furnished",
      ptenant: "Company",
      bhktype: "1BHK",
      availability: "Within 15 Days",
      price: "5,00,000",
    },
    {
      btnaSaleRent: "Sale",
      title: "Unimont Imperia",
      address: "Thane",
      beds: "2",
      baths: "2",
      furnishing: "Furnished",
      ptenant: "Company",
      bhktype: "1BHK",
      availability: "Within 15 Days",
      price: "5,00,000",
    },
    {
      btnaSaleRent: "Sale",
      title: "Thalia Group, Vrundavan Flora Group",
      address: "Thane",
      beds: "2",
      baths: "2",
      furnishing: "Furnished",
      ptenant: "Company",
      bhktype: "1BHK",
      availability: "Within 15 Days",
      price: "5,00,000",
    },
    {
      btnaSaleRent: "Sale",
      title: "Signature,Sairama",
      address: "Kharghar, Navi Mumbai",
      beds: "2",
      baths: "2",
      furnishing: "Furnished",
      ptenant: "Company",
      bhktype: "1BHK",
      availability: "Within 15 Days",
      price: "5,00,000",
    },
  ];
  return (
    <>
      <div className="bg-slate-100">
        <div className="py-20 lg:px-10 md:px-10 px-5">
          {/* <PLHeadSection /> */}
          <div className="pt-3 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-2">
            <div>
              <p className="text-2xl text-slate-700 font-medium">Properties</p>
            </div>
          </div>
          <div className="flex space-x-2">
            <p className="text-sm text-slate-600 hover:text-blue-700">
              <Link to="/">Home</Link>
            </p>
            <p className="text-sm text-blue-700">Properties</p>
          </div>
          <div className=" pt-5 grid lg:grid-cols-4 md:grid-cols-1 grid-cols-1 items-start lg:gap-5 md:gap-0 lg:gap-y-0 md:gap-y-5 gap-y-5">
            <div className="w-full">
              <AdvSearch />
            </div>
            <div className="col-span-3 space-y-2">
              {/* {data?.map((item, idx) => (
                <PLHorizontal data={item} />
              ))} */}

              {properties?.map((item, idx) => (
                <div className="space-y-4" key={idx}>
                  <div className="bg-white border rounded-xl shadow">
                    {" "}
                    <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
                      <div className="relative">
                        <img
                          src="/images/Unimiont-Imperia.jpeg"
                          alt=""
                          className="lg:w-72 md:w-72 w-full h-52 lg:rounded-l md:rounded-l rounded-t"
                        />
                        <div className="px-2">
                          <button className="absolute z-10 top-2 py-1 px-3 text-white font-semibold text-xs rounded bg-abhita-400">
                            {item.btnaSaleRent}
                          </button>
                        </div>
                      </div>
                      <div className="py-3 lg:pl-0 lg:pr-10 md:pl-5 md:pr-10 px-5 w-full col-span-2">
                        <div className="pt-1">
                          <p className="text-2xl text-slate-900 font-bold">
                            {item.title}
                          </p>
                          <div className="flex py-1 text-sm text-slate-700">
                            <Icon
                              path={mdiMapMarkerOutline}
                              className="h-5 w-5"
                            />
                            <p>{item.address}</p>
                          </div>
                        </div>
                        <div className="flex divide-x divide-slate-600 pt-2">
                          <div className="flex pr-5">
                            <p className="text-sm">Beds: {item.beds}</p>
                          </div>
                          <div className="flex px-5">
                            <p className="text-sm">Baths: {item.baths}</p>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-4 pt-2">
                          <div className="flex text-slate-700  space-x-2">
                            <Icon
                              path={mdiSofaSingleOutline}
                              className="h-5 w-5"
                            />
                            <p className="text-sm pt-1">{item.furnishing}</p>
                          </div>
                          <div className="flex text-slate-700 space-x-2">
                            <Icon
                              path={mdiHumanMaleFemaleChild}
                              className="h-5 w-5"
                            />
                            <p className="text-sm pt-1">{item.ptenant}</p>
                          </div>
                          <div className="flex text-slate-700  space-x-2">
                            <Icon path={mdiDomain} className="h-5 w-5" />
                            <p className="text-sm pt-1">{item.bhktype}</p>
                          </div>
                          <div className="flex text-slate-700 space-x-2">
                            <Icon path={mdiKeyOutline} className="h-5 w-5" />
                            <p className="text-sm pt-1">{item.availability}</p>
                          </div>
                        </div>
                        <div></div>
                        <div className="md:flex justify-between pt-4 md:space-y-0 space-y-2">
                          <div className="md:text-center text-left">
                            <p className="text-2xl leading-10">
                              ₹ {item.price}
                            </p>
                          </div>
                          <div className="text-center">
                            <Link to="/propertiesliststatic">
                              <button className=" py-2 px-5 md:w-full text-white font-medium bg-abhita-300 rounded flex justify-end">
                                View Details
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertiesList_Static;
