import React, { useEffect } from "react";
import Icon from "@mdi/react";
import { mdiAccountCircle, mdiStar } from "@mdi/js";
import { Carousel, initTE } from "tw-elements";

function Testimonials() {
  useEffect(() => {
    initTE({ Carousel });
  }, []);

  return (
    <>
      <div className="py-10 md:px-10 px-5 text-center bg-gray-50">
        <p className="md:text-4xl text-2xl font-bold md:pb-10 pb-5">
          Testimonials
        </p>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
          <div
            id="carouselExampleControlsVideo"
            class="relative"
            data-te-carousel-init
            data-te-carousel-slide
          >
            <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
              <div
                class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
                data-te-carousel-active
              >
                <video
                  className="w-full h-[350px]"
                  controls
                  autoplay
                  loop
                  muted
                >
                  <source src="/images/video.mp4" type="video/mp4" />
                </video>
              </div>

              <div
                class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item
              >
                <video
                  className="w-full h-[350px]"
                  controls
                  autoplay
                  loop
                  muted
                >
                  <source
                    src="/images/3. Unimont Imperia Amenities.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>

            <button
              class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleControlsVideo"
              data-te-slide="prev"
            >
              <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Previous
              </span>
            </button>

            <button
              class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleControlsVideo"
              data-te-slide="next"
            >
              <span class="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Next
              </span>
            </button>
          </div>

          <div className="py-16 px-5 bg-white w-full border border-slate-300">
            <div
              id="carouselExampleControlsTestimonial"
              class="relative"
              data-te-carousel-init
              data-te-carousel-slide
            >
              <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                <div
                  class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                  data-te-carousel-item
                  data-te-carousel-active
                >
                  <div className="grid place-items-center">
                    <div className="grid place-items-center">
                      <Icon
                        path={mdiAccountCircle}
                        className="w-20 h-20 text-slate-400 border border-slate-400 rounded-full"
                      />
                      <div className="px-3 pt-3">
                        <p className="text-lg font-medium">Ajay Patil</p>
                        <p className="text-sm text-slate-400">Manager</p>
                      </div>
                    </div>
                    <div className="pt-5">
                      <p className="flex justify-center text-sm text-slate-500">
                        The site really helps us to find good properties in the
                        least amount of time without any headache of brokerage.
                        I was new and had no time. But then I found Dream
                        Estate.
                      </p>
                      <div className="flex text-yellow-500 pt-2 justify-center">
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon
                          path={mdiStar}
                          className="h-4 w-4 text-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                  data-te-carousel-item
                >
                  <div className="grid place-items-center">
                    <div className="grid place-items-center">
                      <Icon
                        path={mdiAccountCircle}
                        className="w-20 h-20 text-slate-400 border border-slate-400 rounded-full"
                      />
                      <div className="px-3 pt-3">
                        <p className="text-lg font-medium">Parag Firke</p>
                        <p className="text-sm text-slate-400">Engineer</p>
                      </div>
                    </div>
                    <div className="pt-5">
                      <p className="flex justify-center text-sm text-slate-500">
                        The site really helps us to find good properties in the
                        least amount of time without any headache of brokerage.
                        I was new and had no time. But then I found Dream
                        Estate.
                      </p>
                      <div className="flex text-yellow-500 pt-2 justify-center">
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon
                          path={mdiStar}
                          className="h-4 w-4 text-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                  data-te-carousel-item
                >
                  <div className="grid place-items-center">
                    <div className="grid place-items-center">
                      <Icon
                        path={mdiAccountCircle}
                        className="w-20 h-20 text-slate-400 border border-slate-400 rounded-full"
                      />
                      <div className="px-3 pt-3">
                        <p className="text-lg font-medium">Narayan Pawar</p>
                        <p className="text-sm text-slate-400">Teacher</p>
                      </div>
                    </div>
                    <div className="pt-5">
                      <p className="flex justify-center text-sm text-slate-500">
                        Abhita Properties provides a great place to stay with safe
                        environment. if they show you something about property
                        that is always same as it. No fake pictures.
                      </p>
                      <div className="flex text-yellow-500 pt-2 justify-center">
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon path={mdiStar} className="h-4 w-4" />
                        <Icon
                          path={mdiStar}
                          className="h-4 w-4 text-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="absolute bottom-0 left-0 top-0 z-[1] flex w-[5%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                type="button"
                data-te-target="#carouselExampleControlsTestimonial"
                data-te-slide="prev"
              >
                <span class="inline-block h-8 w-8 text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </span>
                <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Previous
                </span>
              </button>

              <button
                class="absolute bottom-0 right-0 top-0 z-[1] flex w-[5%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                type="button"
                data-te-target="#carouselExampleControlsTestimonial"
                data-te-slide="next"
              >
                <span class="inline-block h-8 w-8 text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </span>
                <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Next
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
