import React from 'react';
import { mdiCheckAll, mdiClose } from '@mdi/js';
import Icon from '@mdi/react'

function SellerPlans() {
  // const plans =[
  //   {
  //     title:"Basic Plan",
  //     rupees:"Free",
  //     day:"Per Month",
  //     list:"Lorem ipsum dolor sit ame",
  //     mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
  //     mdiClose:<Icon path={mdiClose} size={1} />,
  //     button:"Buy Now",
  //     color: "bg-gradient-to-t from-violet-700 to-violet-900 p-5 shadow-md shadow-slate-500",
  //   },
    
  //     {
  //       title:"Standard Plan",
  //       rupees:"Free",
  //       day:"Per Month",
  //       list:"Lorem ipsum dolor sit ame",
  //       mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
  //       mdiClose:<Icon path={mdiClose} size={1} />,
  //       button:"Buy Now",
  //       color: "bg-gradient-to-t from-lime-500 to-lime-700 p-5 shadow-md shadow-slate-500",
  //     },
  //     {
  //       title:"Basic Plan",
  //       rupees:"Free",
  //       day:"Per Month",
  //       list:"Lorem ipsum dolor sit ame",
  //       mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
  //       mdiClose:<Icon path={mdiClose} size={1} />,
  //       button:"Buy Now",
  //       color: "bg-gradient-to-t from-amber-500 to-amber-800 p-5 shadow-md shadow-slate-500",
  //     },
  //     {
  //       title:"Basic Plan",
  //       rupees:"Free",
  //       day:"Per Month",
  //       list:"Lorem ipsum dolor sit ame",
  //       mdiCheckAll:<Icon path={mdiCheckAll} size={1} />,
  //       mdiClose:<Icon path={mdiClose} size={1} />,
  //       button:"Buy Now",
  //       color: "bg-gradient-to-t from-cyan-500 to-cyan-800 p-5 shadow-md shadow-slate-500",
  //   },
  // ];
  return (
    <>
    <div className="pt-40 pb-20 md:px-20 px-5 w-full bg-gray-50">
        <p className="text-center font-semibold text-3xl">
            Seller Plans
        </p>
        <div className="grid md:grid-cols-4 grid-cols-1 md:gap-5 gap-y-20 place-items-center py-20">
        {/* {plans?.map((s, idx) => (
          <div className='relative bg-white px-10 pb-10 pt-28 shadow-md shadow-slate-200' key={idx}
          >
            <SellerBlog {...s} />
            
          </div>
           ))} */}

          <div className='relative bg-gradient-to-t from-cyan-500 to-cyan-800 rounded-[20px] p-5 shadow-lg shadow-slate-500 w-64 h-[450px]'>
            <div className='absolute t-0 bg-white p-7 w-64 h-[350px] shadow-lg shadow-slate-500 rounded-[20px]'>
            <p className='font-medium text-left text-2xl uppercase pb-5'>Basic Plan</p>

            <ul className=''>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
            </ul>
            </div>
            <div className='absolute bottom-7 left-[65px] p-7 w-40 h-40 bg-black p-5 border-4 border-white rounded-full'>
                 <p className='text-white font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-white opacity-80 font-medium text-center'>Per Month</p>
            </div>
          </div>

          <div className='relative bg-gradient-to-t from-pink-500 to-pink-800 rounded-[20px] p-5 shadow-lg shadow-slate-500 w-64 h-[450px]'>
            <div className='absolute t-0 bg-white p-7 w-64 h-[350px] shadow-lg shadow-slate-500 rounded-[20px]'>
            <p className='font-medium text-left text-2xl uppercase pb-5'>Standard Plan</p>

            <ul className=''>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
            </ul>
            </div>
            <div className='absolute bottom-7 left-[65px] p-7 w-40 h-40 bg-black p-5 border-4 border-white rounded-full'>
                 <p className='text-white font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-white opacity-80 font-medium text-center'>Per Month</p>
            </div>
          </div>

          <div className='relative bg-gradient-to-t from-amber-500 to-amber-800 rounded-[20px] p-5 shadow-lg shadow-slate-500 w-64 h-[450px]'>
            <div className='absolute t-0 bg-white p-7 w-64 h-[350px] shadow-lg shadow-slate-500 rounded-[20px]'>
            <p className='font-medium text-left text-2xl uppercase pb-5'>Premium Plan</p>

            <ul className=''>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
            </ul>
            </div>
            <div className='absolute bottom-7 left-[65px] p-7 w-40 h-40 bg-black p-5 border-4 border-white rounded-full'>
                 <p className='text-white font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-white opacity-80 font-medium text-center'>Per Month</p>
            </div>
          </div>

          <div className='relative bg-gradient-to-t from-violet-500 to-violet-800 rounded-[20px] p-5 shadow-lg shadow-slate-500 w-64 h-[450px]'>
            <div className='absolute t-0 bg-white p-7 w-64 h-[350px] shadow-lg shadow-slate-500 rounded-[20px]'>
            <p className='font-medium text-left text-2xl uppercase pb-5'>Basic Plan</p>

            <ul className=''>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiCheckAll} className="text-cyan-500 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
              <li className='flex space-x-2'><Icon path={mdiClose} className="text-cyan-700 w-5 h-5" /> <p>Lorem ipsum</p></li>
            </ul>
            </div>
            <div className='absolute bottom-7 left-[65px] p-7 w-40 h-40 bg-black p-5 border-4 border-white rounded-full'>
                 <p className='text-white font-semibold text-3xl text-center uppercase pt-4'>Free</p>
                 <p className='text-white opacity-80 font-medium text-center'>Per Month</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


// function SellerBlog ({title, color, rupees, day, list, mdiClose, mdiCheckAll, button}){
//   return (
//     <>
//      <div >
//             <div className='absolute -top-10 w-full left-0 px-10'>
//               <div className={color}>
//                  <p className='text-white opacity-80 font-medium text-center'>{title}</p>
//                  <p className='text-white font-semibold text-3xl text-center uppercase'>{rupees}</p>
//                  <p className='text-white opacity-80 font-medium text-center'>{day}</p>
//               </div>
//             </div>
           
//             <ul>
//               <li className='flex space-x-2'>
//                 <i className="text-cyan-400">{mdiCheckAll}</i>
//                 <p>{list}</p>
//               </li>
//               <li className='flex space-x-2'>
//                 <i className="text-orange-400">{mdiCheckAll}</i>
//                 <p>{list}</p>
//               </li>
//               <li className='flex space-x-2'>
//                 <i className="text-orange-400">{mdiCheckAll}</i>
//                 <p>{list}</p>
//               </li>
//               <li className='flex space-x-2'>
//                 <i className="text-green-600">{mdiClose}</i>
//                 <p>{list}</p>
//               </li>
//               <li className='flex space-x-2'>
//                 <i className="text-green-600">{mdiClose}</i>
//                 <p>{list}</p>
//               </li>
//             </ul>
//             <div className='pt-5'>
//             <button className='bg-orange-500 text-white font-semibold px-10 py-3 w-full'>{button}</button></div>
//           </div>
//     </>
//   );
// }

export default SellerPlans