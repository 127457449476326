import React from "react";

function Details({ data }) {
  console.log(data);
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700 text-lg">Details</p>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5 pt-5 w-full">
          {/* <div className="flex ">
            <p className="text-sm font-semibold">Property Id</p>
            <p className="text-xs font-medium text-slate-500">: 945</p>
          </div> */}
          {data.minPrice?<div className="flex ">
            <p className="text-sm font-semibold">Minimum Price</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.minPrice}
            </p>
          </div>:""}
          {data.maxPrice?<div className="flex ">
            <p className="text-sm font-semibold">Maximum Price</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.maxPrice}
            </p>
          </div>:""}
          {data.bHK_1_MinBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK1 MinBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_1_MinBudget}
            </p>
          </div>:""}
          {data.bHK_1_MaxBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK1 MaxBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_1_MaxBudget}
            </p>
          </div>:""}
          {data.bHK_1_PropertySize?<div className="flex ">
            <p className="text-sm font-semibold">BHK1 PropertySize</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_1_PropertySize}
            </p>
          </div>:""}
          {data.bHK_2_MinBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK2 MinBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_2_MinBudget}
            </p>
          </div>:""}
          {data.bHK_2_MaxBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK2 MaxBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_2_MaxBudget}
            </p>
          </div>:""}
          {data.bHK_2_PropertySize?<div className="flex ">
            <p className="text-sm font-semibold">BHK2 PropertySize</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_2_PropertySize}
            </p>
          </div>:""}
          {data.bHK_3_MinBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK3 MinBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_3_MinBudget}
            </p>
          </div>:""}
          {data.bHK_3_MaxBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK3 MaxBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_3_MaxBudget}
            </p>
          </div>:""}
          {data.bHK_3_PropertySize?<div className="flex ">
            <p className="text-sm font-semibold">BHK3 PropertySize</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_3_PropertySize}
            </p>
          </div>:""}
          {data.bHK_4_MinBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK4 MinBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_4_MinBudget}
            </p>
          </div>:""}
          {data.bHK_4_MaxBudget?<div className="flex ">
            <p className="text-sm font-semibold">BHK4 MaxBudget</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_4_MaxBudget}
            </p>
          </div>:""}
          {data.bHK_4_PropertySize?<div className="flex ">
            <p className="text-sm font-semibold">BHK4 PropertySize</p>
            <p className="text-sm font-medium text-slate-500">
              : ₹{data.bHK_4_PropertySize}
            </p>
          </div>:""}
          {data.propertysize?<div className="flex ">
            <p className="text-sm font-semibold">Property Size</p>
            <p className="text-sm font-medium text-slate-500">
              : {data.propertysize}
            </p>
          </div>:""}
          <div className="flex ">
            <p className="text-sm font-semibold">Availability</p>
            <p className="text-sm font-medium text-slate-500">
              : {data.availability}
              <sup>2</sup>
            </p>
          </div>
          <div className="flex ">
            <p className="text-sm font-semibold">Floor</p>
            <p className="text-sm font-medium text-slate-500">: {data.floor}</p>
          </div>
          <div className="flex ">
            <p className="text-sm font-semibold">Balcony</p>
            <p className="text-sm font-medium text-slate-500">
              : {data.balcony}
            </p>
          </div>
          <div className="flex ">
            <p className="text-sm font-semibold">Flats</p>
            <p className="text-sm font-medium text-slate-500">: {data.flats}</p>
          </div>
          <div className="flex ">
            <p className="text-sm font-semibold">Furnishing</p>
            <p className="text-sm font-medium text-slate-500">
              : {data.furnishing}
            </p>
          </div>
          <div className="col-span-3 flex ">
            <p className="text-sm font-semibold">
              Specification:
              <span className="text-sm font-medium text-slate-500">
                 {data.specification}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
