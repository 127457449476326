//import React from "react";
import SaleOurListing from "../ourListings/SaleOurListing";
import React, {useEffect} from "react";

function OurListings() {
  

  return (
    <>
      <div className="relative md:pt-20 pt-10 pb-12 bg-gray-50">
        <p className="md:text-4xl text-2xl font-bold pb-5 text-center">
          Our Listings
        </p>
        <p className="text-md font-normal pb-10 px-5 md:px-60 text-center">
          Showcase properties in homepage to be visible and accessible. Select
          the most wanted categories or cities to be displayed in the lists.
        </p>

        <div className="md:px-10 px-5 space-y-10">
          <SaleOurListing />
        </div>
      </div>
    </>
  );
}

export default OurListings;
