import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../http-common";
import {
  checkonlyletterandcharacter,
  checkemail,
  checkindiamobile,
} from "../config/validate";
import { useNavigate } from "react-router-dom";

function Register() {

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [state, setState] = useState({
    ID: "0",
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    Type: "buyer",
    Password: "",
    CreatedBy: 0, // 0 means admin
    errors: [],
  });
  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      axiosInstance
        .post("iuusermaster", state)
        .then((response) => {

          if (response?.data.length > 0) {
            if (response?.data[0].ID) {
              sessionStorage.setItem("UserID", response?.data[0].ID);
              sessionStorage.setItem("Type", response?.data[0].Type);
              alert("Successfuly");
              //setList(response.data);
              navigate(
                `/login`
              );
            } else {
              alert("You Are  Allready Register This Email...");
            }
          } else {
            alert("api error");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Salutation) {
      IsValid = false;
      errors["Salutation"] = "Salutation is Required";
    }
    if (!state?.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name is Required";
    }
    else {
      if (!checkonlyletterandcharacter(state.FirstName)) {
        IsValid = false;
        errors["FirstName"] = "Only letter and character allowed";
      }
    }
    // if (!state?.MiddleName) {
    //   IsValid = false;
    //   errors["MiddleName"] = "Middle Name is Required";
    // }
    // else {
    //   if (!checkonlyletterandcharacter(state.MiddleName)) {
    //     IsValid = false;
    //     errors["MiddleName"] = "Only letter and character allowed";
    //   }
    // }
    if (!state?.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name is Required";
    }
    else {
      if (!checkonlyletterandcharacter(state.LastName)) {
        IsValid = false;
        errors["LastName"] = "Only letter and character allowed";
      }
    }
    if (!state?.EmailID) {
      IsValid = false;
      errors["EmailID"] = "EmailID is Required";
    }
    else {
      if (!checkemail(state.EmailID)) {
        IsValid = false;
        errors["EmailID"] = "Only valid EmailID Required";
      }
    }
    if (!state?.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile is Required";
    }
    else {
      if (!checkindiamobile(state.Mobile)) {
        IsValid = false;
        errors["Mobile"] = "Only valid Mobile Number is Required";
      }
    }
    if (!state?.Password) {
      IsValid = false;
      errors["Password"] = "Password is Required";
    }


    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }
  return (
    <>

      <div className="relative">
        <div className="bg-registrationImg md:h-[800px] h-[1100px] bg-center bg-cover bg-no-repeat relative after:bg-black after:absolute after:inset-0 after:opacity-50"></div>

        <div className="px-5">
          <div className="grid place-items-center">
            {/* <div className="bg-white rounded-xl  shadow-lg overflow-hidden text-center"> */}
            <div className="lg:w-3/6 md:w-3/4 w-11/12 container absolute md:top-40 top-28  bg-white rounded-lg md:mx-5 mx-5 shadow-lg overflow-hidden text-center md:py-5 ">
              <p className="md:pt-2 pt-5 text-center md:text-4xl text-2xl text-abhita-100 font-bold uppercase">
                Register Yourself
              </p>
              <p className="text-slate-400  md:text-sm text-xs py-1 px-2 ">
                The information will let us know more about you
              </p>
              <p className=" bg-abhita-200  text-center p-1 text-md text-white font-bold tracking-widest ">
                Build Your Profile
              </p>
              <div className="px-5 pt-5 grid md:grid-cols-3 grid-cols-1 gap-2">
                <div className="md:mb-5 mb-2">
                  <label
                    for="full_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Salutation
                  </label>
                  <select
                    id="Salutation"
                    className="border border-gray-300 bg-white text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    name="Salutation"
                    value={state.Salutation}
                    onChange={handlechange}
                  >
                    <option value="0">Select </option>
                    <option value="Mr">Mr.</option>
                    <option value="Miss">Miss</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs ">{state.errors.Salutation}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="px-5 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-2">
                <div className="md:mb-5 mb-2">
                  <label
                    for="first_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter First Name"
                    name="FirstName"
                    value={state.FirstName}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">{state.errors.FirstName}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="md:mb-5 mb-2">
                  <label
                    for="middle_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Middle name
                  </label>
                  <input
                    type="text"
                    id="middle_name"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter Middle Name"
                    name="MiddleName"
                    value={state.MiddleName}
                    onChange={handlechange}
                    required
                  />
                  {/* {state.errors ? (
            <div className="text-red-600 text-left text-xs">{state.errors.MiddleName}</div>
          ) : (
            ""
          )} */}
                </div>
                <div className="md:mb-5 mb-2">
                  <label
                    for="last_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter Last Name"
                    name="LastName"
                    value={state.LastName}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">{state.errors.LastName}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="px-5 grid md:grid-cols-2 grid-cols-1 gap-2 ">
                <div className="mb-2">
                  <label
                    htmlFor="full_name"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    id="mb_no"
                    className="border border-gray-300 text-gray-900 text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter Mobile No."
                    name="Mobile"
                    value={state.Mobile}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">{state.errors.Mobile}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Email address
                  </label>

                  <input
                    type="email"
                    id="email"
                    className="border border-gray-300 text-black text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter Email ID"
                    name="EmailID"
                    value={state.EmailID}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">{state.errors.EmailID}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="password"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Password
                  </label>

                  <input
                    type="password"
                    id="password"
                    className="border border-gray-300 text-black text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter Password"
                    name="Password"
                    value={state.Password}
                    onChange={handlechange}
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">{state.errors.Password}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="password"
                    className="block mb-1 text-sm font-medium text-black text-left"
                  >
                    Re-enter Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="border border-gray-300 text-black text-sm rounded block w-full p-2 focus:outline-none  focus:border-blue-500 hover:border-blue-500 hover:outline-none"
                    placeholder="Enter Re-enter Password"
                    required
                  />
                  {state.errors ? (
                    <div className="text-red-600 text-left text-xs">{state.errors.Password}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex space-x-2 justify-center pt-5">
                <div>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5  bg-abhita-300 text-white font-semibold text-xs leading-tight uppercase rounded shadow-lg hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={SaveData}
                  >
                    Create Account
                  </button>
                </div>
              </div>
              <div className="md:pt-5 md:py-0 py-5 md:px-5 px-2 grid place-content-center">
                <p className="text-slate-600 text-xs text-center">
                Abhita Properties collects and uses personal data in accordance
                  with our{" "}
                  <span className="hover:underline font-semibold">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </span>
                  . By creating an account, you agree to our{" "}
                  <span className="hover:underline font-semibold">
                    Terms & Conditions
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
