import React from "react";
import Icon from "@mdi/react";
import { mdiArrowRightThin } from "@mdi/js";

function WhyChoose() {
  const choose = [
    {
      img: "/images/1.png",
      title: "Sell Your Home",
      desc: "We do a free evaluation to be sure you want to start selling.",
    },
    {
      img: "/images/2.png",
      title: "Rent Your Home",
      desc: "We do a free evaluation to be sure you want to start selling.",
    },
    {
      img: "/images/3.png",
      title: "Buy A Home",
      desc: "We do a free evaluation to be sure you want to start selling.",
    },
    {
      img: "/images/4.png",
      title: "Free Marketing",
      desc: "We do a free evaluation to be sure you want to start selling.",
    },
  ];

  return (
    <>
      <div className="relative text-center">
        <p className="md:text-4xl text-2xl font-bold pb-5">
          Why Choose Us
        </p>
        <p className="md:text-md text-sm font-normal pb-10 px-5 md:px-60">
          Our well experienced Property Advisors guides you towards the best
          Property for your investment. We not only feature Residential and
          Commercial Property but also Plots, Agricultural Land, etc. We bring
          you the Key to your Dream Home.
        </p>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 p-5 ">
          {choose?.map((c, idx) => (
            <div
              // className="p-5 border-2  border-transparent transition-all hover:border-slate-300 hover:rounded"
              className="relative bg-blue-50 p-5 transition-all  hover:rounded hover:transition"
              key={idx}
            >
              <WhyChooseBlog {...c} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function WhyChooseBlog({ img, title, desc }) {
  return (
    <>
      <div>
        <div className="w-full flex space-x-3">
          <div className="bg-gray-50 border-2 transition-all border-slate-300 rounded-full">
            <img src={img} alt="" className="w-16 h-16 p-3" />
          </div>
          <p className="text-center text-lg font-medium grid place-content-center">
            {title}
          </p>
        </div>

        <div className="pt-2">
          <p className="text-left pt-4 text-sm">{desc}</p>
          <div className="flex">
            <p className="text-left pt-4 text-sm font-semibold text-slate-500 hover:text-blue-700 hover:underline">
              Read More
            </p>
            <Icon
              path={mdiArrowRightThin}
              className="h-10 w-10 pt-3 text-slate-500"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChoose;
