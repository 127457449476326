import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance, { baseURL } from "../http-common";

function SaleOurListing() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    axiosInstance
      .get("getPropertydetail_homeweb")
      .then((response) => {
        debugger;
        let a = [];
        for (let i = 0; i < response.data.length; i++) {
          if (i < 6) {
            a.push(response.data[i]);
          }
        }
        setList(a);
      })
      .catch((error) => {
        alert(error);
      });
  }
  // console.log(list);

  const navigate = useNavigate();

  // const list = [
  //   {
  //     img: "/images/mahabaleshwar.jpeg",
  //     saleOrBuy: "Sale",
  //     propertyName: "Mahabaleshwar Panchgani Property",
  //     propertydetails:
  //       "Mahabaleshwar is a leading municipal council cum town in the District of Satara in Maharashtra.",
  //     navigate:"/property-detail-static4",
  //   },
  //   {
  //     img: "/images/property1.jpg",
  //     saleOrBuy: "Sale",
  //     propertyName: "Ram Prasad Shelke",
  //     propertydetails: "6.6 Acre LAND, MUMBAI PUNE HIGHWAY",
  //     navigate:"/property-detail-static5",
  //   },
  //   {
  //     img: "/images/property1.jpg",
  //     saleOrBuy: "Sale",
  //     propertyName: "Shakil Kamal Kaji",
  //     propertydetails: "62 Hect, 73 Are (Plot)",
  //     navigate:"/property-detail-static6",
  //   },
  // ];



  return (
    <>
      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {list
            ? list.map((item, idx) => (
                <div className="bg-white rounded" key={idx}>
                  <div className="relative">
                    <img
                      src={baseURL + "/" + item.image}
                      alt=""
                      className="rounded-t h-52 w-full"
                    />
                    <div className="absolute top-0 w-full p-2">
                      <button className="absolute z-10 top-2 font-semibold py-1 px-3 text-white text-xs rounded bg-abhita-400">
                        For {item.btnaSaleRent}
                      </button>
                    </div>
                  </div>
                  <div className="p-5 text-left space-y-2">
                    <p className="font-medium text-xl text-black ">
                      {item.title} Property
                    </p>
                    <div className="flex justify-end">
                      <Link to={`/property-detail?id=${item.PropertydetailID}`}>
                        <button className="h-9 p-2 uppercase text-white text-sm font-bold bg-abhita-300 rounded-lg">
                          View Details
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div> */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 px-10">
        {list?.map((l, idx) => (
          <div className="bg-white rounded" key={idx}>
            <div className="relative">
              <img src={l.img} alt="" className="rounded-t h-52 w-full" />
              <div className="absolute top-0 w-full p-2">
                <button className="absolute z-10 top-2 font-semibold py-1 px-3 text-white text-xs rounded bg-abhita-400">
                  For {l.btnaSaleRent}
                </button>
              </div>
            </div>
            <div className="p-5 text-left space-y-2">
              <p className="font-medium text-xl text-black ">
                {l.title}
              </p>
              <p className="text-sm text-gray-600">{l.Typeproperty}</p>
              <div className="flex justify-end">
              <Link to={`/property-detail?id=${l.PropertydetailID}`}>
                <button
                  className="h-9 p-2 uppercase text-white text-sm font-bold bg-abhita-300 rounded-lg"
                >
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default SaleOurListing;
