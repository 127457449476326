import React from "react";

function ChangeMsrmnt() {
  return (
    <>
      <div className="bg-white md:p-10 p-5">
        <p className="font-semibold hover:text-blue-700">Change Measurement</p>
        <div className="pt-5">
          <select className="py-2 px-2 grid w-full bg-white border border-slate-300 rounded ">
            <option>
              square feet - ft<sup>2</sup>
            </option>
            <option>
              suare meters - ft<sup>2</sup>
            </option>
            <option>acres - ac</option>
            <option>
              square yards - yd<sup>2</sup>
            </option>
            <option>hectares - ha</option>
          </select>
        </div>
      </div>
    </>
  );
}

export default ChangeMsrmnt;
